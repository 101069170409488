import { encode, decode } from 'html-entities';

export const decodeString = (input: string) => decode(input);

export const encodeString = (input: string) => encode(input);

export const formatNumberWithCommas = (
  number: number | string,
  options?: Intl.NumberFormatOptions,
) => {
  /**
   * This function accepts values of both numeric and alphabetic string types
   * number number type.
   *
   * By including the condition below, this function can handle a shared variable
   * that may be either a numeric string or an alphabetic string. Instead of
   * adding additional conditions to allow/prevent the use of this function, the
   * shared variable can be provided in both cases. If the value is an alphabetic string,
   * this function will act as a simple data pass-through, returning the same string.
   * If the value is a numeric string, it will be converted to a number, and commas
   * will be added to the formatted string representation of the number before returning it.
   */
  if (!number || (typeof number !== 'number' && isNaN(Number(number)))) {
    return String(number);
  }
  return parseFloat(String(number)).toLocaleString('en-us', options);
};

export const removeCommasFromString = (value: string) => value.replaceAll(',', '');

export const splitCamelCaseString = (camelCaseString: string) =>
  camelCaseString.replace(/([A-Z])/g, ' $1').trim();

export const ValidateEmail = (input: string) => {
  let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.)([a-zA-Z0-9-]+)$/;
  if (validRegex.exec(input)) {
    return false;
  }
  return true;
};

export const shortenNumber = (number: number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'K';
  }
  return number.toString();
};

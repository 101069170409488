import Button, { ButtonVariant } from '@components/Button';
import { Icon, IconName } from '@components/Icon';
import cn from 'classnames';

import './ListingDetails.css';
import useDeviceType from '@/hooks/useDeviceType';
export interface ListingActionsProps {
  propertyID: any;
  isRouteHistoryStackEmpty: any;
  isSaved: any;
  setShowShareModal: (arg0: boolean) => void;
  setShowRemoveFavoritesModal: (arg0: boolean) => void;
  handleAddingFavoriteListing: (arg0: any) => void;
  handleDownloadFlyer: () => void;
}
const ListingActions: React.FC<ListingActionsProps> = ({
  propertyID,
  isRouteHistoryStackEmpty,
  isSaved,
  setShowShareModal,
  setShowRemoveFavoritesModal,
  handleAddingFavoriteListing,
  handleDownloadFlyer,
}) => {
  const deviceType = useDeviceType();
  return (
    <div
      className={cn(['property-listing-actions', isRouteHistoryStackEmpty ? 'w-full' : 'w-1/2'])}>
      <Button
        Icon={
          <Icon
            classNames={isSaved ? '' : 'group-hover:fill-white-100'}
            name={isSaved ? IconName.STAR_FILLED : IconName.STAR}
          />
        }
        label={deviceType === 'click' && !isSaved ? 'Save' : undefined}
        onClick={() => {
          if (isSaved) {
            setShowRemoveFavoritesModal(true);
          } else {
            handleAddingFavoriteListing(propertyID);
          }
        }}
        variant={ButtonVariant.REVEAL_ACTION}
      />
      <Button
        Icon={<Icon name={IconName.DOWNLOAD} />}
        label={deviceType === 'click' ? 'Download Flyer' : undefined}
        onClick={() => {
          handleDownloadFlyer();
        }}
        variant={ButtonVariant.REVEAL_ACTION}
      />
      <Button
        Icon={<Icon name={IconName.EXTERNAL_LINK} />}
        label={deviceType === 'click' ? 'Share' : undefined}
        onClick={() => setShowShareModal(true)}
        variant={ButtonVariant.REVEAL_ACTION}
      />
    </div>
  );
};

export default ListingActions;

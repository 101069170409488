import { FormatLinkClassNamesFunc, LinkVariant } from './Link.types';

export const formatLinkClassNames: FormatLinkClassNamesFunc = (
  disabled: boolean,
  error: boolean,
  variant: LinkVariant,
) =>
  variant === LinkVariant.SAVED_SEARCH_CARD
    ? 'text-solar-300 hover:text-rust-100'
    : disabled
    ? variant === LinkVariant.DEFAULT
      ? 'link--disabled'
      : 'link--disabled--dark'
    : error
    ? 'link--error'
    : variant === LinkVariant.DEFAULT
    ? 'link--default'
    : 'link--default--dark';

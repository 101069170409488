import { useEffect, useMemo, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useApiClient } from '@/hooks/useApiClient';
import { useToast } from '@/hooks/useToast';
import { useUserFavorites } from '@/hooks/useUserFavorites';
import { TemplateTypes } from '@/types';
import Footer from '@components/Footer/Footer';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import RemoveSavedModal from '@components/RemoveSavedModal';
import { ShareListingModal } from '@components/ShareListingModal';

import Button, { ButtonVariant } from '@components/Button';
import { Icon, IconName } from '@components/Icon';
import ListingActions from './ListingActions';
import './ListingDetails.css';
import ListingDetailsContent from './ListingDetailsContent-component';
import { isInViewport } from './ListingDetails.utils';
import { PropertyType } from '@/utilities/constants';

export const ListingDetails = () => {
  const [showRemoveFavoritesModal, setShowRemoveFavoritesModal] = useState<boolean>(false);
  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [isBottom, setIsBottom] = useState<boolean>(false);

  const { getListingData, getFlyer, updateListingViewCount } = useApiClient();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { addFavorite, removeFavorites, favorites, isFavorite } = useUserFavorites();
  const { addToast } = useToast();
  const { mutate: updateListingViewCountMutation } = updateListingViewCount();

  const propertyID = params.id!;
  // Should return true if URL shared or open in new browser tab
  const isRouteHistoryStackEmpty = location.key === 'default';

  // TODO Need to handle error state
  const { data, isLoading, isFetching, isError } = getListingData(TemplateTypes.DetailedListing, [
    propertyID,
  ]);
  const propertyType = data ? data[0].propertyType : '';

  const isSaved = useMemo(() => isFavorite(propertyID), [favorites]);

  const handleAddingFavoriteListing = (id: string) => {
    addFavorite(id);
  };

  const handleRemovingFavoriteListing = (id: string) => {
    removeFavorites([id]);
    setShowRemoveFavoritesModal(false);
  };

  const handleDownloadFlyer = async () => {
    const data = await getFlyer(propertyID);
    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = `flyer-${propertyID}-${new Date().getTime()}.pdf`;
    link.dispatchEvent(new MouseEvent('click'));

    addToast({
      id: `download-flyer-${new Date().getTime()}`,
      title: `Success`,
      description: 'Listing Flyer Downloaded',
      type: 'success',
    });
  };

  if (isError || (!isLoading && data.length === 0)) {
    return <Navigate to="/not-found" />;
  }
  useEffect(() => {
    const handleScroll = () => {
      const el = document.getElementById('contactBannerBottom');
      if (isInViewport(el)) {
        setIsBottom(true);
      } else {
        setIsBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    updateListingViewCountMutation({
      listingId: propertyID,
      listingMetricType: 0,
    });
  }, []);

  return (
    <>
      <div className="overflow-y-auto property-listing-page">
        {isFetching || isLoading ? (
          <div className="flex items-center justify-center w-full p-10">
            <LoadingSpinner />
          </div>
        ) : (
          <div>
            <div className="property-listing-header">
              <div className="flex items-center max-w-[70.625rem] w-full  mx-6">
                {!isRouteHistoryStackEmpty && (
                  <div className="back-button-wrapper">
                    <Button
                      Icon={<Icon name={IconName.CHEVRON_LEFT} />}
                      label="Back"
                      onClick={() => navigate(-1)}
                      variant={ButtonVariant.NO_FILL}
                    />
                  </div>
                )}
                <ListingActions
                  propertyID={propertyID}
                  isRouteHistoryStackEmpty={isRouteHistoryStackEmpty}
                  isSaved={isSaved}
                  setShowShareModal={setShowShareModal}
                  setShowRemoveFavoritesModal={setShowRemoveFavoritesModal}
                  handleAddingFavoriteListing={handleAddingFavoriteListing}
                  handleDownloadFlyer={handleDownloadFlyer}
                />
              </div>
            </div>
            <ListingDetailsContent data={data} isBottom={isBottom} />
            <RemoveSavedModal
              category="favorite"
              onCancel={() => setShowRemoveFavoritesModal(false)}
              onRemove={() => handleRemovingFavoriteListing(propertyID)}
              show={showRemoveFavoritesModal}
              subtext="Removing these listing(s) from Favorites will also remove them from any saved Collections."
            />{' '}
            {showShareModal && (
              <ShareListingModal
                listingId={propertyID}
                propertyType={propertyType}
                show={showShareModal}
                onClose={() => {
                  setShowShareModal(false);
                }}
              />
            )}
          </div>
        )}
        <Footer />
      </div>
    </>
  );
};

export default ListingDetails;

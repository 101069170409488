export interface MinMaxRange {
  max: string;
  min: string;
}

export enum MinMaxRangeDefault {
  NO_MAX = 'No Max',
  NO_MIN = 'No Min',
}

export enum MinMaxRangeType {
  MAX = 'max',
  MIN = 'min',
}

export interface MinMaxSelectorProps {
  classNames?: string;
  clearFilters?: boolean;
  customOnBlurCallback?: (event: MouseEvent) => void;
  data: string[];
  label?: string;
  onSelect: (value: string, name?: string) => void;
  optionIdentifierSubtext?: string;
  range: MinMaxRange;
  maxWidth?: string;
}

type SetRange = (value: React.SetStateAction<MinMaxRange>) => void;

interface SetMinMaxOptionRangeFuncData {
  setRange?: SetRange;
  queryParameter?: string;
  updateSearchParameters?: (
    entries: [string, boolean | string | number | null | undefined][],
  ) => void;
}

export type SetMinMaxOptionRangeFunc = (
  data: SetMinMaxOptionRangeFuncData,
) => (value: string, rangeType: string | undefined) => void;

import { UseQueryResult } from '@tanstack/react-query';
import { createContext, useMemo } from 'react';

import { useApiClient } from '@/hooks/useApiClient';
import { useUser } from '@/hooks/useUser';
import {
  DetailedFavoriteCollection,
  FavoriteCollection,
  FavoriteCollectionFavorite,
  SummaryFavoriteCollection,
} from '@/types';
import { decodeString } from '@/utilities/textHelpers';

interface UserFavoriteCollectionsContextValue {
  addFavoriteCollection: (name: string, listingIds: string[]) => Promise<FavoriteCollection>;
  addFavoritesToFavoriteCollection: (
    favoriteCollectionId: string,
    favoriteIds: string[],
  ) => Promise<FavoriteCollectionFavorite>;
  cloneFavoriteCollection: (favoriteCollectionId: string) => Promise<FavoriteCollection>;
  favoriteCollections: SummaryFavoriteCollection[];
  isFavoriteCollectionsLoading: boolean;
  getFavoriteCollectionById: (
    favoriteCollectionId: string,
  ) => UseQueryResult<DetailedFavoriteCollection, unknown>;
  updateFavoriteCollection: (
    favoriteCollectionId: string,
    name: string,
  ) => Promise<FavoriteCollection>;
  removeFavoriteCollection: (favoriteCollectionId: string) => Promise<void>;
  removeFavoritesFromFavoriteCollection: (
    favoriteCollectionId: string,
    favoriteIds: string[],
  ) => Promise<void>;
  refetchFavoriteCollections: () => Promise<void>;
}

export const UserFavoriteCollectionsContext = createContext<UserFavoriteCollectionsContextValue>(
  {} as UserFavoriteCollectionsContextValue,
);

type UserCollectionsProviderProps = Omit<
  React.ProviderProps<UserFavoriteCollectionsContextValue>,
  'value'
>;

export const UserFavoriteCollectionsProvider: React.FC<UserCollectionsProviderProps> = ({
  children,
}: UserCollectionsProviderProps) => {
  const {
    addFavoriteCollection: addFavoriteCollection_,
    addFavoriteToFavoriteCollection: addFavoriteToFavoriteCollection_,
    cloneFavoriteCollection: cloneFavoriteCollection_,
    getFavoriteCollectionById,
    getFavoriteCollections,
    updateFavoriteCollection: updateFavoriteCollection_,
    removeFavoriteCollection: removeFavoriteCollection_,
    removeFavoritesFromFavoriteCollection: removeFavoritesFromFavoriteCollection_,
  } = useApiClient();

  const { mutateAsync: addFavoriteCollectionMutation } = addFavoriteCollection_();
  const { mutateAsync: addFavoriteToFavoriteCollectionMutation } =
    addFavoriteToFavoriteCollection_();
  const { mutateAsync: cloneFavoriteCollectionMutation } = cloneFavoriteCollection_();
  const { mutateAsync: updateFavoriteCollectionMutation } = updateFavoriteCollection_();
  const { mutateAsync: removeFavoriteCollectionMutation } = removeFavoriteCollection_();
  const { mutateAsync: removeFavoritesFromFavoriteCollectionMutation } =
    removeFavoritesFromFavoriteCollection_();

  const { uniqueId } = useUser();

  const {
    data,
    isLoading: isFavoriteCollectionsLoading,
    refetch: refetchFavoriteCollections_,
  } = getFavoriteCollections(!!uniqueId);

  const addFavoriteCollection = async (name: string, listingIds: string[]) => {
    const favoriteCollection = await addFavoriteCollectionMutation({ name, listingIds });

    await refetchFavoriteCollections();

    return favoriteCollection;
  };

  const addFavoritesToFavoriteCollection = async (
    favoriteCollectionId: string,
    favoriteIds: string[],
  ) => {
    const favoriteCollectionFavorite = await addFavoriteToFavoriteCollectionMutation({
      favoriteCollectionId,
      favoriteIds,
    });

    await refetchFavoriteCollections();

    return favoriteCollectionFavorite;
  };

  const cloneFavoriteCollection = async (favoriteCollectionId: string) => {
    const clonedFavoriteCollection = await cloneFavoriteCollectionMutation({
      favoriteCollectionId,
    });

    await refetchFavoriteCollections();

    return clonedFavoriteCollection;
  };

  const updateFavoriteCollection = async (favoriteCollectionId: string, name: string) => {
    const favoriteCollection = await updateFavoriteCollectionMutation({
      favoriteCollectionId,
      name,
    });

    await refetchFavoriteCollections();

    return favoriteCollection;
  };

  const removeFavoriteCollection = async (favoriteCollectionId: string) => {
    await removeFavoriteCollectionMutation({ favoriteCollectionId });

    await refetchFavoriteCollections();
  };

  const removeFavoritesFromFavoriteCollection = async (
    favoriteCollectionId: string,
    favoriteIds: string[],
  ) => {
    await removeFavoritesFromFavoriteCollectionMutation({
      favoriteCollectionId,
      favoriteIds,
    });

    await refetchFavoriteCollections();
  };

  const refetchFavoriteCollections = async () => {
    await refetchFavoriteCollections_();
  };

  const collectionsMap = (collections: SummaryFavoriteCollection[]) =>
    collections.map((collection) => ({ ...collection, name: decodeString(collection.name) }));

  const contextValue: UserFavoriteCollectionsContextValue = useMemo(
    () => ({
      favoriteCollections: data ? collectionsMap(data) : [],
      isFavoriteCollectionsLoading,
      addFavoriteCollection,
      addFavoritesToFavoriteCollection,
      cloneFavoriteCollection,
      getFavoriteCollectionById,
      updateFavoriteCollection,
      removeFavoriteCollection,
      removeFavoritesFromFavoriteCollection,
      refetchFavoriteCollections,
    }),
    [data],
  );

  return (
    <UserFavoriteCollectionsContext.Provider value={contextValue}>
      {children}
    </UserFavoriteCollectionsContext.Provider>
  );
};

import { createContext, useState } from 'react';
interface ReportCartContextValue {
  cartList: Set<string>;
  addToCart: (ListingIds: string[]) => void;
  isAdded: (ListingId: string) => boolean;
  removeFromCart: (ListingIds: string[]) => void;
  clearCart: () => void;
}

export const CartContext = createContext<ReportCartContextValue>({} as ReportCartContextValue);

export const CartProvider: React.FC<any> = ({ children }) => {
  const [cartList, setCartList] = useState<Set<string>>(new Set());

  const addToCart = (listingIds: string[]) => {
    const updatedSet = new Set(cartList);
    listingIds.forEach((item) => updatedSet.add(item));
    setCartList(updatedSet);
  };

  const removeFromCart = (listingIds: string[]) => {
    const updatedSet = new Set(cartList);
    listingIds.forEach((listingId) => updatedSet.delete(listingId));
    setCartList(updatedSet);
  };

  const isAdded = (listingId: string): boolean => {
    return cartList.has(listingId);
  };

  const clearCart = () => {
    setCartList(new Set());
  };

  const contextValue: ReportCartContextValue = {
    isAdded,
    removeFromCart,
    addToCart,
    cartList,
    clearCart,
  };

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};

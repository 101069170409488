import { IconName } from '@components/Icon';
import { CheckboxState } from '@components/Inputs';

export const enum BtnsDirection {
  COL = 'COL',
  ROW = 'ROW',
}

export interface ShareModalProps {
  cssClassNames?: string;
  header: string;
  mainActionIcon: IconName;
  mainActionLabel: string;
  onMainAction: () => void;
  onSendEmail: (recipient: string[], attachReport: boolean) => void;
  onClose: () => void;
  show: boolean;
  email?: string;
  attachReportCheckBox?: Checkbox;
  singleRecipient?: boolean;
}

export interface Checkbox {
  label: string;
}

import React from 'react';
import cn from 'classnames';

import { Icon, IconName } from '@components/Icon';
import './Modal.css';
import { ModalProps } from './Modal.types';

const Modal: React.FC<ModalProps> = ({
  children,
  classNames,
  customCloseBtn,
  haveClose,
  onClose,
  show,
  theme,
  isSmallScreen,
  customAllMediaButton,
}) => {
  return (
    <>
      {show && isSmallScreen && (
        <>
          <div className="primary-modal-main">
            {customAllMediaButton}
            <div className="swiper-custom-pagination" />
            {customCloseBtn}
            <div className="primary-modal-sub media-modal">
              <div className={cn(['primary-modal-box', classNames])}>
                <div className="h-full">{children}</div>
              </div>
            </div>
          </div>
          <div className="primary-modal-bg"></div>
        </>
      )}
      {show && !isSmallScreen && (
        <>
          <div className="primary-modal-main">
            <div className="primary-modal-sub">
              <div className={cn(['primary-modal-box', classNames])}>
                <div className="h-full">
                  {customCloseBtn ||
                    (haveClose && (
                      <button className={`primary-modal-close-btn ${theme}`} onClick={onClose}>
                        <Icon name={IconName.CLOSE} />
                      </button>
                    ))}
                  {children}
                </div>
              </div>
            </div>
          </div>
          <div className="primary-modal-bg"></div>
        </>
      )}
    </>
  );
};

export default Modal;

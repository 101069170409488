import { createContext } from 'react';

import { getAppConfigByKey, getAppConfigs } from '@/api/app-config';
import {
  getAvailabilityReport,
  getFlyer,
  getExcelReport,
  shareAvailabilityReportViaEmail,
  shareFlyerViaEmail,
  getDataForExcel,
} from '@/api/documents';
import {
  addFavoriteCollection,
  addFavoriteToFavoriteCollection,
  cloneFavoriteCollection,
  getFavoriteCollectionById,
  getFavoriteCollections,
  removeFavoriteCollection,
  removeFavoritesFromFavoriteCollection,
  shareFavoriteCollectionViaEmail,
  updateFavoriteCollection,
} from '@/api/favorite-collections';
import { addFavorite, getFavorites, removeFavorites } from '@/api/favorites';
import {
  getListingData,
  getListingSearchBounds,
  searchListings,
  searchResultsListings,
  shareListingViaEmail,
  updateListingViewCount,
} from '@/api/listings';
import { getLocationSearchData } from '@/api/location-search';
import { getMarketBoundaries, getMarkets, getSubmarkets } from '@/api/markets';
import {
  addSavedSearch,
  getSavedSearchById,
  getSavedSearches,
  removeSavedSearch,
  shareSavedSearchViaEmail,
  updateSavedSearch,
} from '@/api/saved-searches';
import {
  createMe,
  getMe,
  getMicrosoftGraphUserInfo,
  getMicrosoftGraphUserPhoto,
  submitAnIdea,
} from '@/api/users';
import { marketplaceApiInstance, microsoftGraphApiInstance } from '@/lib/axios';
import { ActiveFilters, TemplateTypes } from '@/types';

export interface ApiClientContextValue {
  addFavorite: () => ReturnType<typeof addFavorite>;
  addFavoriteCollection: () => ReturnType<typeof addFavoriteCollection>;
  addFavoriteToFavoriteCollection: () => ReturnType<typeof addFavoriteToFavoriteCollection>;
  addSavedSearch: () => ReturnType<typeof addSavedSearch>;
  cloneFavoriteCollection: () => ReturnType<typeof cloneFavoriteCollection>;
  createMe: () => ReturnType<typeof createMe>;
  getAvailabilityReport: (
    listingIds: string[],
    title?: string,
  ) => ReturnType<typeof getAvailabilityReport>;
  getFavorites: (isEnabled: boolean) => ReturnType<typeof getFavorites>;
  getFavoriteCollections: (isEnabled: boolean) => ReturnType<typeof getFavoriteCollections>;
  getFavoriteCollectionById: (
    favoriteCollectionId: string,
  ) => ReturnType<typeof getFavoriteCollectionById>;
  getListingData: (
    templateType: TemplateTypes,
    listingIds: string[],
  ) => ReturnType<typeof getListingData>;
  getListingSearchBounds: (
    filters: Pick<
      ActiveFilters,
      'minLatitude' | 'maxLatitude' | 'minLongitude' | 'maxLongitude' | 'marketIds' | 'submarketIds'
    >,
  ) => ReturnType<typeof getListingSearchBounds>;
  getLocationSearchData: (query: string) => ReturnType<typeof getLocationSearchData>;
  getMarkets: () => ReturnType<typeof getMarkets>;
  getMarketBoundaries: (marketIds: string[]) => ReturnType<typeof getMarketBoundaries>;
  getMicrosoftGraphUserInfo: () => ReturnType<typeof getMicrosoftGraphUserInfo>;
  getMicrosoftGraphUserPhoto: () => ReturnType<typeof getMicrosoftGraphUserPhoto>;
  getFlyer: (listingIds: string) => ReturnType<typeof getFlyer>;
  getExcelReport: (listingIds: string[]) => ReturnType<typeof getExcelReport>;
  getDataForExcel: (listingIds: string[]) => ReturnType<typeof getDataForExcel>;
  getMe: () => ReturnType<typeof getMe>;
  getSavedSearches: (isEnabled: boolean) => ReturnType<typeof getSavedSearches>;
  getSavedSearchById: (id: string) => ReturnType<typeof getSavedSearchById>;
  getSubmarkets: (marketIds: string[]) => ReturnType<typeof getSubmarkets>;
  removeFavorites: () => ReturnType<typeof removeFavorites>;
  removeFavoriteCollection: () => ReturnType<typeof removeFavoriteCollection>;
  removeFavoritesFromFavoriteCollection: () => ReturnType<
    typeof removeFavoritesFromFavoriteCollection
  >;
  removeSavedSearch: () => ReturnType<typeof removeSavedSearch>;
  searchListings: (filters: ActiveFilters | null) => ReturnType<typeof searchListings>;
  searchResultsListings: (
    filters: ActiveFilters | null,
  ) => ReturnType<typeof searchResultsListings>;
  shareAvailabilityReportViaEmail: () => ReturnType<typeof shareAvailabilityReportViaEmail>;
  shareFavoriteCollectionViaEmail: () => ReturnType<typeof shareFavoriteCollectionViaEmail>;
  shareFlyerViaEmail: (propertyType: string) => ReturnType<typeof shareFlyerViaEmail>;
  shareListingViaEmail: () => ReturnType<typeof shareListingViaEmail>;
  updateListingViewCount: () => ReturnType<typeof updateListingViewCount>;
  shareSavedSearchViaEmail: () => ReturnType<typeof shareSavedSearchViaEmail>;
  submitAnIdea: () => ReturnType<typeof submitAnIdea>;
  updateFavoriteCollection: () => ReturnType<typeof updateFavoriteCollection>;
  updateSavedSearch: () => ReturnType<typeof updateSavedSearch>;
  getAppConfigs: () => ReturnType<typeof getAppConfigs>;
  getAppConfigByKey: (key: string) => ReturnType<typeof getAppConfigByKey>;
}

export const ApiClientContext = createContext<ApiClientContextValue>({} as ApiClientContextValue);

type ApiClientProviderProps = Omit<React.ProviderProps<ApiClientContextValue>, 'value'>;

export const ApiClientProvider: React.FC<ApiClientProviderProps> = ({
  children,
}: ApiClientProviderProps) => {
  const contextValue: ApiClientContextValue = {
    addFavorite: () => addFavorite(marketplaceApiInstance),
    addFavoriteCollection: () => addFavoriteCollection(marketplaceApiInstance),
    addFavoriteToFavoriteCollection: () => addFavoriteToFavoriteCollection(marketplaceApiInstance),
    addSavedSearch: () => addSavedSearch(marketplaceApiInstance),
    cloneFavoriteCollection: () => cloneFavoriteCollection(marketplaceApiInstance),
    createMe: () => createMe(marketplaceApiInstance),
    getAvailabilityReport: (listingIds: string[], title?: string) =>
      getAvailabilityReport(marketplaceApiInstance, listingIds, title),
    getFavorites: (isEnabled: boolean) => getFavorites(marketplaceApiInstance, isEnabled),
    getFavoriteCollections: (isEnabled: boolean) =>
      getFavoriteCollections(marketplaceApiInstance, isEnabled),
    getFavoriteCollectionById: (favoriteCollectionId: string) =>
      getFavoriteCollectionById(marketplaceApiInstance, favoriteCollectionId),
    getListingData: (templateType: TemplateTypes, listingIds: string[]) =>
      getListingData(marketplaceApiInstance, templateType, listingIds),
    getListingSearchBounds: (
      filters: Pick<
        ActiveFilters,
        | 'minLatitude'
        | 'maxLatitude'
        | 'minLongitude'
        | 'maxLongitude'
        | 'marketIds'
        | 'submarketIds'
      >,
    ) => getListingSearchBounds(marketplaceApiInstance, filters),
    getLocationSearchData: (query: string) => getLocationSearchData(marketplaceApiInstance, query),
    getMarkets: () => getMarkets(marketplaceApiInstance),
    getMarketBoundaries: (marketIds: string[]) =>
      getMarketBoundaries(marketplaceApiInstance, marketIds),
    getMicrosoftGraphUserInfo: () => getMicrosoftGraphUserInfo(microsoftGraphApiInstance),
    getMicrosoftGraphUserPhoto: () => getMicrosoftGraphUserPhoto(microsoftGraphApiInstance),
    getFlyer: (listingId: string) => getFlyer(marketplaceApiInstance, listingId),
    getExcelReport: (listingId: string[]) => getExcelReport(marketplaceApiInstance, listingId),
    getDataForExcel: (listingId: string[]) => getDataForExcel(marketplaceApiInstance, listingId),
    getMe: () => getMe(marketplaceApiInstance),
    getSavedSearches: (isEnabled: boolean) => getSavedSearches(marketplaceApiInstance, isEnabled),
    getSavedSearchById: (id: string) => getSavedSearchById(marketplaceApiInstance, id),
    getSubmarkets: (marketIds: string[]) => getSubmarkets(marketplaceApiInstance, marketIds),
    removeFavorites: () => removeFavorites(marketplaceApiInstance),
    removeFavoriteCollection: () => removeFavoriteCollection(marketplaceApiInstance),
    removeFavoritesFromFavoriteCollection: () =>
      removeFavoritesFromFavoriteCollection(marketplaceApiInstance),
    removeSavedSearch: () => removeSavedSearch(marketplaceApiInstance),
    searchListings: (filters: ActiveFilters | null) =>
      searchListings(marketplaceApiInstance, filters),
    searchResultsListings: (filters: ActiveFilters | null) =>
      searchResultsListings(marketplaceApiInstance, filters),
    shareAvailabilityReportViaEmail: () => shareAvailabilityReportViaEmail(marketplaceApiInstance),
    shareFavoriteCollectionViaEmail: () => shareFavoriteCollectionViaEmail(marketplaceApiInstance),
    shareFlyerViaEmail: (listingGroupType: string) =>
      shareFlyerViaEmail(marketplaceApiInstance, listingGroupType),
    shareListingViaEmail: () => shareListingViaEmail(marketplaceApiInstance),
    submitAnIdea: () => submitAnIdea(marketplaceApiInstance),
    updateListingViewCount: () => updateListingViewCount(marketplaceApiInstance),
    shareSavedSearchViaEmail: () => shareSavedSearchViaEmail(marketplaceApiInstance),
    updateFavoriteCollection: () => updateFavoriteCollection(marketplaceApiInstance),
    updateSavedSearch: () => updateSavedSearch(marketplaceApiInstance),
    getAppConfigByKey: (key: string) => getAppConfigByKey(marketplaceApiInstance, key),
    getAppConfigs: () => getAppConfigs(marketplaceApiInstance),
  };

  return <ApiClientContext.Provider value={contextValue}>{children}</ApiClientContext.Provider>;
};

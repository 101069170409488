import React from 'react';

import { useApiClient } from '@/hooks/useApiClient';
import { useToast } from '@/hooks/useToast';
import { SHARE_COLLECTION_QUERY_PARAMETER_NAME } from '@/utilities/constants';
import { IconName } from '@components/Icon';
import ShareModal from '@components/ShareModal';

interface ShareCollectionModalProps {
  show: boolean;
  favoriteCollectionId: string;
  listingIds: string[];
  onClose: () => void;
}

export const ShareCollectionModal: React.FC<ShareCollectionModalProps> = ({
  show,
  onClose,
  favoriteCollectionId,
  listingIds,
}: ShareCollectionModalProps) => {
  const { shareFavoriteCollectionViaEmail } = useApiClient();
  const { addToast } = useToast();

  const { mutate: shareFavoriteCollectionViaEmailMutation } = shareFavoriteCollectionViaEmail();

  const checkBox = { label: 'Attach availability report' };
  const handleCopyLink = () => {
    const link = `${window.location.origin}/bookmarks/collections?${SHARE_COLLECTION_QUERY_PARAMETER_NAME}=${favoriteCollectionId}`;
    navigator.clipboard.writeText(link);

    addToast({
      id: 'shared-favorite-collection-' + favoriteCollectionId,
      description: 'URL Copied to Clipboard',
      title: 'Success',
      type: 'success',
    });
  };

  const handleSendEmail = (recipient: string[], attachReport: boolean) => {
    shareFavoriteCollectionViaEmailMutation({
      recipient,
      favoriteCollectionId,
      attachReport,
    });
    const descriptionText =
      recipient.length === 1
        ? `Email sent to ${recipient.length} address`
        : `Email sent to ${recipient.length} addresses`;

    addToast({
      id: 'share-favorite-collection-' + favoriteCollectionId,
      description: descriptionText,
      title: 'Success',
      type: 'success',
    });
  };

  return (
    <ShareModal
      show={show}
      onClose={onClose}
      header="Share Collection"
      mainActionIcon={IconName.LINK}
      mainActionLabel="Copy Link"
      attachReportCheckBox={checkBox}
      onMainAction={handleCopyLink}
      onSendEmail={handleSendEmail}
    />
  );
};

import React, { useMemo } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Icon, IconName } from '@components/Icon';
import { Checkbox } from '@components/Inputs';
import { Typography } from '@components/Typography';
import { formatNumberWithCommas } from '@/utilities/textHelpers';
import './CollectionCard.css';
import { CollectionCardProps } from './CollectionCard.types';
import { getCardImagesUrls } from './CollectionCard.utils';
import CollectionCardOptions from './CollectionCardOptions';

const CollectionCard: React.FC<CollectionCardProps> = ({
  addToOppsReportChecked,
  addToOppsReportOnClick,
  collectionId,
  collectionName,
  createOppsReportEnabled,
  numberOfListings,
  thumbList,
  optionHandlers,
}) => {
  const navigate = useNavigate();
  const { heroImageUrl, thumbnailImagesUrls } = useMemo(
    () => getCardImagesUrls(thumbList),
    [thumbList],
  );

  const showPlaceholderImage = !heroImageUrl || !thumbList.length;

  const imagesContainerClassNames = cn([
    'collection-cards-image flex h-[10.5625rem] justify-between mb-4 rounded w-full md:w-[19.875rem] space-x-2',
    showPlaceholderImage && 'bg-cement-100 items-center justify-center',
  ]);

  return (
    <div
      onClick={() => {
        if (createOppsReportEnabled) {
          if (numberOfListings === 0) {
            return;
          }
          addToOppsReportOnClick(collectionId);
        } else {
          navigate(`${collectionId}`);
        }
      }}
      className={cn([
        'collection-cards-container border bg-base-white border-cement-300 h-[15.9375rem]] p-4 rounded-lg w-[100%] md:w-[21.875rem] cursor-pointer mt-2 mb-4',
        createOppsReportEnabled && numberOfListings === 0
          ? 'opacity-60 cursor-not-allowed'
          : undefined,
      ])}>
      {createOppsReportEnabled && (
        <Checkbox
          classNames="mb-4"
          checkedState={addToOppsReportChecked}
          disableIndeterminateOption
          disabled={createOppsReportEnabled && numberOfListings === 0}
        />
      )}

      <div className={imagesContainerClassNames}>
        {showPlaceholderImage && (
          <Icon classNames="text-cement-300 !h-[4.75rem] !w-full" name={IconName.FACILITY} />
        )}
        {heroImageUrl && (
          <div
            className={cn([
              'h-full bg-center rounded bg-cover',
              thumbnailImagesUrls.length ? 'w-full sm:w-[14.375rem]' : 'w-full',
            ])}
            style={{ backgroundImage: `url(${heroImageUrl})` }}
          />
        )}
        {!!thumbnailImagesUrls.length && (
          <div className="flex flex-col justify-between w-[6rem] sm:w-20">
            {thumbnailImagesUrls.map((url, index) => (
              <div
                key={`collection-card-thumbnail-image-${index}`}
                className="w-full h-[3.1875rem] bg-center bg-cover rounded"
                style={{ backgroundImage: `url(${url})` }}
              />
            ))}
          </div>
        )}
      </div>

      <div className="relative flex items-center justify-between w-full">
        <Typography variant="subtitle-1" className="max-w-[90%] break-all">
          {collectionName}
        </Typography>
        {!createOppsReportEnabled && (
          <CollectionCardOptions
            collectionId={collectionId}
            numberOfListings={numberOfListings}
            deleteHandler={optionHandlers.deleteHandler}
            renameHandler={optionHandlers.renameHandler}
            copyLinkHandler={optionHandlers.copyLinkHandler}
            createOpHandler={optionHandlers.createOpHandler}
          />
        )}
      </div>
      <Typography className="text-cement-500" variant="body-4">
        {formatNumberWithCommas(numberOfListings)} Listings
      </Typography>
    </div>
  );
};

export default CollectionCard;

export type FormatLinkClassNamesFunc = (
  disabled: boolean,
  error: boolean,
  variant: LinkVariant,
) => string;

type LinkOnClickProp = {
  onClick: () => void;
  url?: never;
};

type LinkUrlProp = {
  onClick?: never;
  url: string;
};

type LinkDefaultProps = {
  children: React.ReactNode;
  classNames?: string;
  disabled?: boolean;
  error?: boolean;
  variant?: LinkVariant;
  newTab?: boolean;
};

export type LinkProps = LinkDefaultProps & (LinkOnClickProp | LinkUrlProp);

export enum LinkVariant {
  DEFAULT = 'DEFAULT',
  DARK_THEME = 'DARK_THEME',
  SAVED_SEARCH_CARD = 'SAVED_SEARCH_CARD',
}

import useIsBigScreen from '@/hooks/useIsBigScreen';
import { TemplateAttributeKey, TemplateTypes, TemplateValue } from '@/types/templates';
import { ConstrainedTypography } from '@components/ConstrainedTypography';
import ContactBroker from '@components/ContactBroker';
import PrimaryInfo from '@components/PrimaryInfo';
import Tab from '@components/Tab';
import Thumbnail from '@components/Thumbnail/Thumbnail';
import { Typography } from '@components/Typography';
import UnitSpecs from '@components/UnitSpecs';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import './ListingDetails.css';
import ListingDetailsMap from './ListingDetailsMap';
import { isInViewport, hasAScroll } from './ListingDetails.utils';
import { isIndustrialOrPark } from '@/utilities/constants';
import { ternaryOperation, sortBy } from '@/utilities/functions';
import { Icon, IconName } from '@components/Icon';
import AssetsCarousel from '@components/AssetsCarousel';
import Modal from '@components/Modal/Modal';
import { useMediaQuery } from 'react-responsive';

export interface ListingDetailsContentMobileProps {
  data: TemplateValue[];
}
const ListingDetailsContentMobile: React.FC<ListingDetailsContentMobileProps> = ({ data }) => {
  const isBigScreen = useIsBigScreen();
  const [isBottom, setIsBottom] = useState<boolean>(false);
  const [pageHasAScroll, setPageHasAScroll] = useState<boolean>(hasAScroll());
  const [showAssetsGalleryModal, setShowAssetsGalleryModal] = useState(false);
  const [showAssetsCarouselModal, setShowAssetsCarouselModal] = useState(false);
  const [showSitePlanCarouselModal, setShowSitePlanCarouselModal] = useState(false);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 767px)' });

  const LISTING_DETAILS = useMemo(() => {
    return data?.find((children) => children.templateType === TemplateTypes.DetailedListing);
  }, []);

  //Info
  const LISTING_DETAILS__PROPERTY_SPECIFICATIONS = useMemo(() => {
    return LISTING_DETAILS?.children?.find(
      (children) => children.templateType === TemplateTypes.PropertySpecifications,
    );
  }, []);

  const NAME = useMemo(() => {
    return LISTING_DETAILS__PROPERTY_SPECIFICATIONS?.attributes?.find(
      (attribute) => attribute.key === TemplateAttributeKey.Name,
    );
  }, []);

  const FULL_ADDRESS = useMemo(() => {
    return LISTING_DETAILS__PROPERTY_SPECIFICATIONS?.attributes?.find(
      (attribute) => attribute.key === TemplateAttributeKey.FullAddress,
    );
  }, []);
  const BUILDING_STATUS = useMemo(() => {
    return LISTING_DETAILS__PROPERTY_SPECIFICATIONS?.attributes?.find(
      (attribute) => attribute.key === TemplateAttributeKey.BuildingStatus,
    );
  }, []);

  const DIGITAL_ASSETS = useMemo(() => {
    return LISTING_DETAILS__PROPERTY_SPECIFICATIONS?.attributes?.find(
      (attribute) => attribute.key === TemplateAttributeKey.DigitalAssets,
    );
  }, []);

  const assestsUrl = DIGITAL_ASSETS?.rawValue;

  //About Listing
  const LISTING_DETAILS__PROPERTY_DESCRIPTION = useMemo(() => {
    return LISTING_DETAILS?.children?.find(
      (children) => children.templateType === TemplateTypes.PropertyDescription,
    );
  }, []);
  const DESCRIPTION = useMemo(() => {
    return LISTING_DETAILS__PROPERTY_DESCRIPTION?.attributes?.find(
      (attribute) => attribute.key === TemplateAttributeKey.propertyDescription,
    );
  }, []);
  const [activeTab, setActiveTab] = useState('General');

  //Unit Specs
  const LISTING_DETAILS__UNIT_SPECIFICATIONS = useMemo(() => {
    return LISTING_DETAILS?.children?.find(
      (children) => children.templateType === TemplateTypes.UnitSpecifications,
    );
  }, []);

  //Unit Square Footage Range
  const LISTING_DETAILS__UNIT_SQUAREFOOTAGERANGE = useMemo(() => {
    return LISTING_DETAILS?.children?.find(
      (children) => children.templateType === TemplateTypes.SquareFootageRange,
    );
  }, []);

  //Site Plan
  const LISTING_DETAILS__SITE_PLAN = useMemo(() => {
    return LISTING_DETAILS?.children?.find(
      (children) => children.templateType === TemplateTypes.SitePlan,
    );
  }, []);
  //Map
  const LISTING_DETAILS__MAP = useMemo(() => {
    return LISTING_DETAILS?.children?.find(
      (children) => children.templateType === TemplateTypes.ListingDetailsMap,
    );
  }, []);
  const LONGITUDE = useMemo(() => {
    return LISTING_DETAILS__MAP?.attributes?.find((attribute) => attribute.key === 'LONGITUDE');
  }, []);
  const LATITUDE = useMemo(() => {
    return LISTING_DETAILS__MAP?.attributes?.find((attribute) => attribute.key === 'LATITUDE');
  }, []);

  //Contact Info
  const LISTING_DETAILS__CONTACT_INFORMATION = useMemo(() => {
    return LISTING_DETAILS?.children?.find(
      (children) => children.templateType === TemplateTypes.ContactInformation,
    );
  }, []);

  const sitePlanUrl = LISTING_DETAILS__SITE_PLAN?.attributes[0].rawValue;
  let filteredAssetUrls: string[] = sitePlanUrl?.split();
  const formatBuildingStatus = (value: string | undefined) => {
    if (value === undefined) return;
    let buildingStatus;
    switch (value) {
      case 'BUILD_TO_SUIT':
        buildingStatus = 'build to suit opportunity';
        break;
      case 'NEW_SPECULATIVE_DEVELOPMENT':
        buildingStatus = 'new class a development';
        break;
      case 'UNDER_CONSTRUCTION':
        buildingStatus = 'under construction';
        break;
    }
    return buildingStatus;
  };

  const formatContactInformation = (
    key: string,
    templateInfo: TemplateValue | undefined,
    propertyType: string,
  ) => {
    if (templateInfo === undefined) return;
    const brokerName = templateInfo.attributes.find((attribute) => attribute.key === 'BROKER_NAME');
    const brokerPhone = templateInfo.attributes.find(
      (attribute) => attribute.key === 'BROKER_PHONE_NUMBER',
    );
    const brokerShop = templateInfo.attributes.find((attribute) => attribute.key === 'BROKER_SHOP');
    const brokerEmail = templateInfo.attributes.find(
      (attribute) => attribute.key === 'BROKER_EMAIL',
    );
    const linkRepEmail = templateInfo.attributes.find(
      (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_EMAIL',
    );
    const linkRepName = templateInfo.attributes.find(
      (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_NAME',
    );
    const linkRepPhone = templateInfo.attributes.find(
      (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_PHONE_NUMBER',
    );
    let nameToShow = brokerName?.rawValue ?? '--',
      emailToShow = brokerEmail?.rawValue ?? '--',
      phoneToShow = brokerPhone?.rawValue ?? '--',
      shopToShow = brokerShop?.rawValue ?? '--';
    // if broker info exists, add 'Broker for' to the shop name
    if (shopToShow !== '--') {
      shopToShow = `Broker for ${shopToShow}`;
    }
    if (!brokerName?.rawValue && !!linkRepName) {
      nameToShow = linkRepName?.rawValue ?? '--';
      emailToShow = linkRepEmail?.rawValue ?? '--';
      phoneToShow = linkRepPhone?.rawValue ?? '--';
      shopToShow = nameToShow === '--' ? '--' : 'Agent for LINK Logistics';
    }
    if (isIndustrialOrPark(propertyType)) {
      shopToShow = 'Market Officer for Link Logistics';
      phoneToShow = '--';
    }
    let value;
    switch (key) {
      case 'nameToShow':
        value = nameToShow;
        break;
      case 'emailToShow':
        value = emailToShow;
        break;
      case 'phoneToShow':
        value = phoneToShow;
        break;
      case 'shopToShow':
        value = shopToShow;
        break;
      case 'brokerEmail':
        value = brokerEmail ? brokerEmail?.icon : linkRepEmail?.icon;
        break;
      case 'brokerPhone':
        value = brokerPhone ? brokerPhone?.icon : linkRepPhone?.icon;
        break;
    }
    return value;
  };

  const renderActiveTabContent = (): ReactNode => {
    const orderInfoMobile = [
      'MARKET',
      'SUBMARKET',
      'CONTACT_US_OR_DATE_AVAILABLE',
      'BUILDING_SQUARE_FOOTAGE',
      'ACREAGE',
    ];
    const orderUnitSpecsMobile = [
      'UNIT_SQUARE_FOOTAGE_RANGE',
      'UNIT_OFFICE_SQUARE_FOOTAGE_OR_BUILD_TO_SUIT',
      'CLEAR_HEIGHT',
      'LOADING_DOCKS',
      'DRIVE_IN_DOORS',
      'TRUCK_COURT_DEPTH',
      'TRAILER_PARKING_AVAILABLE',
      'RAIL_SERVED',
      'ESFR',
      'OUTDOOR_STORAGE',
      'TENANCY',
    ];

    let component;
    switch (activeTab) {
      case 'General':
        component = (
          <div id="Info-Mobile">
            <PrimaryInfo
              attributes={sortBy(
                LISTING_DETAILS__PROPERTY_SPECIFICATIONS?.attributes,
                orderInfoMobile,
              )}
              propertyType={LISTING_DETAILS?.propertyType}
            />
          </div>
        );

        break;
      case 'About':
        component = (
          <div id="About-Mobile">
            <Typography variant={'body-2'}>{DESCRIPTION?.value}</Typography>
          </div>
        );
        break;
      case 'Unit Specs':
        component = (
          <div id="Unit-Specs-Mobile">
            {BUILDING_STATUS?.rawValue !== 'BUILD_TO_SUIT' ? (
              <>
                <Typography variant={'h6'} className="pb-6">
                  {ternaryOperation(
                    isIndustrialOrPark(LISTING_DETAILS?.propertyType),
                    'Property Details',
                    ternaryOperation(
                      BUILDING_STATUS?.rawValue === 'NEW_SPECULATIVE_DEVELOPMENT' ||
                        BUILDING_STATUS?.rawValue === 'UNDER_CONSTRUCTION',
                      'Unit Details',
                      'Unit Specs',
                    ),
                  )}
                </Typography>
                <UnitSpecs
                  attributes={sortBy(
                    isIndustrialOrPark(LISTING_DETAILS?.propertyType)
                      ? LISTING_DETAILS__UNIT_SPECIFICATIONS?.attributes.filter(
                          (f) => f.label != 'Unit Sq Ft',
                        )
                      : LISTING_DETAILS__UNIT_SPECIFICATIONS?.attributes,
                    orderUnitSpecsMobile,
                  )}
                  propertyType={LISTING_DETAILS?.propertyType}
                />
              </>
            ) : (
              <>
                <Typography variant={'h6'} className="pb-6">
                  Unit Square Footage
                </Typography>
                <div className="border-b border-cement-200 border-dotted grid grid-cols-2 mb-[0.9375rem] md:w-[27.3125rem]">
                  <Typography variant="body-2">Available Range</Typography>
                  <Typography variant="subtitle-2">
                    {LISTING_DETAILS__UNIT_SQUAREFOOTAGERANGE?.attributes[0].value}
                  </Typography>
                </div>
              </>
            )}
          </div>
        );
        break;
      case 'Map View':
        component = (
          <div id="Map-View-Mobile">
            <div className="[&_.mapboxgl-map]:rounded-[0.4375rem] w-full h-[40rem] pointer-events-none drop-shadow-lg listing-map">
              <ListingDetailsMap latitude={LATITUDE?.rawValue} longitude={LONGITUDE?.rawValue} />
            </div>
          </div>
        );
        break;
    }
    return component;
  };

  const handleScroll = () => {
    const el = document.getElementById('contactBannerBottomMobile');
    if (isInViewport(el)) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };
  useEffect(() => {
    setPageHasAScroll(hasAScroll());
    handleScroll();
  }, []);

  useEffect(() => {
    setPageHasAScroll(hasAScroll());
    handleScroll();
  }, [activeTab]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const customCloseButton = (
    <button
      className="absolute flex space-x-[0.5625rem] hover:opacity-50 right-4 text-white-100 top-4"
      onClick={() => setShowAssetsGalleryModal(false)}>
      <Icon name={IconName.CLOSE} />
      <Typography variant="button">Close</Typography>
    </button>
  );

  let videoAssetsCount = 0;
  DIGITAL_ASSETS?.rawValue.map((asset: string) => {
    videoAssetsCount = asset.includes('.mp4') ? videoAssetsCount + 1 : videoAssetsCount;
  });

  let imageAssetsCount = DIGITAL_ASSETS?.rawValue.length - videoAssetsCount;

  const customMediaBanner = (
    <div
      role="none"
      className="absolute bottom-4 left-6 z-10 rounded-sm px-5 py-3 bg-white-100 border-slate-300 !h-[20px] !w-[60px] top-4"
      onClick={() => setShowAssetsGalleryModal(true)}>
      <div className="absolute flex left-[5px] top-[6px] text-white-100">
        <Icon
          classNames="!h-[0.731rem] !w-[0.813rem] margin-left-[5px]"
          name={IconName.CAMERA_ICON}
        />
        <Typography variant="body-2" className="text-black absolute top-[-4px] left-[15px]">
          {imageAssetsCount}
        </Typography>
        <Icon classNames="!h-[0.731rem] !w-[0.813rem] ml-4" name={IconName.VIDEO_ICON} />
        <Typography variant="body-2" className="text-black absolute top-[-4px] left-[45px]">
          {videoAssetsCount}
        </Typography>
      </div>
    </div>
  );

  return (
    <div className="md:mx-auto flex-col">
      <div id="Property-Image-Mobile" className="min-h-[12rem]">
        <Thumbnail
          propertyImageUrl={DIGITAL_ASSETS?.value}
          onClick={() => setShowAssetsGalleryModal(true)}>
          <div id="primary-info-badges">
            <div className="absolute top-6 left-6 z-10 flex">
              {formatBuildingStatus(BUILDING_STATUS?.rawValue) != undefined && (
                <Typography
                  variant="body-3"
                  className="py-[0.1875rem] mr-2 rounded-sm px-[0.5rem] bg-site-100 capitalize">
                  {formatBuildingStatus(BUILDING_STATUS?.rawValue)}
                </Typography>
              )}
            </div>
          </div>
          <div
            role="none"
            className="absolute bottom-4 left-6 z-10 rounded-sm px-5 py-3 bg-white-100 border-slate-300 !h-[20px] !w-[60px]"
            onClick={() => setShowAssetsGalleryModal(true)}>
            <div className="absolute flex left-[5px] top-[6px] text-white-100">
              <Icon
                classNames="!h-[0.731rem] !w-[0.813rem] margin-left-[5px]"
                name={IconName.CAMERA_ICON}
              />
              <Typography variant="body-2" className="text-black absolute top-[-4px] left-[15px]">
                {imageAssetsCount}
              </Typography>
              <Icon classNames="!h-[0.731rem] !w-[0.813rem] ml-4" name={IconName.VIDEO_ICON} />
              <Typography variant="body-2" className="text-black absolute top-[-4px] left-[45px]">
                {videoAssetsCount}
              </Typography>
            </div>
          </div>
          {sitePlanUrl ? (
            <div
              role="none"
              className="absolute bottom-4 right-6 z-10 px-5 py-3 border rounded-lg bg-white-100 border-slate-300"
              onClick={() => setShowSitePlanCarouselModal(true)}>
              <Typography variant="button">{'View Site Plan'}</Typography>
            </div>
          ) : (
            <></>
          )}
        </Thumbnail>
        {showAssetsGalleryModal && (
          <Modal
            classNames="!bg-transparent !p-0 !rounded-none"
            customCloseBtn={customCloseButton}
            show
            customAllMediaButton={customMediaBanner}
            isSmallScreen={isSmallScreen}>
            <div className="h-[93vh] overflow-y-auto image-scroll">
              {DIGITAL_ASSETS?.rawValue.map((asset: string) =>
                asset.includes('.mp4') ? (
                  <div
                    role="none"
                    key={asset}
                    className="relative w-full mt-[10px]"
                    onClick={() => {
                      setShowAssetsCarouselModal(true);
                      setShowAssetsGalleryModal(false);
                    }}>
                    <Icon
                      classNames="!h-14 !w-14 absolute top-[43%] left-[43%]"
                      name={IconName.VIDEO_PLAY_BUTTON}
                    />
                    <video>
                      <source src={asset} type="video/mp4" />
                    </video>
                  </div>
                ) : (
                  <div
                    role="none"
                    key={asset}
                    className="relative w-full mt-[10px]"
                    onClick={() => {
                      setShowAssetsCarouselModal(true);
                      setShowAssetsGalleryModal(false);
                    }}>
                    <img alt="" src={asset} />
                  </div>
                ),
              )}
            </div>
          </Modal>
        )}
        {showAssetsCarouselModal && !showAssetsGalleryModal && (
          <AssetsCarousel
            assets={assestsUrl}
            onClose={() => setShowAssetsCarouselModal(false)}
            onAllMediaClick={() => {
              setShowAssetsCarouselModal(false);
              setShowAssetsGalleryModal(true);
            }}
            displayCustomMediaButton={true}
          />
        )}
        {showSitePlanCarouselModal && (
          <AssetsCarousel
            assets={filteredAssetUrls}
            onClose={() => setShowSitePlanCarouselModal(false)}
            displayCustomMediaButton={false}
          />
        )}
      </div>

      <div className="flex-col px-6 mt-4 space-y-2" id="property-name-address-mobile">
        <ConstrainedTypography className="mb-2.5" variant="h5" width="max-w-[40.625rem]">
          {NAME?.value}
        </ConstrainedTypography>
        <Typography variant="body-2" className="mb-[2.125rem] text-slate-300 mb-2">
          {FULL_ADDRESS?.value}
        </Typography>

        <div id="property-tab-group ">
          <div id="property-tab-group-headers" className="flex space-x-8">
            <Tab
              label={'General'}
              active={activeTab === 'General'}
              onClick={() => setActiveTab('General')}
            />
            {DESCRIPTION?.value !== '--' && (
              <Tab
                label={'About'}
                active={activeTab === 'About'}
                onClick={() => setActiveTab('About')}
              />
            )}
            <Tab
              label={isIndustrialOrPark(LISTING_DETAILS?.propertyType) ? 'Property' : 'Unit'}
              active={activeTab === 'Unit Specs'}
              onClick={() => setActiveTab('Unit Specs')}
            />
            <Tab
              label={'Map'}
              active={activeTab === 'Map View'}
              onClick={() => setActiveTab('Map View')}
            />
          </div>
        </div>
      </div>
      <div id="property-tab-group-content" className="p-6 pt-8 min-h-[10rem]">
        {renderActiveTabContent()}
      </div>
      <div id="contactBannerBottomMobile"></div>
      <div
        id="contact-broker-mobile"
        className={
          pageHasAScroll === false
            ? 'flex justify-center w-full contact-banner-footer'
            : isBottom
            ? 'flex justify-center w-full contact-banner-bottom'
            : 'flex justify-center w-full contact-banner'
        }>
        <div className="property-listing-contact w-full">
          <ContactBroker
            name={formatContactInformation(
              'nameToShow',
              LISTING_DETAILS__CONTACT_INFORMATION,
              LISTING_DETAILS?.propertyType!,
            )}
            email={formatContactInformation(
              'emailToShow',
              LISTING_DETAILS__CONTACT_INFORMATION,
              LISTING_DETAILS?.propertyType!,
            )}
            phone={formatContactInformation(
              'phoneToShow',
              LISTING_DETAILS__CONTACT_INFORMATION,
              LISTING_DETAILS?.propertyType!,
            )}
            shop={formatContactInformation(
              'shopToShow',
              LISTING_DETAILS__CONTACT_INFORMATION,
              LISTING_DETAILS?.propertyType!,
            )}
            emailIcon={formatContactInformation(
              'brokerEmail',
              LISTING_DETAILS__CONTACT_INFORMATION,
              LISTING_DETAILS?.propertyType!,
            )}
            phoneIcon={formatContactInformation(
              'brokerPhone',
              LISTING_DETAILS__CONTACT_INFORMATION,
              LISTING_DETAILS?.propertyType!,
            )}
            isBigScreen={isBigScreen}
          />
        </div>
      </div>
    </div>
  );
};

export default ListingDetailsContentMobile;

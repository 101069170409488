import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ControlledMenu, MenuButton, MenuDivider } from '@szhsin/react-menu';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUser } from '@/hooks/useUser';
import { Icon, IconName } from '@components/Icon';
import { Typography } from '@components/Typography';
import AuthenticationMenuItem from './AuthenticationMenuItem';
import './AuthenticationMenu.css';
import ContactUsModal from '@components/ContactUsModal';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import { LISTVIEW_CACHE_KEY } from '@/utilities/constants';

const AuthenticationMenu: React.FC = () => {
  const [open, setOpen] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const isBigScreen = useIsBigScreen();
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const { instance } = useMsal();
  const { firstName, lastName, photoUrl, email } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    addHeaderClass();
  }, []);

  const addHeaderClass = () => {
    const el = document.getElementById('ControlledAuthenticationMenu');
    el?.parentElement?.classList.add('szh-menu-container-header-menu');
  };

  useEffect(() => {
    const containerElement = Array.from(
      document.getElementsByClassName('szh-menu-container') as HTMLCollectionOf<HTMLElement>,
    );
    if (containerElement?.length > 0) {
      if (open) {
        containerElement[0].style.display = 'block';
      } else {
        containerElement[0].style.display = 'none';
      }
    }
  }, [open]);

  if (!isAuthenticated) {
    return (
      <div className="cursor-pointer">
        <Typography className="px-6" variant="h6">
          Sign In
        </Typography>
      </div>
    );
  }

  const userIcon = useMemo(() => {
    if (photoUrl) {
      return (
        <div className="w-10 h-10">
          <img className="w-10 h-10 rounded-full" src={photoUrl} />
        </div>
      );
    } else if (!!firstName && !!lastName) {
      const userInitials = `${firstName.slice(0, 1).toUpperCase()}${lastName
        .slice(0, 1)
        .toUpperCase()}`;

      return (
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-site-100">
          <Typography variant="subtitle-1">{userInitials}</Typography>
        </div>
      );
    }
  }, [photoUrl, firstName, lastName]);

  function handleClose() {
    setOpen(!open);
  }

  return (
    <>
      <MenuButton
        className="rounded hover:bg-solar-200 sm:h-[36px] md:h-auto sm:p-1 md:p-0"
        onClick={handleClose}>
        <div className="authentication-menu space-x-3">
          <Icon name={IconName.USER_ICON} />
          <Typography variant="h6">{firstName}</Typography>
          <Icon name={open ? IconName.CHEVRON_UP : IconName.CHEVRON_DOWN} />
        </div>
        <div className="authentication-menu-mobile">
          <Icon name={IconName.MENU} />
        </div>
      </MenuButton>
      <ControlledMenu
        id="ControlledAuthenticationMenu"
        state={open ? 'open' : 'closed'}
        align="end"
        menuClassName={`z-[1000] overflow-hidden md:rounded-tl-md md:rounded-b-md md:!right-0 md:!left-[inherit] md:!top-[1.75rem] md:w-auto !top-0 w-full`}
        gap={16}
        shift={12}>
        <div className={'mx-6 md:mx-0'}>
          <div className="flex flex-row justify-between items-center w-full mt-4 md:hidden">
            <Typography className="text-base-white" variant="h6">
              {'Menu'}
            </Typography>
            <button onClick={() => setOpen(false)}>
              <Icon classNames="text-slate-100" name={IconName.CLOSE} />
            </button>
          </div>
          <AuthenticationMenuItem>
            {userIcon}
            <div>
              <Typography className="text-solar-100" variant="subtitle-2">
                {firstName}
              </Typography>
              <Typography className="text-cement-200" variant="body-4">
                {email}
              </Typography>
            </div>
          </AuthenticationMenuItem>
          <MenuDivider className="border border-slate-100" />
          <AuthenticationMenuItem
            onClick={() => {
              navigate('/bookmarks/favorites');
              setOpen(false);
            }}
            iconName={IconName.STAR}
            label="Favorites"
            hover
          />
          <AuthenticationMenuItem
            onClick={() => {
              navigate('/bookmarks/collections');
              setOpen(false);
            }}
            iconName={IconName.FOLDER}
            label="Collections"
            hover
          />
          <AuthenticationMenuItem
            onClick={() => {
              navigate('/bookmarks/saved-searches');
              setOpen(false);
            }}
            iconName={IconName.BOOKMARK}
            label="Saved Searches"
            hover
          />
          <div className="sm:block md:hidden">
            <MenuDivider className="border border-slate-100" />
            <AuthenticationMenuItem
              onClick={() => {
                sessionStorage.setItem(LISTVIEW_CACHE_KEY, 'map');
                window.open('/map', '_self');
                setOpen(false);
              }}
              iconName={IconName.MAP_SETTINGS}
              label="Map"
              hover
            />
            <AuthenticationMenuItem
              onClick={() => {
                setOpen(false);
                setShowContactUsModal(true);
              }}
              iconName={IconName.MAIL}
              label="Contact Us"
              hover
            />
          </div>
          {email?.endsWith('@linklogistics.com') && (
            <>
              {isBigScreen && <div className="knowledge-center"></div>}
              <AuthenticationMenuItem
                onClick={() => {
                  setOpen(false);
                  window.open(
                    'https://liprop.sharepoint.com/sites/TechnologyDepartment/SitePages/Property-Search-Knowledge-Center.aspx',
                    '_blank',
                  );
                }}
                iconName={IconName.QUESTION_CIRCLE}
                label="Knowledge Center"
                hover
              />
            </>
          )}
          <MenuDivider className="border border-slate-100" />
          <AuthenticationMenuItem
            label="Sign Out"
            onClick={() => {
              setOpen(false);
              const account = instance.getActiveAccount();
              instance.logoutRedirect({ account });
            }}
            hover
          />
        </div>
      </ControlledMenu>
      {showContactUsModal && (
        <ContactUsModal
          onClose={() => {
            setOpen(false);
            setShowContactUsModal(false);
          }}
        />
      )}
    </>
  );
};

export default AuthenticationMenu;

import React from 'react';
import { MapProvider as MapRefProvider } from 'react-map-gl';

import { MapActionsProvider } from './contexts/mapActionsContext';
import { MapDataProvider } from './contexts/mapDataContext';

export const MapProviders = ({ children }: { children: React.ReactNode }) => (
  <MapRefProvider>
    <MapActionsProvider>
      <MapDataProvider>{children}</MapDataProvider>
    </MapActionsProvider>
  </MapRefProvider>
);

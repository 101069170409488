import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { MapProvider as MapRefProvider } from 'react-map-gl';
import { Link } from 'react-router-dom';

import { DetailedFavoriteCollection, Favorite } from '@/types';
import { ConstrainedTypography } from '@components/ConstrainedTypography';
import { Icon, IconName } from '@components/Icon';
import Modal from '@components/Modal';
import OverflowHiddenWrapper from '@components/OverflowHiddenWrapper';
import { Typography } from '@components/Typography';
import { MapActionsProvider } from '../Map/contexts/mapActionsContext';
import { Toolbar } from '../Map/Toolbar';
import CollectionMap from './CollectionMap';
import { decodeString } from '@/utilities/textHelpers';
import './MapModal.css';
import { isiOS } from '@/utilities/constants';
import useIsBigScreen from '@/hooks/useIsBigScreen';

export interface MapModalProps {
  favoriteCollection: DetailedFavoriteCollection;
  onClose: () => void;
}

const MapModal: React.FC<MapModalProps> = ({ favoriteCollection, onClose }) => {
  const { favorites, name } = favoriteCollection;
  const visibleFavorites = favorites.filter((x) => x.isVisible);
  const isBigScreen = useIsBigScreen();

  const decodedCollectionName = decodeString(name);

  const customCloseButton = (
    <button
      className="absolute flex space-x-[0.5625rem] hover:opacity-50 right-0 text-white-100 top-[-2.625rem] map-modal-close"
      onClick={onClose}>
      <Icon name={IconName.CLOSE} />
      <Typography variant="button">Close</Typography>
    </button>
  );

  const [selectedFavorite, setSelectedFavorite] = useState<Favorite | null>(null);
  const [secondaryHoverFavorite, setSecondaryHoverFavorite] = useState<Favorite | null>(null);
  const [scrollHeight, setScrollHeight] = useState<number>();

  const ios = isiOS();
  // NOTE - To avoid confusion on overlapping pins, we combine nearby pins to
  // have the same pin number. This calculation creates a map from the Favorite
  // ID to it's grouped index.
  const encounteredIndices = new Set();
  let groupedIndexCounter = 0;
  const handleHoverChange = (listing: Favorite | null) => {
    if (ios) {
      setSelectedFavorite(listing);
    }
  };

  useEffect(() => {
    const height = document.getElementById('collectionName')?.clientHeight;
    const scrollHeight = ((window.innerHeight - (height ?? 0)) * 0.7) / 16; // Convert to rem
    setScrollHeight(scrollHeight);
  });

  const indexMap = Array.from(
    visibleFavorites
      .reduce((locationMap, favorite) => {
        const key = `${favorite.longitude.toFixed(6)}|${favorite.latitude.toFixed(6)}`;

        if (!locationMap.has(key)) {
          locationMap.set(key, [favorite]);
        } else {
          locationMap.get(key)!.push(favorite);
        }

        return locationMap;
      }, new Map<string, Favorite[]>())
      .values(),
  ).reduce((indexMap, locationGroup, index) => {
    for (const favorite of locationGroup) {
      indexMap.set(favorite.favoriteId, index);
    }

    return indexMap;
  }, new Map<string, number>());

  return (
    <div className="[&_.modal-sub]:max-w-none map-modal-container">
      <Modal
        classNames="!bg-transparent !p-0 !rounded-none"
        customCloseBtn={customCloseButton}
        show>
        <div className="map-modal-heading-mobile bg-slate-500">
          <div>
            <Typography
              className="mb-6 mt-4 mr-4 text-white-100 flex justify-between max-w-[90%] break-all"
              variant="title">
              {decodedCollectionName}
            </Typography>
          </div>
          <div className="map-modal-close-btn" onClick={onClose}>
            <Icon classNames="text-white-100 map-modal-close-icon" name={IconName.CLOSE} />
          </div>
        </div>
        <div className="border-0 bg-white-100 flex h-[51rem] rounded-lg w-[78rem] map-modal-content">
          <div className="bg-slate-500 h-full pl-6 pt-4 w-[25rem] rounded-l-[0.4375rem] map-modal-pins">
            <div className="map-modal-close-btn-lower" onClick={onClose}>
              <Icon classNames="text-white-100 map-modal-close-icon" name={IconName.CLOSE} />
            </div>
            {selectedFavorite && (
              <button
                className="text-xs font-thin text-white-100 cursor-pointer map-modal-clear-section mr-6 sm:mb-3 bg-slate-500 h-5 p-2"
                onClick={() => {
                  setSelectedFavorite(null);
                  setSecondaryHoverFavorite(null);
                }}>
                Clear Selection(s)
              </button>
            )}
            <div id="collectionName">
              <Typography
                className="mb-6 mt-4 mr-4 text-white-100 flex justify-between max-w-[90%] break-all map-modal-collection-name"
                variant="title">
                {decodedCollectionName}
              </Typography>
            </div>
            <OverflowHiddenWrapper
              classNames="[&_.overflow-hidden-inner-wrapper]:pr-6 map-modal-listing-wrapper"
              heightRem={scrollHeight}>
              {visibleFavorites.map((listing, index) => {
                const { favoriteId, addressLine1, addressLine2, listingId, name, size } = listing;
                const groupedIndex = indexMap.get(favoriteId)!;
                const listingsInGroup = visibleFavorites.filter(
                  (f) => indexMap.get(f.favoriteId) === groupedIndex,
                );
                if (encounteredIndices.has(groupedIndex)) {
                  groupedIndexCounter += 1;
                } else {
                  groupedIndexCounter = 1;
                }
                const isFirstOccurrence = !encounteredIndices.has(groupedIndex);
                if (isFirstOccurrence) {
                  encounteredIndices.add(groupedIndex);
                }
                const isLastOccuarance = groupedIndexCounter == listingsInGroup.length;

                return (
                  <div
                    key={listingId}
                    onMouseEnter={
                      isBigScreen
                        ? () => {
                            setSecondaryHoverFavorite(visibleFavorites[index]);
                            ios && setSelectedFavorite(visibleFavorites[index]);
                          }
                        : undefined
                    }
                    onMouseLeave={
                      isBigScreen
                        ? () => {
                            setSecondaryHoverFavorite(null);
                            ios && setSelectedFavorite(null);
                          }
                        : undefined
                    }
                    className={cn([
                      'p-[0.5rem] pb-[0.875rem] map-modal-list-item cursor-pointer',
                      index !== visibleFavorites.length - 1 &&
                        isLastOccuarance &&
                        'border-b border-b-slate-400',
                      listingsInGroup.find((l) => l.listingId === selectedFavorite?.listingId)
                        ? 'active'
                        : '',
                    ])}>
                    <div className="flex h-[1.5625rem] items-center mb-1 text-white-100">
                      <div className="relative flex items-center justify-center mr-2 w-[1.125rem] h-[1.75rem]">
                        {isFirstOccurrence && (
                          <Typography className="absolute text-black z-[1]" variant="subtitle-4">
                            {groupedIndex + 1}
                          </Typography>
                        )}
                        {isFirstOccurrence && (
                          <Icon
                            classNames="absolute !h-[1.625rem] mt-0.5 top-[0.125rem] !w-[1.125rem]"
                            name={IconName.COLLECTION_PIN}
                          />
                        )}
                      </div>
                      <Link to={`/listing/${listingId}`}>
                        <ConstrainedTypography
                          className="underline hover:text-cement-300"
                          variant="subtitle-2"
                          width="max-w-[19.3rem]">
                          {name}
                        </ConstrainedTypography>
                      </Link>
                    </div>
                    <div
                      className="pl-6"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedFavorite(visibleFavorites[index]);
                      }}
                      aria-hidden="true">
                      <Typography className="text-cement-300" variant="body-4">
                        {addressLine1}
                      </Typography>
                      <Typography className="mb-1 text-cement-300" variant="body-4">
                        {addressLine2.replace(',', '').trimStart()}
                      </Typography>
                      <Typography className="text-white-100" variant="body-4">
                        {size}
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </OverflowHiddenWrapper>
          </div>
          <div className="[&_.mapboxgl-map]:rounded-r-[0.4375rem] w-[calc(100%-17.5625rem)] relative map-modal-mapbox">
            <MapRefProvider>
              <MapActionsProvider>
                <CollectionMap
                  favorites={visibleFavorites}
                  selected={selectedFavorite}
                  hovered={secondaryHoverFavorite}
                  setSelected={setSelectedFavorite}
                  setHovered={setSecondaryHoverFavorite}
                  onHoverChange={handleHoverChange}
                />
                <Toolbar
                  isCollectionMap
                  className="absolute top-2/4 translate-y-[-50%] left-0 map-modal-toolbar"
                />
              </MapActionsProvider>
            </MapRefProvider>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MapModal;

import { Icon, IconName } from '@components/Icon';
import LinkMailTo from '@components/LinkMailTo/LinkMailTo';
import { Typography } from '@components/Typography';
import { useMemo } from 'react';
import { ContactBrokerProps } from './ContactBroker.types';

const ContactBroker: React.FC<ContactBrokerProps> = ({
  name,
  email,
  phone,
  shop,
  photoUrl,
  emailIcon,
  phoneIcon,
  isBigScreen,
}) => {
  const userIcon = useMemo(() => {
    if (photoUrl) {
      return (
        <div className="w-12 h-12">
          <img className="w-12 h-12 rounded-full" src={photoUrl} />
        </div>
      );
    } else if (name) {
      let userInitials = `${name.slice(0, 1).toUpperCase()}`;
      const nameArr = name.split(' ');
      if (nameArr.length > 1) {
        userInitials += `${nameArr[1].slice(0, 1).toUpperCase()}`;
      }

      return (
        <div>
          {userInitials && userInitials !== '-' && userInitials !== '--' ? (
            <div className="flex items-center justify-center w-14 h-14 rounded-full bg-solar-100">
              <Typography variant="h6" className="font-lg text-sm !font-normal text-white-100">
                {userInitials}
              </Typography>
            </div>
          ) : (
            <Icon classNames="!h-[3.5rem] !w-[3.5rem]" name={IconName.GREY_USER_ICON} />
          )}
        </div>
      );
    }
  }, [photoUrl, name]);
  const noBrokerInfo = !!(name === '--' && email === '--' && phone === '--' && shop === '--');

  return (
    <>
      <div
        className={
          noBrokerInfo
            ? 'p-4 flex sm:space-x-8 md:space-x-[89px] items-center justify-center'
            : 'p-4 flex sm:space-x-4 md:space-x-[89px] items-center justify-between'
        }>
        <div className="flex-col md:flex md:flex-row md:space-x-6 items-center justify-between">
          <div className="hidden md:block">{userIcon}</div>
          <div>
            <Typography className="mb-2" variant={isBigScreen ? 'subtitle-2' : 'subtitle-1'}>
              {name}
            </Typography>
            <Typography variant={isBigScreen ? 'body-4' : 'body-3'}>{shop}</Typography>
          </div>
        </div>
        <div className="flex-col">
          <div className="flex mb-4">
            {phoneIcon ? (
              <Icon classNames="mr-2 !w-4 !h-4" name={phoneIcon} />
            ) : (
              <div className="w-4 h-4 mr-2" />
            )}
            <Typography variant={isBigScreen ? 'body-4' : 'body-3'}>{phone}</Typography>
          </div>
          <div className="flex font-sm">
            {emailIcon ? (
              <Icon classNames="mr-2 !w-4 !h-4" name={emailIcon} />
            ) : (
              <div className="w-4 h-4 mr-2" />
            )}
            {email !== '--' ? (
              <LinkMailTo email={email} label={email}></LinkMailTo>
            ) : (
              <Typography
                className="!text-xs !font-medium !leading-[18px]"
                variant={isBigScreen ? 'body-4' : 'body-3'}>
                {email}
              </Typography>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactBroker;

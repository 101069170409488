export const ternaryOperation = (condition: any, ifTrue: any, ifFalse: any) => {
  return condition ? ifTrue : ifFalse;
};

export const sortBy = (valuesToSort: any[] | undefined, sortOrder: string | any[]) => {
  const ordering: any = {};
  for (let i = 0; i < sortOrder.length; i++) ordering[sortOrder[i]] = i;
  valuesToSort?.sort(function (a, b) {
    return ordering[a.key] - ordering[b.key] || a.key.localeCompare(b.key);
  });
  return valuesToSort;
};

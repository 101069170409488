import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { ActiveFilterQueryParam } from '@/types';
import { useApiClient } from '../useApiClient';
import useMarkets from '../useMarkets';
import { getActiveFiltersToStringMap } from './useDelimitedActiveFiltersValues.utils';

const useDelimitedActiveFiltersValues = (queryParameters?: string) => {
  const { getMarkets, getSubmarkets } = useApiClient();
  const location = useLocation();
  const { idLookup } = useMarkets();

  const searchParameters = new URLSearchParams(queryParameters ?? location.search);
  const marketIds = searchParameters.has(ActiveFilterQueryParam.MARKET_IDS)
    ? searchParameters.get(ActiveFilterQueryParam.MARKET_IDS)!.split(',')
    : [];

  const submarketIds = searchParameters.has(ActiveFilterQueryParam.SUBMARKET_IDS)
    ? searchParameters.get(ActiveFilterQueryParam.SUBMARKET_IDS)!.split(',')
    : [];

  const { data: markets } = getMarkets();
  const { data: submarkets } = getSubmarkets(marketIds);

  const delimitedActiveFiltersValues = useMemo(() => {
    const marketNames = markets?.filter((x) => marketIds.includes(x.marketId)).map((x) => x.name);
    const submarketNames = submarkets
      ?.filter((x) => submarketIds.includes(x.marketId))
      .map((x) => x.name);

    const filtersToStringMap = getActiveFiltersToStringMap(
      searchParameters,
      marketNames,
      submarketNames,
    );

    return Object.values(filtersToStringMap).join(' | ');
  }, [idLookup, submarkets]);

  return { delimitedActiveFiltersValues };
};

export default useDelimitedActiveFiltersValues;

import { createContext } from 'react';

import { useApiClient } from '@/hooks/useApiClient';
import { useUser } from '@/hooks/useUser';
import { SavedSearch } from '@/types';
import { decodeString } from '@/utilities/textHelpers';

interface UserSavedSearchesContextValue {
  addSavedSearch: (
    name: string,
    url: string,
    savedSearchCriteria: string,
    enableEmailAlerts: boolean,
  ) => Promise<SavedSearch | null>;
  isSavedSearchesLoading: boolean;
  savedSearches: SavedSearch[];
  removeSavedSearch: (userSavedSearchId: string) => Promise<boolean>;
  updateSavedSearch: (
    userSavedSearchId: string,
    name: string,
    url: string,
    enableEmailAlerts: boolean,
    savedSearchCriteria: string,
  ) => Promise<SavedSearch | null>;
}

export const UserSavedSearchesContext = createContext<UserSavedSearchesContextValue>(
  {} as UserSavedSearchesContextValue,
);

type UserSavedSearchesProviderProps = Omit<
  React.ProviderProps<UserSavedSearchesContextValue>,
  'value'
>;

export const UserSavedSearchesProvider: React.FC<UserSavedSearchesProviderProps> = ({
  children,
}: UserSavedSearchesProviderProps) => {
  const {
    addSavedSearch: addSavedSearch_,
    getSavedSearches,
    removeSavedSearch: removeSavedSearch_,
    updateSavedSearch: updateSavedSearch_,
  } = useApiClient();

  const { mutateAsync: addSavedSearchMutation } = addSavedSearch_();
  const { mutateAsync: removeSavedSearchMutation } = removeSavedSearch_();
  const { mutateAsync: updateSavedSearchMutation } = updateSavedSearch_();

  const { uniqueId } = useUser();

  const { data, isLoading, refetch } = getSavedSearches(!!uniqueId);

  const addSavedSearch = async (
    name: string,
    url: string,
    savedSearchCriteria: string,
    enableEmailAlerts: boolean,
  ) => {
    const savedSearch = await addSavedSearchMutation({
      name,
      url,
      savedSearchCriteria,
      enableEmailAlerts,
    });

    await refetch();

    return savedSearch;
  };

  const removeSavedSearch = async (savedSearchId: string) => {
    if (!data?.find((x: SavedSearch) => x.savedSearchId === savedSearchId)) {
      return false;
    }

    const success = await removeSavedSearchMutation({ savedSearchId });

    await refetch();

    return success;
  };

  const updateSavedSearch = async (
    savedSearchId: string,
    name: string,
    url: string,
    enableEmailAlerts: boolean,
    savedSearchCriteria: string,
  ) => {
    if (!data?.find((x: SavedSearch) => x.savedSearchId === savedSearchId)) {
      return null;
    }

    const savedSearch = await updateSavedSearchMutation({
      savedSearchId,
      name,
      url,
      savedSearchCriteria,
      enableEmailAlerts,
    });

    await refetch();

    return savedSearch;
  };

  const savedSearchesMap = (savedSearches: SavedSearch[]) =>
    savedSearches.map((savedSearch) => ({ ...savedSearch, name: decodeString(savedSearch.name) }));

  const contextValue: UserSavedSearchesContextValue = {
    addSavedSearch,
    isSavedSearchesLoading: isLoading,
    savedSearches: data ? savedSearchesMap(data) : [],
    removeSavedSearch,
    updateSavedSearch,
  };

  return (
    <UserSavedSearchesContext.Provider value={contextValue}>
      {children}
    </UserSavedSearchesContext.Provider>
  );
};

import { createContext, useEffect, useState } from 'react';

import { useApiClient } from '@/hooks/useApiClient';

interface UserContextValue {
  uniqueId: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  photoUrl: string | null;
}

type UserInfo = {
  firstName: string | null;
  lastName: string | null;
  emailAddress: string | null;
};

export const UserContext = createContext<UserContextValue>({} as UserContextValue);

type UserProviderProps = Omit<React.ProviderProps<UserContextValue>, 'value'>;

export const UserProvider: React.FC<UserProviderProps> = ({ children }: UserProviderProps) => {
  const { createMe, getMe, getMicrosoftGraphUserInfo, getMicrosoftGraphUserPhoto } = useApiClient();

  const { data: user, isLoading: isLoadingUser, refetch: refetchUser } = getMe();
  const { mutateAsync: createMeMutation } = createMe();

  const [uniqueId, setUniqueId] = useState<string | null>(null);

  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [photoUrl, setPhotoUrl] = useState<string | null>(null);

  useEffect(() => {
    if (isLoadingUser) {
      return;
    }

    if (user) {
      setUniqueId(user.uniqueId);
      return;
    }

    if (!userInfo) {
      return;
    }

    createMeMutation(userInfo).then(() => refetchUser());
  }, [isLoadingUser, user, userInfo]);

  useEffect(() => {
    if (process.env.JEST_WORKER_ID) {
      return;
    }

    getMicrosoftGraphUserInfo().then(({ givenName, mail, surname }) => {
      setUserInfo({
        firstName: givenName ?? null,
        lastName: surname ?? null,
        emailAddress: mail ?? null,
      });
    });

    getMicrosoftGraphUserPhoto().then((photoUrl) => {
      setPhotoUrl(photoUrl);
    });
  }, []);

  useEffect(() => {
    if (!uniqueId || !userInfo) {
      return;
    }

    (window as any).pendo.initialize({
      visitor: {
        id: uniqueId,
        email: userInfo.emailAddress,
      },

      // Accounts are groups that multiple visitors are associated with.
      // An Account ID is typically a business name or a unique number.
      // TODO: revisit when app open to external users
      account: {
        id: 'MarketplaceInternal',
        name: 'Link Logistics Marketplace',
      },
    });
  }, [uniqueId, userInfo]);

  const contextValue: UserContextValue = {
    uniqueId,
    firstName: userInfo?.firstName ?? null,
    lastName: userInfo?.lastName ?? null,
    email: userInfo?.emailAddress ?? null,
    photoUrl,
  };

  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};

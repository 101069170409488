import { FillExtrusionLayer, LngLatBounds } from 'mapbox-gl';
import React, { useEffect, useMemo, useState } from 'react';
import MapboxMap, { Layer, Marker, useMap } from 'react-map-gl';

import { MAPBOX_ACCESS_TOKEN, MAP_3D_ZOOM_THRESHOLD, MAP_STYLES } from '@/config';
import { Favorite } from '@/types';
import { Icon, IconName } from '@components/Icon';
import MapPinPopup from '@components/MapPinPopup';
import { Typography } from '@components/Typography';
import { useGeolocation } from '../Map/hooks/useGeolocation';
import { useMapActions } from '../Map/hooks/useMapActions';

import 'mapbox-gl/dist/mapbox-gl.css';
import { useMediaQuery } from 'react-responsive';

const CollectionMap: React.FC<{
  favorites: Favorite[];
  selected: Favorite | null;
  hovered: Favorite | null;
  setSelected: (fav: Favorite | null) => void;
  setHovered: (fav: Favorite | null) => void;
  onHoverChange: (listing: Favorite | null) => void;
}> = ({ favorites, selected, hovered, setSelected, setHovered, onHoverChange }) => {
  const { currentLocation } = useGeolocation();
  const isSmallScreen = useMediaQuery({ query: '(max-width: 499px)' });

  const { default: mapRef } = useMap();
  const { is3dActive, setIs3dDisabled, deactivate3d, flyTo, fitBounds } = useMapActions();

  const mapDefaultBounds = useMemo(() => {
    const { maxLatitude, maxLongitude, minLatitude, minLongitude } = favorites.reduce(
      (bounds, listings) => {
        // maximum latitude (West)
        if (!bounds.maxLatitude || listings.latitude > bounds.maxLatitude) {
          bounds.maxLatitude = listings.latitude;
        }

        // maximum longitude (South)
        if (!bounds.maxLongitude || listings.longitude > bounds.maxLongitude) {
          bounds.maxLongitude = listings.longitude;
        }

        // minimum latitude (East)
        if (!bounds.minLatitude || listings.latitude < bounds.minLatitude) {
          bounds.minLatitude = listings.latitude;
        }

        // minimum longitude (North)
        if (!bounds.minLongitude || listings.longitude < bounds.minLongitude) {
          bounds.minLongitude = listings.longitude;
        }

        return bounds;
      },
      {
        maxLatitude: 0,
        maxLongitude: 0,
        minLatitude: 0,
        minLongitude: 0,
      },
    );

    const lngLatBounds = new LngLatBounds();

    lngLatBounds.setSouthWest({ lng: maxLongitude, lat: maxLatitude });
    lngLatBounds.setNorthEast({ lng: minLongitude, lat: minLatitude });

    return lngLatBounds;
  }, []);

  useEffect(() => {
    // Move map to selected listing
    if (selected) {
      const { latitude, longitude } = selected!;
      flyTo({ center: [longitude, latitude], zoom: MAP_3D_ZOOM_THRESHOLD + 4 });
    } else {
      !is3dActive && fitBounds(mapDefaultBounds, { padding: 80, maxZoom: MAP_3D_ZOOM_THRESHOLD });
    }
  }, [selected]);

  const handleMouseOver = (listing: Favorite | null) => {
    // Let parent know about change in hovered listing
    setHovered(listing);
    onHoverChange(listing);
  };

  const handlePinClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    listing: Favorite,
  ) => {
    event.stopPropagation();

    // Let parent know about change in selected listing
    setSelected(listing);

    // Move map to selected listing
    const { latitude, longitude } = listing;
    flyTo({ center: [longitude, latitude], zoom: MAP_3D_ZOOM_THRESHOLD + 4 });
  };

  const handleMapZoomEnd = () => {
    const map = mapRef?.getMap();

    if (!map) {
      return;
    }

    const zoom = map.getZoom();

    if (zoom < MAP_3D_ZOOM_THRESHOLD) {
      if (is3dActive) {
        deactivate3d();
      }

      setIs3dDisabled(true);
    } else {
      setIs3dDisabled(false);
    }
  };

  // NOTE - To avoid confusion on overlapping pins, we combine nearby pins.

  const favoriteGroups = useMemo(
    () =>
      Array.from(
        favorites
          .reduce((locationMap, favorite) => {
            const key = `${favorite.longitude.toFixed(6)}|${favorite.latitude.toFixed(6)}`;

            if (!locationMap.has(key)) {
              locationMap.set(key, [favorite]);
            } else {
              locationMap.get(key)!.push(favorite);
            }

            return locationMap;
          }, new Map<string, Favorite[]>())
          .values(),
      ),
    [favorites],
  );

  const getThreeDBuildingsLayerProps = (): FillExtrusionLayer => {
    const zoom = mapRef?.getZoom() ?? 22;

    return {
      id: '3d-buildings',
      source: 'composite',
      'source-layer': 'building',
      filter: ['==', 'extrude', 'true'],
      type: 'fill-extrusion',
      minzoom: MAP_3D_ZOOM_THRESHOLD,
      layout: {
        visibility: zoom < MAP_3D_ZOOM_THRESHOLD ? 'none' : 'visible',
      },
      paint: {
        'fill-extrusion-color': '#aaa',
        'fill-extrusion-height': [
          'interpolate',
          ['linear'],
          ['zoom'],
          MAP_3D_ZOOM_THRESHOLD,
          0,
          MAP_3D_ZOOM_THRESHOLD + 0.05,
          ['get', 'height'],
        ],
        'fill-extrusion-base': [
          'interpolate',
          ['linear'],
          ['zoom'],
          MAP_3D_ZOOM_THRESHOLD,
          0,
          MAP_3D_ZOOM_THRESHOLD + 0.05,
          ['get', 'min_height'],
        ],
        'fill-extrusion-opacity': 1.0,
      },
    };
  };

  return (
    <MapboxMap
      attributionControl={false}
      initialViewState={{
        bounds: mapDefaultBounds,
        fitBoundsOptions: { maxZoom: MAP_3D_ZOOM_THRESHOLD, padding: 80 },
      }}
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      mapStyle={MAP_STYLES[0].url}
      onZoomEnd={handleMapZoomEnd}>
      {favoriteGroups.map((favoriteGroup, index) => {
        const favorite = favoriteGroup[0];
        const { latitude, listingId, longitude } = favorite;
        // If the looping ID is selected = Show black icon
        // If the looping ID is hovered = Show dark red with number
        let icon = IconName.COLLECTION_MAP_PIN;
        if (
          listingId == selected?.listingId ||
          favoriteGroup.find((l) => l.listingId === selected?.listingId)
        ) {
          icon = IconName.PIN_ACTIVE;
          isSmallScreen && handleMouseOver(favorite);
        } else if (
          listingId == hovered?.listingId ||
          favoriteGroup.find((l) => l.listingId === hovered?.listingId)
        ) {
          icon = IconName.PIN_SECONDARY_HOVER;
        }

        return (
          <Marker key={`pin-${listingId}`} latitude={latitude} longitude={longitude}>
            <div
              className="relative flex hover:cursor-pointer items-center justify-center w-[1.125rem] h-[1.75rem]"
              onClick={(event) => handlePinClick(event, favorite)}
              onMouseEnter={() => handleMouseOver(favorite)}
              onMouseLeave={() => handleMouseOver(null)}>
              {listingId !== selected?.listingId &&
                listingId !== hovered?.listingId &&
                !favoriteGroup.find((l) => l.listingId === selected?.listingId) &&
                !favoriteGroup.find((l) => l.listingId === hovered?.listingId) && (
                  <Typography className="absolute text-black z-[1]" variant="subtitle-1">
                    {index + 1}
                  </Typography>
                )}
              <Icon classNames="absolute !h-[3.75rem] mt-0.5 !w-[2.75rem]" name={icon} />
            </div>
          </Marker>
        );
      })}

      {hovered && (
        <MapPinPopup
          latitude={hovered.latitude}
          listingId={hovered.listingId}
          longitude={hovered.longitude}
          popupProps={{ anchor: 'bottom' }}
        />
      )}

      {currentLocation && (
        <Marker
          key="current-location"
          latitude={currentLocation.latitude}
          longitude={currentLocation.longitude}>
          <Icon name={IconName.CURRENT_LOCATION} />
        </Marker>
      )}

      {is3dActive && <Layer {...getThreeDBuildingsLayerProps()} />}
    </MapboxMap>
  );
};

export default CollectionMap;

import { QueryClientProvider } from '@tanstack/react-query';

import { ApiClientProvider } from '@/contexts/apiClientContext';
import { ToastProvider } from '@/contexts/toastContext';
import { UserProvider } from '@/contexts/userContext';
import { UserFavoriteCollectionsProvider } from '@/contexts/userFavoriteCollectionsContext';
import { UserFavoritesProvider } from '@/contexts/userFavoritesContext';
import { UserSavedSearchesProvider } from '@/contexts/userSavedSearchesContext';
import { queryClient } from '@/lib/react-query';
import { GeolocationProvider } from '@/pages/Map/contexts/geolocationContext';
import { CartProvider } from '@/contexts/reportCartContext';

/**
 * An application wrapper that provides any children with access to top-level
 * application state.
 */
export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastProvider>
        <ApiClientProvider>
          <UserProvider>
            <UserFavoriteCollectionsProvider>
              <UserFavoritesProvider>
                <UserSavedSearchesProvider>
                  <CartProvider>
                    <GeolocationProvider>{children}</GeolocationProvider>
                  </CartProvider>
                </UserSavedSearchesProvider>
              </UserFavoritesProvider>
            </UserFavoriteCollectionsProvider>
          </UserProvider>
        </ApiClientProvider>
      </ToastProvider>
    </QueryClientProvider>
  );
}

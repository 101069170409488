import React from 'react';

import { RemoveSavedModalProps } from './RemoveSavedModal.types';
import ConfirmationModal from '@components/ConfirmationModal';

const RemoveSavedModal: React.FC<RemoveSavedModalProps> = ({
  category,
  onCancel,
  onRemove,
  show,
  subtext,
}) => (
  <ConfirmationModal
    header={category === 'favorite' ? 'Are you sure?' : `Remove ${category}`}
    onClose={onCancel}
    primaryBtnLabel="Yes, I want to remove"
    primaryBtnOnClick={onRemove}
    secondaryBtnLabel="No, I want to cancel"
    secondaryBtnOnClick={onCancel}
    show={show}
    subText={subtext ?? `Are you sure you want to remove the selected ${category}?`}
  />
);

export default RemoveSavedModal;

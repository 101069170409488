import {
  FEATURE_FLAG_ENABLE_RESULT_PANEL_AVAILABILITY_REPORT,
  FEATURE_FLAG_PAGINATION_CARDS_PER_PAGE,
  FEATURE_FLAG_USE_FRONTEND_FILTERING,
  REPORT_MAX_LISTINGS,
} from '@/config';
import { useCart } from '@/hooks/useReportCart';
import { useMapData } from '@/pages/Map/hooks/useMapData';
import {
  Listing,
  ActiveFilters as SearchFilters,
  ActiveFilterQueryParam as SearchParameterNames,
} from '@/types';
import { formatNumberWithCommas, shortenNumber } from '@/utilities/textHelpers';
import Button, { ButtonVariant } from '@components/Button';
import GenerateAvailabilityReportModal from '@components/GenerateAvailabilityReportModal';
import { Icon, IconName } from '@components/Icon';
import SearchResultSkeleton from '@components/SearchResultCard/SearchResultSkeleton';
import Sort from '@components/Sort';
import { FilterOption } from '@components/Sort/Sort.types';
import { Typography } from '@components/Typography/Typography';
import cn from 'classnames';
import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Tooltip } from 'react-tooltip';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import NoMatchingResults from './NoMatchingResults';
import SearchResultList from './SearchResultList';
import { formatListingId, scrollToSelectedListing, sortNumericValues } from './SearchResults.utils';

import { searchResultsListings } from '@/api/listings';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import { useUserFavorites } from '@/hooks/useUserFavorites';
import { marketplaceApiInstance } from '@/lib/axios';
import { useMapActions } from '@/pages/Map/hooks/useMapActions';
import { Checkbox, CheckboxState, DatePickerTimeFrames, MinMaxRange } from '@components/Inputs';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import RemoveSavedModal from '@components/RemoveSavedModal';
import ellipse from '@images/loading-spinner/loading-spinner-ellipse.png';
import { LngLatBounds } from 'mapbox-gl';
import { useMediaQuery } from 'react-responsive';
import './SearchResults.css';

import { useApiClient } from '@/hooks/useApiClient';
import { useToast } from '@/hooks/useToast';
import { ListingExcelRow } from '@/types/excel';
import { EXCEL_REPORT_CHUNK_SIZE } from '@/utilities/constants';
import { ternaryOperation } from '@/utilities/functions';
import ConfirmationModal from '@components/ConfirmationModal';
import Spinner from '@components/LoadingSpinner/Spinner';
import SearchResultCard from '@components/SearchResultCard';
import * as FileSaver from 'file-saver';
import { useNavigate } from 'react-router-dom';
import XLSX from 'sheetjs-style';

const A_TO_Z = 'aToZ';
const Z_TO_A = 'zToA';
const SQF_I_X = 'SiTox';
const SQF_X_I = 'SxToi';
let filterOptions: FilterOption[] = [
  {
    label: 'Name (A - Z)',
    value: A_TO_Z,
  },
  {
    label: 'Name (Z - A)',
    value: Z_TO_A,
  },

  {
    label: 'Avail Sq Ft (Min - Max)',
    value: SQF_I_X,
  },
  {
    label: 'Avail Sq Ft (Max - Min)',
    value: SQF_X_I,
  },
];

export interface ResultsDataContextValue {
  listings: Listing[];
  selectedListing: Listing | null;
  setSelectedListing: (feature: Listing | null) => void;
  isLoading: boolean;
  setIsMoving: (isMoving: boolean) => void;

  showSearchResults: boolean;
  setShowSearchResults: (showSearchResults: boolean) => void;

  viewport: LngLatBounds | null;
  searchedLocation: string;
  setSearchedLocation: (searchedLocation: string) => void;
  dateAvailable: Date | undefined;
  dateTimeFrame: DatePickerTimeFrames | undefined;
  unitSquareFootageAvailableRange: MinMaxRange;
  marketId: string;
  marketIds: string[];
  submarketIds: string[];
  listingTypes: string[];
  clearHeightRange: MinMaxRange;
  loadingDocksRange: MinMaxRange;
  classifications: string[];
  trailerParking: boolean;
  railServed: boolean;
  outdoorStorage: boolean;
  esfr: boolean;
  fund: null | string;
  clearFilters: boolean;
  clearAllFilters: () => void;
  moreFilterCount: number;
  dateAvailableFilterCount: number;
  sqFtAvailableMinFilterCount: number;
  sqFtAvailableMaxFilterCount: number;

  updateSearchParameters: (
    entries: [string, boolean | string | number | null | undefined][],
  ) => void;
}

export const ResultsDataContext = createContext<ResultsDataContextValue>(
  {} as ResultsDataContextValue,
);

const SearchResults = ({ initialData, updateData, initialPageNumber, updatePageNumber }: any) => {
  const { showViewList } = useMapActions();
  const listRef = useRef<FixedSizeList<Listing[]>>(null);
  const resultCardsContainerRef = useRef<HTMLDivElement>(null);
  const isBigScreen = useIsBigScreen();
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1024px)' });
  const isSmallScreen = useMediaQuery({ query: '(max-width: 639px)' });

  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [availReportModal, setAvailReportModal] = useState<boolean>(false);
  const [showRemoveFavoriteModal, setShowRemoveFavoriteModal] = useState<boolean>(false);
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [exporting, setExporting] = useState<boolean>(false);
  const { addToast } = useToast();

  const [removeFavoriteId, setRemoveFavoriteId] = useState<string | null>(null);
  const [serviceLoading, isServiceLoading] = useState<boolean>(false);

  const {
    listings,
    selectedListing,
    isLoading,
    viewport,
    dateAvailable,
    dateTimeFrame,
    unitSquareFootageAvailableRange,
    marketId,
    marketIds,
    submarketIds,
    listingTypes,
    clearHeightRange,
    loadingDocksRange,
    classifications,
    trailerParking,
    railServed,
    outdoorStorage,
    esfr,
    fund,
  } = useMapData();

  const searchFilters = useMemo<SearchFilters | null>(() => {
    // NOTE - If we have enabled frontend filtering, then the only filters we
    // apply are the bounds of the US. This will return all listings on the
    // initial request and filter them accordingly. No further requests will be
    // made due to a stable react-query key.

    if (FEATURE_FLAG_USE_FRONTEND_FILTERING) {
      return {
        minLatitude: 20,
        maxLatitude: 50,
        minLongitude: -125,
        maxLongitude: -65,
        pageNumber: 1,
        pageSize: FEATURE_FLAG_PAGINATION_CARDS_PER_PAGE,
      };
    }

    // NOTE - The viewport will not be available until the map finishes its
    // initial load. We return null here to ensure that we don't make a request
    // until the viewport is available.

    if (!viewport) {
      return null;
    }

    return {
      minLatitude: viewport.getSouth(),
      maxLatitude: viewport.getNorth(),
      minLongitude: viewport.getWest(),
      maxLongitude: viewport.getEast(),
      dateAvailable: dateAvailable === null ? undefined : dateAvailable,
      minUnitSquareFootageAvailable:
        unitSquareFootageAvailableRange.min === ''
          ? undefined
          : Number(unitSquareFootageAvailableRange.min),
      maxUnitSquareFootageAvailable:
        unitSquareFootageAvailableRange.max === ''
          ? undefined
          : Number(unitSquareFootageAvailableRange.max),
      marketId: marketId === '' ? undefined : marketId,
      marketIds: marketIds.length === 0 ? undefined : marketIds,
      submarketIds: submarketIds.length === 0 ? undefined : submarketIds,
      listingTypes: listingTypes.length === 0 ? undefined : listingTypes,
      minClearHeight: clearHeightRange.min === '' ? undefined : Number(clearHeightRange.min),
      maxClearHeight: clearHeightRange.max === '' ? undefined : Number(clearHeightRange.max),
      minUnitDockDoors: loadingDocksRange.min === '' ? undefined : Number(loadingDocksRange.min),
      maxUnitDockDoors: loadingDocksRange.max === '' ? undefined : Number(loadingDocksRange.max),
      classifications: classifications.length === 0 ? undefined : classifications,
      trailerParking: trailerParking ? true : undefined,
      railServed: railServed ? true : undefined,
      outdoorStorage: outdoorStorage ? true : undefined,
      esfr: esfr ? true : undefined,
      fund: fund === null ? undefined : fund,
      pageNumber: 1,
    };
  }, [
    viewport,
    dateAvailable,
    dateTimeFrame,
    unitSquareFootageAvailableRange.min,
    unitSquareFootageAvailableRange.max,
    marketId,
    marketIds,
    submarketIds,
    listingTypes,
    clearHeightRange.min,
    clearHeightRange.max,
    loadingDocksRange.min,
    loadingDocksRange.max,
    classifications,
    trailerParking,
    railServed,
    outdoorStorage,
    esfr,
    fund,
  ]);

  const { cartList, clearCart, addToCart, removeFromCart } = useCart();
  const { removeFavorites } = useUserFavorites();

  const [selectedFilter, setSelectedFilter] = useState<FilterOption | undefined>(filterOptions[0]);
  const navigate = useNavigate();
  const selectedFilterRef = useRef<FilterOption | undefined>(selectedFilter);
  const { getExcelReport, getDataForExcel } = useApiClient();

  const formatList = (data: { featureCollection: { features: any } }) => {
    let result = data && data.featureCollection.features;
    return result;
  };

  const sortedListings = useMemo(() => {
    const searchParameters = new URLSearchParams(location.search);
    const currentSortQuery = searchParameters.get(SearchParameterNames.SORT);
    if (currentSortQuery) {
      const currentFilterOption = filterOptions.filter((f) => f.value === currentSortQuery)[0];
      const optionIndex = filterOptions.indexOf(currentFilterOption);

      setSelectedFilter(filterOptions[optionIndex]);
    } else {
      setSelectedFilter(filterOptions[0]);
    }
    const FilterValue = selectedFilter?.value;

    initialData.returnedPageNumber
      ? updatePageNumber(initialData.returnedPageNumber)
      : updatePageNumber(initialPageNumber);

    let result =
      initialData && initialData.featureCollection
        ? initialData.featureCollection.features
        : initialData;

    if (initialData) {
      switch (FilterValue) {
        case A_TO_Z:
          result.sort((a: { properties: { name: string } }, b: { properties: { name: any } }) =>
            a.properties.name.localeCompare(b.properties.name),
          );
          break;
        case Z_TO_A:
          result.sort((a: { properties: { name: any } }, b: { properties: { name: string } }) =>
            b.properties.name.localeCompare(a.properties.name),
          );
          break;
        case SQF_I_X:
          result.sort(
            (
              a: { properties: { unit_min_square_footage_available: any } },
              b: { properties: { unit_min_square_footage_available: any } },
            ) =>
              sortNumericValues(
                a.properties?.unit_min_square_footage_available,
                b.properties?.unit_min_square_footage_available,
              ),
          );
          break;
        case SQF_X_I:
          result.sort(
            (
              a: { properties: { unit_max_square_footage_available: any } },
              b: { properties: { unit_max_square_footage_available: any } },
            ) =>
              sortNumericValues(
                b.properties?.unit_max_square_footage_available,
                a.properties?.unit_max_square_footage_available,
              ),
          );
          break;
        default:
          break;
      }
    }

    return result;
  }, [listings, selectedFilter, initialData]);

  useEffect(() => {
    if (selectedFilterRef && selectedFilterRef?.current?.value !== selectedFilter?.value) {
      isServiceLoading(true);
      searchResultsListings(marketplaceApiInstance, {
        ...searchFilters,
        pageNumber: 1,
      } as SearchFilters).then((response) => {
        if (response.status === 200) {
          isServiceLoading(false);
          updatePageNumber(response.data.returnedPageNumber);
          updateData([...formatList(response.data)]);
        }
      });
    }
  }, [selectedFilter]);

  const handleScrollToListing = () => {
    listRef?.current?.scrollToItem(0);
  };

  const handleRemoveFavorite = (id: string | null) => {
    if (!id) return;
    removeFavorites([id]).then(() => {
      setShowRemoveFavoriteModal(false);
      setRemoveFavoriteId(null);
    });
  };

  const handleInfiniteScroll = () => {
    isServiceLoading(true);

    searchResultsListings(marketplaceApiInstance, {
      ...searchFilters,
      pageNumber: initialPageNumber + 1,
    } as SearchFilters).then((response) => {
      if (response.status === 200) {
        isServiceLoading(false);
        updatePageNumber(response.data.returnedPageNumber);
        updateData([...sortedListings, ...formatList(response.data)]);
      }
    });
  };

  const handleDownloadExcelReport = async (listingIds: string[]) => {
    setExporting(true);
    const fileExtension = '.xlsx';
    const data = await getExcelData(listingIds);
    if (data) {
      // Save file
      FileSaver.saveAs(data, `download-excel-report-${new Date().getTime()}${fileExtension}`);
      addToast({
        id: `download-excel-report-${new Date().getTime()}`,
        description: `Excel Report Downloaded`,
        title: 'Success',
        type: 'success',
      });
    } else {
      addToast({
        id: `download-excel-report-${new Date().getTime()}`,
        description: `Failed to generate excel report`,
        title: 'Error',
        type: 'error',
      });
    }
    setExporting(false);
    setShowExportModal(false);
  };

  useEffect(() => {
    handleScrollToListing();

    const listingIds = listings.map((obj) => String(obj.id));
    setSelectAll(!!listingIds.every((item) => cartList.has(item)));
  }, [listings]);

  useEffect(() => {
    if (selectedListing) {
      scrollToSelectedListing(listings, selectedListing, listRef);
    } else {
      handleScrollToListing();
    }
  }, [listings, selectedListing]);

  const heightReduction = useMediaQuery({ query: '(max-width: 768px)' }) ? 36 : 90;
  const handleResultSelect = (id: string) => navigate(`/listing/${id}`);
  const descriptionText =
    cartList.size === 1
      ? `${cartList.size} record will be exported as an Excel file.`
      : `${formatNumberWithCommas(cartList.size)} records will be exported as an Excel file.`;
  const exportText = ternaryOperation(
    cartList.size === 1,
    `Export ${cartList.size} record`,
    ternaryOperation(
      exporting,
      `Exporting ${formatNumberWithCommas(cartList.size)} records`,
      `Export ${formatNumberWithCommas(cartList.size)} records`,
    ),
  );
  const exportingSpinner = exporting ? <Spinner /> : <></>;
  const handleClose = () => {
    setShowExportModal(false);
    setExporting(false);
  };
  async function getExcelData(listingIds: string[]) {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    if (listingIds.length <= EXCEL_REPORT_CHUNK_SIZE) {
      return await getExcelReport(listingIds);
    } else {
      let allListingData: ListingExcelRow[] = [];
      for (let i = 0; i < listingIds.length; i += EXCEL_REPORT_CHUNK_SIZE) {
        const chunk = listingIds.slice(i, i + EXCEL_REPORT_CHUNK_SIZE);
        // Call API for chunk
        const excelChunk = await getDataForExcel(chunk);
        allListingData = allListingData.concat(excelChunk);
      }
      if (allListingData.length > 0) {
        // create excel stream and assign to data
        const ws = XLSX.utils.json_to_sheet(allListingData);
        let index = 1;
        Object.keys(allListingData[0]).forEach((key) => {
          const cellNumber = `${String.fromCharCode(index + 64)}1`;
          // Customize the header row font
          if (ws[cellNumber]) {
            ws[cellNumber].s = {
              font: {
                bold: true,
              },
            };
            index++;
          }
        });
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        return new Blob([excelBuffer], { type: fileType });
      }
    }
  }
  return (
    <>
      <div className={cn(['search-results', showViewList && 'show-view-list'])}>
        {isLoading ? (
          <div className="search-results-container">
            <div className="search-results-header-container">
              <Typography variant="body-3">Loading Results...</Typography>
              <Sort
                name="sort search results"
                disabled
                filterOptions={filterOptions}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                onFilterSelect={handleScrollToListing}
                customButtonLabel="Sort"
                showCustomButtonLabel={isSmallScreen}
              />
            </div>
            <div
              className="result-cards-container flex flex-row flex-wrap mx-4 xl:mr-0"
              ref={resultCardsContainerRef}>
              <SearchResultSkeleton />
              <SearchResultSkeleton />
              <SearchResultSkeleton />
              <SearchResultSkeleton />
              <SearchResultSkeleton />
              <SearchResultSkeleton />
              <SearchResultSkeleton />
              <SearchResultSkeleton />
              <SearchResultSkeleton />
              <SearchResultSkeleton />
            </div>
          </div>
        ) : (
          <>
            {listings.length ? (
              <div className="search-results-container">
                <div className={'flex flex-col'}>
                  <div className="search-results-counter">
                    {serviceLoading ? (
                      <Typography variant="body-3">Loading Results...</Typography>
                    ) : (
                      <Typography variant="body-3" className="text-cement-500">
                        {`Showing results 1 - ${formatNumberWithCommas(
                          Math.min(sortedListings.length, listings.length),
                        )} of ${formatNumberWithCommas(listings.length)} listings`}
                      </Typography>
                    )}
                  </div>
                  <div className="flex flex-row justify-between pl-2 pr-4">
                    <div className="search-results-header-container">
                      <div className="flex">
                        {FEATURE_FLAG_ENABLE_RESULT_PANEL_AVAILABILITY_REPORT && (
                          <>
                            <Checkbox
                              checkedState={selectAll ? CheckboxState.CHECKED : CheckboxState.EMPTY}
                              label="Select All "
                              name="checkbox"
                              className="pl-[0.3rem]"
                              onClick={() => {
                                if (selectAll) {
                                  const listingIds = listings.map((obj) => String(obj.id));
                                  removeFromCart(listingIds);
                                  setSelectAll(false);
                                } else {
                                  const listingIds = listings.map((obj) => String(obj.id));
                                  addToCart(listingIds);
                                  setSelectAll(true);
                                }
                              }}
                            />
                            <span
                              className="flex ml-2"
                              data-tooltip-id="report-info"
                              data-tooltip-place="top">
                              <Icon classNames="text-slate-100" name={IconName.ALERT_CIRCLE} />
                            </span>
                            <Tooltip id="report-info" className="z-10">
                              <Typography variant="body-4" className="max-w-[12.5rem]">
                                If you Select All and then change your filters or zoom level, this
                                field may deselect. However, your previous selections will remain.
                              </Typography>
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </div>

                    <Sort
                      name="sort search results"
                      filterOptions={filterOptions}
                      selectedFilter={selectedFilter}
                      setSelectedFilter={setSelectedFilter}
                      onFilterSelect={handleScrollToListing}
                      customButtonLabel="Sort"
                      showCustomButtonLabel={isSmallScreen}
                    />
                  </div>
                </div>
                <InfiniteScroll
                  dataLength={sortedListings.length - 1}
                  next={() => {
                    handleInfiniteScroll();
                  }}
                  hasMore={sortedListings.length < listings.length}
                  height={isBigScreen ? 'calc(100vh - 14.5rem)' : 'calc(100vh - 17.5rem)'}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  className={
                    isSmallScreen || isLargeScreen
                      ? '!overflow-x-hidden infinite-scroll-comp'
                      : '!overflow-x-hidden infinite-scroll-comp flex flex-col flex-wrap lg:flex-row flex-auto content-start'
                  }
                  // eslint-disable-next-line react/no-children-prop
                  children={
                    isLargeScreen ? (
                      <AutoSizer>
                        {({ height, width }) => (
                          <SearchResultList
                            currentResults={sortedListings}
                            height={Boolean(cartList.size) ? height - heightReduction : height}
                            listRef={listRef}
                            width={width}
                            handleRemoveFavorite={(favId: string) => {
                              setShowRemoveFavoriteModal(true);
                              setRemoveFavoriteId(favId);
                            }}
                          />
                        )}
                      </AutoSizer>
                    ) : (
                      sortedListings.map((item: any) => {
                        const property = item.properties;
                        const key = formatListingId(item.id as string);
                        const unitOfMeasure =
                          property.property_type === 'INDUSTRIAL' ||
                          property.property_type === 'LINK_PARK'
                            ? 'Unit Sq Ft'
                            : 'Avail Sq Ft';
                        const {
                          unit_max_square_footage_available,
                          unit_min_square_footage_available,
                        } = property;
                        let propertySize;
                        if (
                          unit_max_square_footage_available &&
                          unit_min_square_footage_available
                        ) {
                          const value =
                            unit_max_square_footage_available === unit_min_square_footage_available
                              ? unit_max_square_footage_available
                              : `${shortenNumber(
                                  unit_min_square_footage_available,
                                )}-${shortenNumber(unit_max_square_footage_available)}`;
                          propertySize = { value, unitOfMeasure };
                        }
                        return (
                          property && (
                            <div id={key} className="list-item-prop w-[100%]">
                              <SearchResultCard
                                acreage={property.acreage ?? undefined}
                                address={property.full_address}
                                clearHeight={property.clear_height ?? undefined}
                                dateAvailable={property.date_available ?? undefined}
                                dockDoors={property.unit_dock_doors ?? undefined}
                                driveInDoors={property.unit_drive_in_doors ?? undefined}
                                id={item.id as string}
                                imageUrl={property.thumbnail_image_url ?? undefined}
                                key={key}
                                buildingStatus={property.building_status ?? undefined}
                                onResultSelect={handleResultSelect}
                                propertySize={propertySize}
                                propertyType={property.property_type}
                                handleRemoveFavorite={(favId: string) => {
                                  setShowRemoveFavoriteModal(true);
                                  setRemoveFavoriteId(favId);
                                }}
                                unitName={property.name}
                                listingViewCount={property.listing_views}
                              />
                            </div>
                          )
                        );
                      })
                    )
                  }
                  loader={
                    <div className="absolute bottom-0 left-[47%] z-0 invisible">
                      <LoadingSpinner />
                    </div>
                  }
                />
              </div>
            ) : (
              <NoMatchingResults />
            )}

            {serviceLoading && (
              <div className="flex justify-center sm:relative sm:bottom-[2rem] lg:bottom-0 lg:ml-12">
                <div className="flex h-[3.5rem] w-[3.5rem] items-center justify-center relative">
                  <img className="loading-spinner-ellipse" src={ellipse} />
                </div>
              </div>
            )}

            {Boolean(cartList.size) && (
              <div className="report-list-container z-[100] fixed w-full lg:w-[unset] bottom-0 right-0">
                <div className="report-list-box justify-between sm:flex-col md:flex-row">
                  <div className="sm:w-[100%] md:w-[38%] flex sm:justify-between md:flex-col flex-row sm:mb-2">
                    <Typography variant="body-2" className="mb-1">
                      ({formatNumberWithCommas(cartList.size)}) LISTINGS SELECTED
                    </Typography>

                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        setSelectAll(false);
                        clearCart();
                      }}>
                      <Typography variant="link">Clear Selection</Typography>
                    </span>
                  </div>

                  <div className="sm:w-[100%] md:w-[60%] gap-1 flex sm:justify-between md:justify-end">
                    <div>
                      <div
                        className="w-full"
                        data-tooltip-id="max-export-tooltip"
                        data-tooltip-target="tooltip-default">
                        <Button
                          label="Export"
                          Icon={<Icon name={IconName.EXPORT} />}
                          onClick={() => setShowExportModal(true)}
                          classNames="h-11"
                          onMouseOut={function noRefCheck() {}}
                          variant={ButtonVariant.DEFAULT_OUTLINE}
                        />
                      </div>
                    </div>
                    <div>
                      <div
                        data-tooltip-id="max-listings-tooltip"
                        data-tooltip-target="tooltip-default">
                        <Button
                          label="Create Availability Report"
                          onClick={() => setAvailReportModal(true)}
                          classNames="w-full h-11"
                          onMouseOut={function noRefCheck() {}}
                          variant={ButtonVariant.REPORT_HP}
                          disabled={cartList.size > REPORT_MAX_LISTINGS}
                        />
                      </div>
                      {cartList.size > REPORT_MAX_LISTINGS && (
                        <Tooltip id="max-listings-tooltip" place="top">
                          <Typography variant="body-4">
                            Reports cannot contain more than {REPORT_MAX_LISTINGS} listings
                          </Typography>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>

      {showRemoveFavoriteModal && (
        <RemoveSavedModal
          category="favorite"
          onCancel={() => setShowRemoveFavoriteModal(false)}
          onRemove={() => handleRemoveFavorite(removeFavoriteId)}
          show={showRemoveFavoriteModal}
          subtext="Removing these listing(s) from Favorites will also remove them from any saved Collections."
        />
      )}

      {showExportModal && (
        <ConfirmationModal
          header="Export"
          primaryBtnLabel={exportText}
          primaryBtnIcon={exportingSpinner}
          primaryBtnDisabled={exporting}
          primaryBtnOnClick={() => handleDownloadExcelReport(Array.from(cartList))}
          secondaryBtnLabel="Cancel"
          secondaryBtnOnClick={handleClose}
          secondaryBtnDisabled={exporting}
          onClose={handleClose}
          secondaryBtnVariant={ButtonVariant.DEFAULT_OUTLINE}
          show={showExportModal}
          subText={
            <>
              {descriptionText}
              <br /> Are you sure you want to export?
            </>
          }
        />
      )}

      {availReportModal && (
        <GenerateAvailabilityReportModal
          listingGroups={[{ listingIds: Array.from(cartList) }]}
          onConfirm={() => {
            setAvailReportModal(false);
            clearCart();
            setSelectAll(false);
          }}
          onClose={() => {
            setAvailReportModal(false);
          }}
        />
      )}
    </>
  );
};

export default SearchResults;

import { Menu, MenuButton, MenuItem } from '@szhsin/react-menu';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { useApiClient } from '@/hooks/useApiClient';
import { useToast } from '@/hooks/useToast';
import { useUserFavorites } from '@/hooks/useUserFavorites';
import { Favorite } from '@/types';
import Button, { ButtonVariant } from '@components/Button';
import FavoritesTable from '@components/FavoritesTable';
import Footer from '@components/Footer';
import GenerateAvailabilityReportModal from '@components/GenerateAvailabilityReportModal';
import { Icon, IconName } from '@components/Icon';
import PostFavoritedModals from '@components/PostFavoritedModals';
import { RemoveFavoritesModal } from '@components/RemoveFavoritesModal';
import RemoveUnavailableListingsModal from '@components/RemoveUnavailableListingsModal/RemoveUnavailableListingsModal';
import SavedContentPageWrapper from '@components/SavedContentPageWrapper';
import { ShareListingModal } from '@components/ShareListingModal';
import { Typography } from '@components/Typography';

import './Favorites.css';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import Sort from '@components/Sort';
import { FilterOption } from '@components/Sort/Sort.types';
import { sortNumericValues } from '@components/SearchResults/SearchResults.utils';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';
import { useMediaQuery } from 'react-responsive';

const A_TO_Z = 'aToZ';
const Z_TO_A = 'zToA';
const SQF_I_X = 'SiTox';
const SQF_X_I = 'SxToi';
const filterOptions: FilterOption[] = [
  {
    label: 'Name (A - Z)',
    value: A_TO_Z,
  },
  {
    label: 'Name (Z - A)',
    value: Z_TO_A,
  },

  {
    label: 'Avail Sq Ft (Min - Max)',
    value: SQF_I_X,
  },
  {
    label: 'Avail Sq Ft (Max - Min)',
    value: SQF_X_I,
  },
];

export const Favorites: React.FC = () => {
  const { favorites, isFavoritesLoading, removeFavorites, refetchFavorites } = useUserFavorites();
  const [sortedFavorites, setSortedFavorites] = useState<Favorite[]>([]);
  const [selectedFilter, setSelectedFilter] = useState<FilterOption | undefined>(filterOptions[0]);
  const [contextMenuFavorite, setContextMenuFavorite] = useState<Favorite | null>(null);
  const [selectedFavorites, setSelectedFavorites] = useState<Favorite[]>([]);
  const [unavailableListings, setUnavailableListings] = useState<Favorite[]>([]);
  const [showCollectionsModal, setShowCollectionsModal] = useState(false);
  const [showGenerateAvailabilityReportModal, setShowGenerateAvailabilityReportModal] =
    useState(false);
  const [showRemoveFavoritesModal, setShowRemoveFavoritesModal] = useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showUnavailableListingsModal, setShowUnavailableListingsModal] = useState(false);
  const isBigScreen = useIsBigScreen();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 499px)' });
  const menuItemClassNames = 'flex items-center space-x-2 py-2 cursor-pointer hover:text-rust-100';

  const { getFlyer } = useApiClient();
  const { addToast } = useToast();

  const handleDownloadFlyer = async (favorite: Favorite) => {
    const data = await getFlyer(favorite.listingId);

    const link = document.createElement('a');

    link.href = URL.createObjectURL(data);
    link.download = `flyer-${favorite.listingId}-${new Date().getTime()}.pdf`;
    link.dispatchEvent(new MouseEvent('click'));

    addToast({
      id: `download-flyer-${new Date().getTime()}`,
      description: `Listing Flyer Downloaded`,
      title: 'Success',
      type: 'success',
    });
  };

  const getSelectedFavoritesIds = (selectedFavorites: Favorite[]) =>
    selectedFavorites.map((favorite) => favorite.favoriteId);

  const handleConfirmRemoveUnavailableListings = async () => {
    await removeFavorites(unavailableListings.map((x) => x.listingId));
    setShowUnavailableListingsModal(false);
  };

  useEffect(() => {
    (async function () {
      await refetchFavorites();
    })();
  }, []);

  useEffect(() => {
    const notAvailable = favorites.filter((favorite) => !favorite.isVisible);
    setUnavailableListings(notAvailable);
  }, [favorites]);

  useEffect(() => {
    const FilterValue = selectedFilter?.value;
    let result = [...favorites]; // Create a shallow copy of the original listings array
    switch (FilterValue) {
      case A_TO_Z:
        result.sort((a, b) => a.name.localeCompare(b.name));
        break;
      case Z_TO_A:
        result.sort((a, b) => b.name.localeCompare(a.name));
        break;
      case SQF_I_X:
        result.sort((a, b) => sortNumericValues(a?.sortableSize, b?.sortableSize));
        break;
      case SQF_X_I:
        result.sort((a, b) => sortNumericValues(b?.sortableSize, a?.sortableSize));
        break;
      case 'Default':
        result;
        break;
      default:
        break;
    }
    setSortedFavorites(result);
  }, [selectedFilter, favorites]);

  return (
    <>
      <SavedContentPageWrapper isLoading={isFavoritesLoading}>
        <div
          className={cn([
            'flex flex-col justify-between h-[3rem] mb-3',
            unavailableListings.length > 0 ? 'sm:h-[5.625rem]' : 'sm:h-[3.625rem]',
          ])}>
          <div className="flex items-center justify-between h-[3.125rem]">
            <Typography className="my-[0.3625rem] sm:my-[0.5625rem] title" variant="title">
              {isBigScreen ? 'My Favorited Listings' : `My Favorites (${favorites.length})`}
            </Typography>
            {!isBigScreen && favorites.length > 0 && (
              <Sort
                name="sort favorites"
                filterOptions={filterOptions}
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                customButtonLabel="Sort"
                showCustomButtonLabel={isSmallScreen}
              />
            )}
          </div>

          {unavailableListings.length > 0 && (
            <Button
              buttonClassNames="!text-rust-100 hover:!text-rust-300"
              Icon={<Icon name={IconName.ALERT_TRIANGLE} />}
              label={`Remove (${unavailableListings.length}) Unavailable Listings`}
              onClick={() => setShowUnavailableListingsModal(true)}
              variant={ButtonVariant.NO_FILL}
            />
          )}
        </div>
        {isFavoritesLoading || (favorites.length > 0 && sortedFavorites.length == 0) ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : (
          <FavoritesTable
            data={sortedFavorites}
            onSelectionChange={(favorites) => setSelectedFavorites(favorites)}
            onAddToCollection={() => setShowCollectionsModal(true)}
            onRemove={() => setShowRemoveFavoritesModal(true)}
            onCreateReport={() => setShowGenerateAvailabilityReportModal(true)}
            numberOfSelectedFavorites={selectedFavorites.length}
            moreActionsCell={({ row }) => {
              return (
                <Menu
                  menuClassName="rounded-lg bg-white-400 shadow w-[14.0625rem] px-5 py-2 sm:!top-0 sm:!left-[-13.125rem] md:!top-[-45px] md:!left-[-13.7rem]"
                  menuButton={
                    <MenuButton>
                      <Icon
                        classNames="text-white-100 md:text-cement-500"
                        name={IconName.MORE_VERTICAL}
                      />
                    </MenuButton>
                  }>
                  {row.original.isVisible && (
                    <>
                      <MenuItem
                        className={menuItemClassNames}
                        onClick={() => {
                          handleDownloadFlyer(row.original);
                        }}>
                        <Icon name={IconName.DOWNLOAD} />
                        <Typography variant="button">Download Flyer</Typography>
                      </MenuItem>
                      <MenuItem
                        className={menuItemClassNames}
                        onClick={() => {
                          setContextMenuFavorite(row.original);
                          setShowCollectionsModal(true);
                        }}>
                        <Icon name={IconName.ADD_FOLDER} />
                        <Typography variant="button">Add to Collection</Typography>
                      </MenuItem>
                      <MenuItem
                        className={menuItemClassNames}
                        onClick={() => {
                          setContextMenuFavorite(row.original);
                          setShowShareModal(true);
                        }}>
                        <Icon name={IconName.EXTERNAL_LINK} />
                        <Typography variant="button">Share Listing</Typography>
                      </MenuItem>
                    </>
                  )}
                  <MenuItem
                    className={menuItemClassNames}
                    onClick={() => {
                      setContextMenuFavorite(row.original);
                      setShowRemoveFavoritesModal(true);
                    }}>
                    <Icon name={IconName.TRASH} />
                    <Typography variant="button">Remove from Favorites</Typography>
                  </MenuItem>
                </Menu>
              );
            }}
          />
        )}
      </SavedContentPageWrapper>

      {showRemoveFavoritesModal && (
        <RemoveFavoritesModal
          show={showRemoveFavoritesModal}
          favorites={contextMenuFavorite ? [contextMenuFavorite] : selectedFavorites}
          onClose={() => {
            setContextMenuFavorite(null);
            setShowRemoveFavoritesModal(false);
          }}
        />
      )}

      {showGenerateAvailabilityReportModal && (
        <GenerateAvailabilityReportModal
          listingGroups={[{ listingIds: selectedFavorites.map((x) => x.listingId) }]}
          onConfirm={() => {
            setShowGenerateAvailabilityReportModal(false);
            setSelectedFavorites([]);
          }}
          onClose={() => {
            setShowGenerateAvailabilityReportModal(false);
            setSelectedFavorites([]);
          }}
        />
      )}

      {showCollectionsModal && (
        <PostFavoritedModals
          addToCollectionFlow
          favoriteListingIds={
            contextMenuFavorite
              ? [contextMenuFavorite.favoriteId]
              : getSelectedFavoritesIds(selectedFavorites)
          }
          onClose={() => {
            setContextMenuFavorite(null);
            setShowCollectionsModal(false);
          }}
        />
      )}

      {showShareModal && contextMenuFavorite && (
        <ShareListingModal
          listingId={contextMenuFavorite.listingId}
          propertyType={contextMenuFavorite.propertyType}
          show={showShareModal}
          onClose={() => {
            setContextMenuFavorite(null);
            setShowShareModal(false);
          }}
        />
      )}

      {showUnavailableListingsModal && (
        <RemoveUnavailableListingsModal
          unavailableListings={unavailableListings}
          onClose={() => setShowUnavailableListingsModal(false)}
          onConfirm={handleConfirmRemoveUnavailableListings}
        />
      )}

      <Footer />
    </>
  );
};

export default Favorites;

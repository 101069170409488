import { ButtonVariant } from '@components/Button';

export const enum BtnsDirection {
  COL = 'COL',
  ROW = 'ROW',
}

type ModalWithSecondaryBtn = {
  secondaryBtnLabel: string;
  secondaryBtnDisabled?: boolean;
  secondaryBtnOnClick: () => void;
  secondaryBtnIcon?: JSX.Element;
  secondaryBtnVariant?: ButtonVariant;
  hideSecondaryButton?: boolean;
};

type ModalWithoutSecondaryBtn = {
  secondaryBtnLabel?: never;
  secondaryBtnOnClick?: never;
  secondaryBtnIcon?: never;
  secondaryBtnVariant?: never;
  hideSecondaryButton?: never;
  secondaryBtnDisabled?: boolean;
};

type ConfirmationModalDefaultProps = {
  btnsDirection?: BtnsDirection;
  children?: React.ReactNode;
  classNames?: string;
  header: string;
  description?: string;
  descriptionNote?: string;
  onClose: () => void;
  primaryBtnDisabled?: boolean;
  primaryBtnIcon?: JSX.Element;
  primaryBtnLabel: string;
  primaryBtnOnClick: () => void;
  primaryBtnVariant?: ButtonVariant;
  hidePrimaryButton?: boolean;
  show: boolean;
  subText?: JSX.Element | string;
  haveCloseButton?: boolean;
  isMediumScreen?: boolean;
};

export type ConfirmationModalProps = ConfirmationModalDefaultProps &
  (ModalWithSecondaryBtn | ModalWithoutSecondaryBtn);

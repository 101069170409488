import cn from 'classnames';
import React, { Fragment } from 'react';
import { Tooltip } from 'react-tooltip';

import { Icon, IconName } from '@components/Icon';
import { Typography } from '@components/Typography';
import './../Map.css';

export type MapToolbarItem =
  | {
      id: string;
      type: 'simple';
      icon: IconName;
      onClick: () => void;
      isActive?: boolean;
      isDisabled?: boolean;
      show?: boolean;
      disabledTooltipContent?: string;
    }
  | {
      id: string;
      type: 'custom';
      element: JSX.Element;
      show: boolean;
    };

interface MapToolbarProps {
  items: MapToolbarItem[];
}

export const MapToolbar: React.FC<MapToolbarProps> = ({ items }: MapToolbarProps) => {
  return (
    <div className="flex flex-col">
      {items.map((item, index) => {
        if (!item.show) {
          return <></>;
        }
        if (item.type === 'simple') {
          return (
            <div key={item.id} className="first:rounded-tr-md last:rounded-br-md relative">
              <button
                className={cn([
                  'p-3 bg-slate-500 inline-flex justify-center items-center text-cardboard-100 hover:text-slate-500 hover:bg-site-100 ',
                  item.isActive ? '!text-slate-500 bg-site-100' : '',
                  'margin-bottom-all',
                  index === 0 ? 'first-child-border-round' : '',
                  index === items.length - 1 ? 'last-child-border-round' : '',
                ])}
                onClick={item.onClick}>
                <Icon name={item.icon} classNames="w-[1.2rem]" />
              </button>
              {item.isDisabled && (
                <>
                  <div
                    className="absolute top-0 left-0 w-full h-full opacity-50 bg-base-white"
                    data-tooltip-id={`${item.id}-tooltip`}
                    data-tooltip-target="tooltip-default"></div>
                  <Tooltip id={`${item.id}-tooltip`} place="right">
                    <Typography variant="body-4">{item.disabledTooltipContent}</Typography>
                  </Tooltip>
                </>
              )}
            </div>
          );
        } else {
          return <Fragment key={item.id}>{item.element}</Fragment>;
        }
      })}
    </div>
  );
};

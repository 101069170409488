import { useMutation } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { API_LISTINGS_BASE_URL } from '@/config';
import { ListingExcelRow } from '@/types/excel';

export async function getFlyer(axios: AxiosInstance, listingId: string) {
  const response = await axios.post<Blob>(
    `${API_LISTINGS_BASE_URL}/documents/flyers`,
    {
      listingId,
    },
    {
      responseType: 'blob',
    },
  );

  return response.data;
}

export async function getExcelReport(axios: AxiosInstance, listingIds: string[]) {
  const response = await axios.post<Blob>(
    `${API_LISTINGS_BASE_URL}/documents/result-panel-data/excel`,
    {
      listingIds,
    },
    {
      responseType: 'blob',
    },
  );

  return response.data;
}

export async function getDataForExcel(axios: AxiosInstance, listingIds: string[]) {
  const response = await axios.post<ListingExcelRow[]>(
    `${API_LISTINGS_BASE_URL}/documents/result-panel-data/excel-data`,
    {
      listingIds,
    },
  );

  return response.data;
}

type ShareFlyerViaEmailVariables = {
  recipient: string[];
  listingId: string;
  attachFlyer: boolean;
  senderFirstName: string;
  senderLastName: string;
  toEmail: string;
};

export function shareFlyerViaEmail(axios: AxiosInstance, propertyType: string) {
  return useMutation({
    mutationFn: async ({
      recipient,
      listingId,
      attachFlyer,
      senderFirstName,
      senderLastName,
      toEmail,
    }: ShareFlyerViaEmailVariables) => {
      const response = await axios.post<boolean>(
        `${API_LISTINGS_BASE_URL}/documents/flyers/emails?propertyType=${propertyType}`,
        {
          recipient,
          listingId,
          attachFlyer,
          senderFirstName,
          senderLastName,
          toEmail,
        },
      );

      return response.data;
    },
  });
}

export async function getAvailabilityReport(
  axios: AxiosInstance,
  listingIds: string[],
  title?: string,
) {
  const response = await axios.post<Blob>(
    `${API_LISTINGS_BASE_URL}/documents/availability-reports`,
    {
      listingIds,
      title,
    },
    {
      responseType: 'blob',
    },
  );

  return response.data;
}

type ShareAvailabilityReportViaEmailVariables = {
  recipient: string[];
  listingIds: string[];
  title?: string;
  senderEmail: string;
  senderFirstName: string;
  senderLastName: string;
};

export function shareAvailabilityReportViaEmail(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({
      recipient,
      listingIds,
      title,
      senderEmail,
      senderFirstName,
      senderLastName,
    }: ShareAvailabilityReportViaEmailVariables) => {
      const response = await axios.post<boolean>(
        `${API_LISTINGS_BASE_URL}/documents/availability-reports/emails`,
        {
          recipient,
          listingIds,
          title,
          senderEmail,
          senderFirstName,
          senderLastName,
        },
      );

      return response.data;
    },
  });
}

import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { Checkbox, CheckboxState } from '@components/Inputs';
import { Typography } from '@components/Typography';
import { Icon, IconName } from '@components/Icon';
import { FavoriteCollectionModalCardProps } from './PostFavoritedModals.types';
import { ConstrainedTypography } from '@components/ConstrainedTypography';

const FavoriteCollectionModalCard: React.FC<FavoriteCollectionModalCardProps> = ({
  classNames,
  favoriteCollectionName,
  favoriteCollectionId,
  listingCount,
  thumbnailImageUrl,
  isChecked,
  isDisabled,
  onChecked,
}) => {
  const [checkedState, setCheckedState] = useState(isChecked);
  const handleUpdatingCollection = (checkedState: CheckboxState) => {
    setCheckedState(checkedState);
    onChecked(favoriteCollectionId, checkedState);
  };

  useEffect(() => {
    setCheckedState(isChecked);
    onChecked(favoriteCollectionId, isChecked);
  }, [isChecked]);

  return (
    <div
      className={cn([
        'flex h-20 justify-between rounded-lg border-cement-300 mr-1',
        checkedState === CheckboxState.CHECKED
          ? 'border-2 py-[0.4375rem] pl-[0.4375rem] pr-[0.9375rem]'
          : 'border py-2 pl-2 pr-4',
        classNames,
      ])}>
      <div className="flex space-x-4">
        {thumbnailImageUrl ? (
          <div
            className="bg-center bg-cover w-[4rem] h-full"
            style={{ backgroundImage: `url(${thumbnailImageUrl})` }}
          />
        ) : (
          <div className="flex items-center justify-center bg-cement-100 w-[4rem] h-full">
            <Icon classNames="text-cement-300 !w-full" name={IconName.FACILITY} />
          </div>
        )}

        <div className="flex flex-col items-start justify-center h-full text-left">
          <ConstrainedTypography
            variant="subtitle-1"
            width="w-[11.75rem] sm:w-[9.75rem] md:w-[15rem]">
            {favoriteCollectionName}
          </ConstrainedTypography>
          <Typography variant="body-4">{listingCount} Listings</Typography>
        </div>
      </div>

      <Checkbox
        checkedState={checkedState}
        disableIndeterminateOption
        disabled={isDisabled}
        onClick={handleUpdatingCollection}
      />
    </div>
  );
};

export default FavoriteCollectionModalCard;

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  Header,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import TableSearch from '@components/Inputs/TableSearch';

import { Favorite } from '@/types';
import Button, { ButtonVariant } from '@components/Button';
import { ConstrainedTypography } from '@components/ConstrainedTypography';
import { Icon, IconName } from '@components/Icon';
import { Checkbox, CheckboxState } from '@components/Inputs';
import Thumbnail from '@components/Thumbnail/Thumbnail';
import { Typography } from '@components/Typography';
import { FavoritesTableProps } from './FavoritesTable.types';

import './FavoritesTable.css';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import useDynamicMediaQuery from '@/hooks/useDynamicMediaQuery';
import useScrollbarInfo from '@/hooks/useScrollbarInfo';
import { isIndustrialOrPark } from '@/utilities/constants';

const FavoritesTable: React.FC<FavoritesTableProps> = ({
  data,
  onSelectionChange,
  moreActionsCell,
  onAddToCollection,
  onRemove,
  onCreateReport,
  numberOfSelectedFavorites,
}) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([{ id: 'name', desc: false }]);
  const [searchValue, setSearchValue] = useState<string>('');
  const isBigScreen = useIsBigScreen();
  const navigate = useNavigate();
  const columnHelper = createColumnHelper<Favorite>();
  const isHorizontalScrollEnabled = useDynamicMediaQuery(
    '(min-width: 768px) and (max-width: 886px)',
  );
  const scrollInfo = useScrollbarInfo('listingsTableId', isHorizontalScrollEnabled);

  const getBuildStatusFromListingType = (listingType: string) => {
    switch (listingType) {
      case 'Build to Suit Opportunities':
        return 'build to suit opportunity';
      case 'New Class A':
        return 'new class a development';
      case 'Under Construction':
        return 'under construction';
      default:
        return;
    }
  };

  const renderName = (text: string): JSX.Element => {
    if (!searchValue) {
      return <>{text}</>;
    }

    const regex = new RegExp(`(${searchValue})`, 'gi');
    const matches = text.match(regex);

    if (!matches) {
      return <>{text}</>;
    }

    const parts = text.split(regex);

    return (
      <>
        {parts.map((part, index) =>
          matches.includes(part) ? (
            <span key={`${part}-${index}`} className="bg-cardboard-100">
              {part}
            </span>
          ) : (
            <span key={`${part}-${index}`}>{part}</span>
          ),
        )}
      </>
    );
  };

  const pixelScalingFactor =
    parseFloat(window.getComputedStyle(document.documentElement).fontSize) / 16.0;

  const columns = useMemo(() => {
    return [
      columnHelper.display({
        id: 'gutter-left',
        size: 24 * pixelScalingFactor,
      }),
      columnHelper.display({
        id: 'select',
        enableSorting: false,
        header: ({ table }) => (
          <Checkbox
            disabled={data.length === 0}
            checkedState={
              table.getIsAllRowsSelected()
                ? CheckboxState.CHECKED
                : table.getIsSomeRowsSelected()
                ? CheckboxState.INDETERMINATE
                : CheckboxState.EMPTY
            }
            onClick={() => {
              table.toggleAllRowsSelected(
                table.getIsSomeRowsSelected() || table.getIsAllRowsSelected() ? false : true,
              );
            }}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checkedState={row.getIsSelected() ? CheckboxState.CHECKED : CheckboxState.EMPTY}
            disabled={!row.getCanSelect()}
            onClick={(checkedState: CheckboxState) => {
              row.toggleSelected(
                [CheckboxState.CHECKED, CheckboxState.INDETERMINATE].includes(checkedState),
              );
            }}
          />
        ),
      }),
      columnHelper.accessor(
        (row) => ({
          thumbnailImageUrl: row.thumbnailImageUrl,
          name: row.name,
          addressLine1: row.addressLine1,
          addressLine2: row.addressLine2,
          isVisible: row.isVisible,
        }),
        {
          id: 'name',
          enableSorting: !!isBigScreen,
          size: 100 * pixelScalingFactor,
          sortingFn: (rowA, rowB) =>
            rowA.original.name
              .toLocaleLowerCase()
              .localeCompare(rowB.original.name.toLocaleLowerCase()),
          header: () => (
            <Typography variant="label-2" component="span">
              Name
            </Typography>
          ),
          cell: ({ row, getValue }) => {
            const { thumbnailImageUrl, name, addressLine1, addressLine2, isVisible } = getValue();
            const dateAvailable = row.original.dateAvailable;
            const propertyType = row.original.propertyType;
            let formattedValue = '';
            const buildStatus = getBuildStatusFromListingType(row.original.listingType);
            if (isVisible) {
              switch (dateAvailable) {
                case 'CONTACT_US':
                  formattedValue = 'Contact us';
                  break;
                case 'Now':
                case '--':
                  formattedValue = 'Available Now';
                  break;
                default:
                  formattedValue = dateAvailable;
                  break;
              }
              formattedValue =
                formattedValue !== 'Contact us' && formattedValue !== 'Available Now'
                  ? isIndustrialOrPark(propertyType)
                    ? 'Potentially Available ' + dateAvailable
                    : 'Available ' + dateAvailable
                  : formattedValue;
            } else {
              formattedValue = 'UNAVAILABLE';
            }
            return (
              <div
                className={cn(
                  'flex md:items-center name-item-container',
                  row.original.isVisible ? undefined : 'opacity-25',
                  isVisible ? 'cursor-pointer' : 'pointer-events-none',
                )}
                onClick={() => {
                  navigate('/listing/' + row.original.listingId);
                }}>
                <div className="overflow-hidden rounded-t w-14">
                  <Thumbnail
                    imgClassNames="rounded w-14 h-14"
                    showText={false}
                    propertyImageUrl={thumbnailImageUrl}>
                    <Typography
                      variant="body-3"
                      className="absolute z-[15] md:!z-[70] lg:z-[10] py-[0.1875rem] mr-2 rounded-sm px-[0.5rem] bg-white-100 capitalize text-base top-[0.4rem] left-[2.5rem] md:invisible">
                      {formattedValue}
                    </Typography>
                    {buildStatus ? (
                      <div className="building-status md:!z-[70] lg:z-[10] md:invisible">
                        <Typography variant="label-2">{buildStatus}</Typography>
                      </div>
                    ) : (
                      <></>
                    )}
                  </Thumbnail>
                </div>
                <div className="flex flex-col ml-4 text-container custom-width-tablet">
                  <ConstrainedTypography
                    data-tooltip-id="property-name-tooltip-id"
                    data-tooltip-content={renderName(name)}
                    data-tooltip-target="property-name-tooltip-id"
                    className={cn([
                      'mb-1 mobile-tooltip sm:max-w-[75%] md:max-w-[100%]',
                      isVisible ? 'underline' : '',
                    ])}
                    variant="subtitle-2">
                    {renderName(name)}
                  </ConstrainedTypography>
                  <ConstrainedTypography
                    className="text-cement-500 whitespace-normal"
                    variant="body-4">
                    {addressLine1}
                  </ConstrainedTypography>
                  <ConstrainedTypography
                    className="text-cement-500 whitespace-normal"
                    variant="body-4">
                    {addressLine2}
                  </ConstrainedTypography>
                </div>
              </div>
            );
          },
        },
      ),
      columnHelper.accessor('listingType', {
        enableSorting: !!isBigScreen,
        sortingFn: (rowA, rowB) =>
          rowA.original.listingType
            .toLocaleLowerCase()
            .localeCompare(rowB.original.listingType.toLocaleLowerCase()),
        header: () => (
          <Typography variant="label-2" component="span">
            Listing Type
          </Typography>
        ),
        cell: ({ row, getValue }) => {
          return <div className="hidden md:block">{getValue()}</div>;
        },
      }),
      columnHelper.accessor('dateAvailable', {
        enableSorting: !!isBigScreen,
        sortingFn: (rowA, rowB) => {
          const dateAvailableA = rowA.original.sortableDateAvailable;
          const dateAvailableB = rowB.original.sortableDateAvailable;

          if (dateAvailableA === null && dateAvailableB === null) {
            return 0;
          } else if (dateAvailableA === null) {
            return 1;
          } else if (dateAvailableB === null) {
            return -1;
          }

          return new Date(dateAvailableA).getTime() - new Date(dateAvailableB).getTime();
        },
        size: 130 * pixelScalingFactor,
        header: () => (
          <Typography variant="label-2" component="span">
            Available Date
          </Typography>
        ),
        cell: ({ row, getValue }) => {
          const url = !isBigScreen ? '/listing/' + row.original.listingId : '#';
          const dateAvailable = getValue();
          const isVisible = row.original.isVisible;
          const propertyType = row.original.propertyType;
          let formattedValue = '';
          if (isVisible) {
            switch (dateAvailable) {
              case 'CONTACT_US':
                formattedValue = 'Contact us';
                break;
              case 'Now':
              case '--':
                formattedValue = 'Available Now';
                break;
              default:
                formattedValue = dateAvailable;
                break;
            }
            formattedValue =
              formattedValue !== 'Contact us' && formattedValue !== 'Available Now'
                ? isIndustrialOrPark(propertyType)
                  ? 'Potentially Available ' + dateAvailable
                  : 'Available ' + dateAvailable
                : formattedValue;
          } else {
            formattedValue = 'UNAVAILABLE';
          }
          return (
            isBigScreen && (
              <Link to={url} className={!isBigScreen ? undefined : 'pointer-events-none'}>
                <Typography
                  className={cn(
                    row.original.isVisible ? undefined : 'text-rust-100 !font-semibold',
                    'available-item',
                  )}
                  variant="body-2">
                  {formattedValue}
                </Typography>
              </Link>
            )
          );
        },
      }),
      columnHelper.accessor('size', {
        enableSorting: !!isBigScreen,
        sortingFn: (rowA, rowB) => {
          const sizeA = rowA.original.sortableSize;
          const sizeB = rowB.original.sortableSize;

          if (sizeA === null && sizeB === null) {
            return 0;
          } else if (sizeA === null) {
            return 1;
          } else if (sizeB === null) {
            return -1;
          }

          return sizeA - sizeB;
        },
        header: () => (
          <Typography className="text-center" variant="label-2" component="span">
            Size
          </Typography>
        ),
        cell: ({ row, getValue }) => {
          const size = getValue();
          const url = !isBigScreen ? '/listing/' + row.original.listingId : '#';
          return (
            <Link to={url} className={!isBigScreen ? undefined : 'pointer-events-none'}>
              <Typography
                className={cn(row.original.isVisible ? undefined : 'opacity-25', 'size-value')}
                variant="body-2">
                {size.replace('Sq. Ft.', '').replace('Acres', '')}
                <Typography variant="body-2" component="span" className="additional ml-[0.15rem]">
                  {size.includes('Acres') ? 'Acres' : 'Sq Ft'}
                </Typography>
              </Typography>
            </Link>
          );
        },
      }),
      columnHelper.accessor('clearHeight', {
        enableSorting: !!isBigScreen,
        sortingFn: (rowA, rowB) => {
          const clearHeightA = rowA.original.sortableClearHeight;
          const clearHeightB = rowB.original.sortableClearHeight;

          if (clearHeightA === null && clearHeightB === null) {
            return 0;
          } else if (clearHeightA === null) {
            return 1;
          } else if (clearHeightB === null) {
            return -1;
          }

          return clearHeightA - clearHeightB;
        },
        size: 100 * pixelScalingFactor,
        header: () => (
          <Typography className="text-center clear-height-value" variant="label-2" component="span">
            Clear Height
          </Typography>
        ),
        cell: ({ row, getValue }) => {
          const clearHeight = getValue();
          const url = !isBigScreen ? '/listing/' + row.original.listingId : '#';
          return (
            <Link to={url} className={!isBigScreen ? undefined : 'pointer-events-none'}>
              <Typography
                className={cn([
                  'text-center mr-4',
                  row.original.isVisible ? undefined : 'opacity-25',
                ])}
                variant="body-2">
                {clearHeight}
                <Typography
                  variant="body-2"
                  component="span"
                  className="inline md:hidden additional ml-[0.15rem]">
                  Clear Height
                </Typography>
              </Typography>
            </Link>
          );
        },
      }),
      columnHelper.accessor('loadingDocks', {
        enableSorting: !!isBigScreen,
        sortingFn: (rowA, rowB) => {
          const loadingDocksA = rowA.original.sortableLoadingDocks;
          const loadingDocksB = rowB.original.sortableLoadingDocks;

          if (loadingDocksA === null && loadingDocksB === null) {
            return 0;
          } else if (loadingDocksA === null) {
            return 1;
          } else if (loadingDocksB === null) {
            return -1;
          }

          return loadingDocksA - loadingDocksB;
        },
        size: 100 * pixelScalingFactor,
        header: () => (
          <Typography className="text-center landing-dock-value" variant="label-2" component="span">
            Loading Docks
          </Typography>
        ),
        cell: ({ row, getValue }) => {
          const loadingDocks = getValue();
          const url = !isBigScreen ? '/listing/' + row.original.listingId : '#';
          return (
            <Link to={url} className={!isBigScreen ? undefined : 'pointer-events-none'}>
              <Typography
                className={cn([
                  'text-center mr-4',
                  row.original.isVisible ? undefined : 'opacity-25',
                ])}
                variant="body-2">
                {loadingDocks}
                <Typography
                  variant="body-2"
                  component="span"
                  className="inline md:hidden additional ml-[0.15rem]">
                  Loading Dock(s)
                </Typography>
              </Typography>
            </Link>
          );
        },
      }),
      columnHelper.accessor('driveInDoors', {
        enableSorting: !!isBigScreen,
        sortingFn: (rowA, rowB) => {
          const driveInDoorsA = rowA.original.sortableDriveInDoors;
          const driveInDoorsB = rowB.original.sortableDriveInDoors;

          if (driveInDoorsA === null && driveInDoorsB === null) {
            return 0;
          } else if (driveInDoorsA === null) {
            return 1;
          } else if (driveInDoorsB === null) {
            return -1;
          }

          return driveInDoorsA - driveInDoorsB;
        },
        size: 100 * pixelScalingFactor,
        header: () => (
          <Typography
            className="text-center drive-in-door-value"
            variant="label-2"
            component="span">
            Drive-In Doors
          </Typography>
        ),
        cell: ({ row, getValue }) => {
          const driveInDoors = getValue();
          const url = !isBigScreen ? '/listing/' + row.original.listingId : '#';
          return (
            <Link to={url} className={!isBigScreen ? undefined : 'pointer-events-none'}>
              <Typography
                className={cn([
                  'text-center mr-4',
                  row.original.isVisible ? undefined : 'opacity-25',
                ])}
                variant="body-2">
                {driveInDoors}
                <Typography
                  variant="body-2"
                  component="span"
                  className="inline md:hidden additional ml-[0.15rem]">
                  Drive in Door(s)
                </Typography>
              </Typography>
            </Link>
          );
        },
      }),
      columnHelper.display({
        id: 'more-actions',
        header: '',
        enableSorting: false,
        cell: moreActionsCell,
      }),
      columnHelper.display({
        id: 'gutter-right',
        size: 24 * pixelScalingFactor,
      }),
    ];
  }, [columnHelper, data.length]);

  useEffect(() => {
    setRowSelection({});
  }, [data.length]);
  useEffect(() => {
    if (numberOfSelectedFavorites === 0) {
      setRowSelection({});
    }
  }, [numberOfSelectedFavorites]);

  useEffect(() => {
    if (!onSelectionChange) {
      return;
    }

    const selection = table.getSelectedRowModel().rows.map((x) => x.original);
    onSelectionChange(selection);
  }, [rowSelection]);

  const tableData = useMemo(() => {
    return data.filter((item: Favorite) =>
      item.name.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }, [data, searchValue]);

  const table = useReactTable({
    data: tableData,
    columns,
    state: {
      rowSelection,
      sorting,
    },
    enableRowSelection: (row) => row.original.isVisible,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
  });

  const getSortIcon = (header: Header<Favorite, unknown>) => {
    if (!header.column.getCanSort()) {
      return null;
    }

    let iconName: IconName;

    if (header.column.getIsSorted() === 'asc') {
      iconName = IconName.SORT_ASCENDING;
    } else if (header.column.getIsSorted() === 'desc') {
      iconName = IconName.SORT_DESCENDING;
    } else {
      iconName = IconName.SORT_NONE;
    }

    return <Icon name={iconName} />;
  };

  const renderDesktopActions = () => (
    <div
      className={
        scrollInfo.hs && !scrollInfo.vs
          ? 'flex w-auto space-x-4 absolute right-[2rem]'
          : 'flex w-auto space-x-4'
      }>
      <Button
        buttonClassNames="!py-0"
        data-tooltip-id="add-to-collection"
        data-tooltip-content="Add to Collection"
        data-tooltip-target="tooltip-default"
        disabled={numberOfSelectedFavorites < 1}
        Icon={<Icon name={IconName.ADD_FOLDER} />}
        onClick={onAddToCollection}
        variant={ButtonVariant.NO_FILL_WHITE}
      />
      <Tooltip className="!bg-slate-400 !opacity-100" id="add-to-collection" place="bottom" />

      <Button
        buttonClassNames="!py-0"
        data-tooltip-id="create-report"
        data-tooltip-content="Create Report"
        data-tooltip-target="tooltip-default"
        disabled={numberOfSelectedFavorites === 0}
        Icon={isBigScreen ? <Icon name={IconName.LEASING_AND_OCCUPANCY} /> : undefined}
        onClick={onCreateReport}
        variant={ButtonVariant.NO_FILL_WHITE}
        label={isBigScreen ? '' : 'Generate Report'}
      />
      <Tooltip className="!bg-slate-400 !opacity-100" id="create-report" place="bottom" />

      <Button
        buttonClassNames="!py-0"
        data-tooltip-id="remove-listings"
        data-tooltip-content="Remove Listings"
        data-tooltip-target="tooltip-default"
        disabled={numberOfSelectedFavorites < 1}
        Icon={<Icon name={IconName.TRASH} />}
        onClick={onRemove}
        variant={ButtonVariant.NO_FILL_WHITE}
      />
      <Tooltip className="!bg-slate-400 !opacity-100" id="remove-listings" place="bottom" />
    </div>
  );

  const renderMobileActions = () => (
    <>
      <Button
        buttonClassNames="!py-0 !w-full"
        data-tooltip-id="add-to-collection"
        data-tooltip-content="Add to Collection"
        data-tooltip-target="tooltip-default"
        disabled={numberOfSelectedFavorites < 1}
        Icon={<Icon name={IconName.ADD_FOLDER} />}
        onClick={onAddToCollection}
        variant={ButtonVariant.NO_FILL_WHITE}
      />
      <Tooltip className="!bg-slate-400 !opacity-100" id="add-to-collection" place="bottom" />

      <Button
        buttonClassNames="!py-0 !w-full"
        data-tooltip-id="remove-listings"
        data-tooltip-content="Remove Listings"
        data-tooltip-target="tooltip-default"
        disabled={numberOfSelectedFavorites < 1}
        Icon={<Icon name={IconName.TRASH} />}
        onClick={onRemove}
        variant={ButtonVariant.NO_FILL_WHITE}
      />
      <Tooltip className="!bg-slate-400 !opacity-100" id="remove-listings" place="bottom" />

      <Button
        buttonClassNames="!py-0"
        data-tooltip-id="create-report"
        data-tooltip-content="Create Report"
        data-tooltip-target="tooltip-default"
        disabled={numberOfSelectedFavorites === 0}
        Icon={isBigScreen ? <Icon name={IconName.LEASING_AND_OCCUPANCY} /> : undefined}
        onClick={onCreateReport}
        variant={ButtonVariant.NO_FILL_WHITE}
        label={isBigScreen ? '' : 'Generate Report'}
      />
      <Tooltip className="!bg-slate-400 !opacity-100" id="create-report" place="bottom" />
    </>
  );

  return (
    <div
      id="listingsTableId"
      className="overflow-x-auto overflow-y-hidden rounded-lg thinScrollbar">
      <table className="w-full overflow-hidden rounded-lg favorites-table md:shadow">
        <caption className="px-6 py-2 text-left bg-solar-300">
          <div className="flex items-center justify-between w-full min-h-[3.5rem]">
            <div className={scrollInfo.hs && !scrollInfo.vs ? 'absolute left-[2rem]' : ''}>
              <TableSearch
                placeholder="Search by Listing Name"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onClear={() => setSearchValue('')}
              />
            </div>

            {renderDesktopActions()}
          </div>
        </caption>

        <thead className="bg-cement-100">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  style={{
                    width: header.getSize() !== 150 ? header.getSize() : undefined,
                  }}
                  className="py-4 pr-4 text-left text-slate-300 hover:text-base-black"
                  key={header.id}
                  colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    <div
                      className={cn(
                        'flex items-center',
                        header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                      )}
                      onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {getSortIcon(header)}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-base-white">
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="table-item sm:shadow md:shadow-none">
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      className={cn(
                        'py-4 pr-4 overflow-hidden sm:text-left',
                        `cell-${cell.column.id}`,
                      )}
                      key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  );
                })}
              </tr>
            ))
          ) : (
            <tr className="text-center">
              <td className="px-6 py-8" colSpan={columns.length}>
                <Typography variant="body-2">There are no saved listings.</Typography>
              </td>
            </tr>
          )}
        </tbody>

        {!isBigScreen && numberOfSelectedFavorites > 0 ? (
          <div className="mobile-actions space-x-4 !p-6">{renderMobileActions()}</div>
        ) : null}
      </table>
    </div>
  );
};

export default FavoritesTable;

import useDynamicMediaQuery from '@/hooks/useDynamicMediaQuery';
import { useMapActions } from '@/pages/Map/hooks/useMapActions';
import { useMapData } from '@/pages/Map/hooks/useMapData';
import { ActiveFilterQueryParam, ActiveFilterQueryParam as QueryParam } from '@/types';
import { formatNumberWithCommas } from '@/utilities/textHelpers';
import { ButtonVariant } from '@components/Button';
import Button from '@components/Button/Button';
import { Icon, IconName } from '@components/Icon';
import {
  DatePicker,
  DatePickerMode,
  DatePickerSelectedDate,
  DatePickerTimeFrames,
  MinMaxSelector,
  ToggleSwitch,
} from '@components/Inputs';
import { setMinMaxOptionRange } from '@components/Inputs/MinMaxSelector/MinMaxSelector.utils';
import { LinkVariant } from '@components/Link';
import Link from '@components/Link/Link';
import OverflowHiddenWrapper from '@components/OverflowHiddenWrapper';
import { Typography } from '@components/Typography/Typography';
import cn from 'classnames';
import { getUnixTime } from 'date-fns';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import ClearHeightFilter from './ClearHeightFilter';
import ListingTypes from './ListingTypes';
import { MarketFilter } from './MarketFilter';
import './MoreFiltersModal.css';
import { MoreFiltersModalProps } from './MoreFiltersModal.types';
import { SubmarketFilter } from './SubmarketFilter';

const dockDoorsMockData = ['25', '50', '75', '100', '150', '200'];
const availableSqFtMockData = [
  '25000',
  '50000',
  '100000',
  '300000',
  '500000',
  '750000',
  '1000000',
  '1500000',
];
const MoreFiltersModal: React.FC<MoreFiltersModalProps> = ({ classNames, setIsOpen }) => {
  const {
    clearAllFilters,
    clearFilters,
    esfr,
    listings,
    loadingDocksRange,
    outdoorStorage,
    railServed,
    trailerParking,
    dateAvailable,
    dateTimeFrame,
    unitSquareFootageAvailableRange,
    updateSearchParameters,
    isLoading,
    resetDatePicker,
    updateDateAvailable,
    updateDateTimeFrame,
  } = useMapData();
  const { reset } = useMapActions();

  const handleDatePickerOnSelect = (selection: DatePickerSelectedDate, mode: DatePickerMode) => {
    if (!selection) {
      updateSearchParameters([
        [ActiveFilterQueryParam.DATE_TIMEFRAME, ''],
        [ActiveFilterQueryParam.DATE_AVAILABLE, ''],
      ]);
      resetDatePicker();
    } else {
      switch (mode) {
        case DatePickerMode.TIMEFRAME:
          updateSearchParameters([
            [ActiveFilterQueryParam.DATE_TIMEFRAME, (selection as DatePickerTimeFrames) ?? ''],
            [ActiveFilterQueryParam.DATE_AVAILABLE, ''],
          ]);
          updateDateAvailable(undefined);
          break;
        case DatePickerMode.DATE:
          updateSearchParameters([
            [ActiveFilterQueryParam.DATE_AVAILABLE, getUnixTime(selection as Date)],
            [ActiveFilterQueryParam.DATE_TIMEFRAME, ''],
          ]);
          updateDateTimeFrame(undefined);
          break;
      }
    }
  };

  const dateLabel = 'Date Available';

  const sqFtLabel = 'Square Footage';

  return (
    <div
      className={cn([
        'more-filers-modal fixed top-[7rem] lg:absolute right-0 lg:top-[3.6rem] w-full lg:w-[38.5625rem] z-[72] overflow-hidden',
        classNames,
      ])}>
      <div className="more-filers-modal-header bg-slate-500 justify-center lg:justify-start flex h-[3rem] md:h-[4rem] lg:h-[4.2rem] items-center px-8 lg:rounded-t-lg">
        <Typography className="text-white-100 !font-semibold lg:font-normal" variant="h6">
          {useDynamicMediaQuery('(max-width: 1024px)') ? 'Filters' : 'More Filters'}
        </Typography>
        <button className="primary-modal-close-btn white" onClick={() => setIsOpen(false)}>
          <Icon name={IconName.CLOSE} />
        </button>
      </div>

      <OverflowHiddenWrapper classNames="bg-slate-400 [&_.overflow-hidden-inner-wrapper]:px-8 [&_.overflow-hidden-inner-wrapper]:py-4 ">
        {useMediaQuery({ query: '(max-width: 1024px)' }) && (
          <>
            <Typography className="mb-4 text-white-100" variant="subtitle-1">
              {dateLabel}
            </Typography>
            <DatePicker
              classNames="more-date-input"
              disablePastDays
              onSelect={handleDatePickerOnSelect}
              placeholder={dateLabel}
              selectedDate={dateAvailable}
              selectedTimeFrame={dateTimeFrame}
            />
          </>
        )}
        {useMediaQuery({ query: '(max-width: 1325px)' }) && (
          <MinMaxSelector
            classNames="[&_.range-divider]:border-white-100 mb-4 lg:mb-6"
            clearFilters={clearFilters}
            data={availableSqFtMockData}
            label={sqFtLabel}
            onSelect={setMinMaxOptionRange({
              queryParameter: 'available_sq_ft',
              updateSearchParameters,
            })}
            range={unitSquareFootageAvailableRange}
            maxWidth="responsive-sqft-modal-w-more-filters md:max-w-[16.5rem] lg:max-w-[11.8rem]"
          />
        )}

        <MarketFilter className="mb-4 lg:mb-6" />

        <SubmarketFilter className="mb-4 lg:mb-6" />

        <ListingTypes />

        <MinMaxSelector
          classNames="[&_.range-divider]:border-white-100 mb-4 lg:mb-6"
          clearFilters={clearFilters}
          data={dockDoorsMockData}
          label="Loading Docks"
          onSelect={setMinMaxOptionRange({
            queryParameter: 'dock_doors',
            updateSearchParameters,
          })}
          range={loadingDocksRange}
          maxWidth="responsive-sqft-modal-w-more-filters md:max-w-[16.5rem] lg:max-w-[11.8rem]"
        />

        <ClearHeightFilter />

        <ToggleSwitch
          classNames="mb-4 lg:mb-6 justify-between"
          leftLabel={
            <Typography className="text-white-100" variant="subtitle-1">
              Trailer Parking Required
            </Typography>
          }
          onClick={(toggled) => updateSearchParameters([[QueryParam.TRAILER_PARKING, toggled]])}
          toggled={trailerParking}
        />

        <ToggleSwitch
          classNames="mb-4 lg:mb-6 justify-between"
          leftLabel={
            <Typography className="text-white-100" variant="subtitle-1">
              Rail Service Required
            </Typography>
          }
          onClick={(toggled) => updateSearchParameters([[QueryParam.RAIL_SERVED, toggled]])}
          toggled={railServed}
        />

        <ToggleSwitch
          classNames="mb-4 lg:mb-6 justify-between"
          leftLabel={
            <Typography className="text-white-100" variant="subtitle-1">
              Outdoor Storage Allowed
            </Typography>
          }
          onClick={(toggled) => updateSearchParameters([[QueryParam.OUTDOOR_STORAGE, toggled]])}
          toggled={outdoorStorage}
        />

        <ToggleSwitch
          classNames="mb-4 lg:mb-6 justify-between sm:pb-24 md:pb-24"
          leftLabel={
            <Typography className="text-white-100" variant="subtitle-1">
              ESFR Required
            </Typography>
          }
          onClick={(toggled) => updateSearchParameters([[QueryParam.ESFR, toggled]])}
          toggled={esfr}
        />
      </OverflowHiddenWrapper>

      <div className="fixed z-10 lg:static bottom-0 w-full bg-slate-500 flex h-[4.8rem] items-center justify-between pl-[3.25rem] pr-8 lg:rounded-b-lg">
        <Link
          onClick={() => {
            clearAllFilters();
            reset();
            setIsOpen(false);
          }}
          variant={LinkVariant.DARK_THEME}>
          Clear All Filters
        </Link>
        <Button
          label={
            isLoading
              ? 'Loading...'
              : 'Show ' + formatNumberWithCommas(listings.length) + '  Results'
          }
          onClick={() => setIsOpen(false)}
          variant={ButtonVariant.PRIMARY_TWO}
        />
      </div>
    </div>
  );
};

export default MoreFiltersModal;

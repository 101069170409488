import { CheckboxState } from '../Checkbox/Checkbox.types';
import { DropdownSelectorOnSelectFunc } from '../DropdownSelector/DropdownSelector.types';
import InputValueClearingPill from '../InputValueClearingPill';
import { MultipleLevelCheckboxDropdownSelectorOption } from './MultipleLevelCheckboxDropdown.types';

export const getSelectedChildCount = (option: MultipleLevelCheckboxDropdownSelectorOption) => {
  const count = option?.childs?.filter(
    (childOption) => childOption.checkedState === CheckboxState.CHECKED,
  ).length;
  return count ?? 0;
};

export const handleOptionState = (
  optionState: CheckboxState,
  option: MultipleLevelCheckboxDropdownSelectorOption,
) => {
  if (optionState === CheckboxState.CHECKED) {
    option.checkedState = CheckboxState.EMPTY;
  } else {
    option.checkedState = CheckboxState.CHECKED;
  }
};

export const updateSelectedLabelsText = (
  options: MultipleLevelCheckboxDropdownSelectorOption[],
  handleClick: (
    checkedState: CheckboxState,
    label?: string,
    value?: string,
    childs?: MultipleLevelCheckboxDropdownSelectorOption[],
  ) => void,
  onSelect: DropdownSelectorOnSelectFunc,
  setSelectedSubmarketPills: (pills: JSX.Element[] | string) => void,
) => {
  let allChildLabels: MultipleLevelCheckboxDropdownSelectorOption[] = [];
  //Checked Parent options but without childs as we dont want parent with childs to be part of pills.
  const filteredCheckedOptions = options.filter(
    (option) =>
      option.checkedState === CheckboxState.CHECKED && getSelectedChildCount(option) === 0,
  );
  //All Parents who has child options.
  const parentWithChildOptions = options.filter((option) => option.childs !== undefined);
  for (let childOption of parentWithChildOptions) {
    //Checked Childs.
    const childLabels = childOption.childs?.filter((option) => option.checkedState === 'CHECKED');
    allChildLabels = [...allChildLabels, ...childLabels!];
  }
  const _filteredCheckedOptions = [...filteredCheckedOptions, ...allChildLabels];
  const selectedOptionPills = _filteredCheckedOptions.map((option) => {
    const optionLabel = option.label;
    const optionValue = option.value as string;
    return (
      <InputValueClearingPill
        key={`${optionLabel}-submarket-pill`}
        classNames="relative w-fit"
        onRemove={() => {
          handleClick(CheckboxState.EMPTY, optionLabel, optionValue);
          onSelect(CheckboxState.EMPTY, optionLabel, optionValue);
        }}
        value={optionLabel}
      />
    );
  });

  setSelectedSubmarketPills(selectedOptionPills);
};

export const handleParentOption = (
  updatedDropdownOptions: MultipleLevelCheckboxDropdownSelectorOption[],
  childs: MultipleLevelCheckboxDropdownSelectorOption[],
  checkedState: CheckboxState,
  value: string | undefined,
) => {
  for (let dropdownOption of updatedDropdownOptions) {
    if (dropdownOption.value === value) {
      let _childs = childs;
      for (let childDropdownOption of _childs) {
        //Marking childrens checked/unchecked.
        handleOptionState(checkedState, childDropdownOption);
      }
      //Marking Parent checked/unchecked.
      handleOptionState(dropdownOption.checkedState!, dropdownOption);
      dropdownOption.childs = _childs;
    }
  }
};
export const handleSeprateChildOptions = (
  _childs: MultipleLevelCheckboxDropdownSelectorOption[],
  dropdownOption: MultipleLevelCheckboxDropdownSelectorOption,
  value: string | undefined,
) => {
  for (let _child of _childs) {
    if (_child.value === value) {
      //Handle child option state.
      handleOptionState(_child.checkedState!, _child);
    }
    //Checked childs.
    const filteredCheckedChilds = _childs.filter(
      (option: MultipleLevelCheckboxDropdownSelectorOption) =>
        option.checkedState === 'CHECKED' && option.label !== 'Select All',
    );
    if (filteredCheckedChilds.length === _childs.length) {
      //All childs to the parent are checked so mark Parent CHECKED.
      dropdownOption.checkedState = CheckboxState.CHECKED;
    } else if (
      filteredCheckedChilds.length < _childs.length &&
      filteredCheckedChilds.length !== 0
    ) {
      //Out of all only few childs are checked to the parent so mark Parent INDETERMINATE checked.
      dropdownOption.checkedState = CheckboxState.INDETERMINATE;
    } else {
      //None of childs are checked to the parent so mark Parent EMPTY.
      dropdownOption.checkedState = CheckboxState.EMPTY;
    }
  }
};

export const handleChildOption = (
  updatedDropdownOptions: MultipleLevelCheckboxDropdownSelectorOption[],
  value: string | undefined,
) => {
  for (let dropdownOption of updatedDropdownOptions) {
    //Childless Parent option clicked.
    if (dropdownOption.value === value) {
      handleOptionState(dropdownOption.checkedState!, dropdownOption);
    } else {
      //Separate child option for any parent is clicked.
      const _childs = dropdownOption.childs;
      if (_childs) {
        handleSeprateChildOptions(_childs, dropdownOption, value);
      }
    }
  }
};

import { BBox, FeatureCollection, MultiPolygon, Polygon, Position } from 'geojson';
import mapboxgl, {
  FillExtrusionLayer,
  FitBoundsOptions,
  LngLatBounds,
  LngLatBoundsLike,
} from 'mapbox-gl';
import React, { useEffect, useMemo, useState } from 'react';
import MapboxMap, {
  Layer,
  LayerProps,
  MapboxEvent,
  Marker,
  Source,
  ViewState,
  ViewStateChangeEvent,
  useMap,
} from 'react-map-gl';
import Supercluster from 'supercluster';

import {
  MAPBOX_ACCESS_TOKEN,
  MAP_3D_ZOOM_THRESHOLD,
  MAP_CLUSTERING_MAX_ZOOM,
  MAP_CLUSTERING_RADIUS,
  MAP_DEFAULT_BOUNDS,
  MAP_STYLES,
} from '@/config';
import { useApiClient } from '@/hooks/useApiClient';
import {
  ActiveFilterQueryParam,
  DistanceFilter,
  ListingAttributes,
  ActiveFilterQueryParam as SearchParameterNames,
} from '@/types';
import { Icon, IconName } from '@components/Icon';
import MapCluster from '@components/MapCluster';
import MapPin from '@components/MapPin';
import MapPinPopup from '@components/MapPinPopup';
import { useGeolocation } from './hooks/useGeolocation';
import { useMapActions } from './hooks/useMapActions';
import { useMapData } from './hooks/useMapData';

import { useToast } from '@/hooks/useToast';
import { SHARE_SEARCH_QUERY_PARAMETER_NAME } from '@/utilities/constants';
import SaveSearchModal from '@components/SaveSearchModal';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useNavigate } from 'react-router-dom';
import * as turf from '@turf/turf';
import { Units } from '@turf/turf';
import './Map.css';
import { majorHighwaysLayerIds } from './utilities/constants';

type Listing = Supercluster.PointFeature<ListingAttributes>;
type Cluster = Supercluster.ClusterFeature<Supercluster.AnyProps>;

export const Map: React.FC = () => {
  const [hoveredListing, setHoveredListing] = useState<Listing | null>(null);
  const [previousBounds, setPreviousBounds] = useState<LngLatBounds | LngLatBoundsLike | null>(
    null,
  );
  const [previousZoom, setPreviousZoom] = useState<number | undefined>(undefined);
  const { addToast } = useToast();
  const { currentLocation } = useGeolocation();
  const navigate = useNavigate();
  const { default: mapRef } = useMap();
  const {
    distanceFilter,
    listings,
    setIsMoving,
    selectedListing,
    searchedLocation,
    setSelectedListing,
    clearAllFilters,
    setShowSearchResults,
    updateSearchParameters,
    marketIds,
    submarketIds,
    selectedOption,
    tempCircle,
    setDistanceFilterTempCircle,
  } = useMapData();
  const { getListingSearchBounds, getMarketBoundaries, getSavedSearchById } = useApiClient();
  const {
    flyTo,
    fitBounds,
    is3dActive,
    setIs3dDisabled,
    deactivate3d,
    showAirports,
    showBusStations,
    showMajorHighways,
    showSubwayStations,
    showTrainStations,
    showPorts,
    secondaryHover,
    setSecondaryHover,
    resultCardHover,
  } = useMapActions();

  // NOTE - Automatically fit bounds to contain Market / Submarket listings.

  const { data: boundsData } = getListingSearchBounds({
    minLatitude: 20,
    maxLatitude: 50,
    minLongitude: -125,
    maxLongitude: -65,
    marketIds,
    submarketIds,
  });

  useEffect(() => {
    if (!mapRef || !boundsData) {
      return;
    }

    fitBounds(boundsData, { padding: 80, maxZoom: MAP_3D_ZOOM_THRESHOLD });
  }, [boundsData]);

  const [circle, setCircle] = useState<turf.Feature<Polygon, {}>>();

  // NOTE - Display Market boundaries.

  const [marketBoundary, setMarketBoundary] = useState<FeatureCollection<Polygon | MultiPolygon>>();
  const [showSaveSearchModal, setShowSaveSearchModal] = useState<boolean>(false);

  const [submarketBoundaries, setSubmarketBoundaries] =
    useState<FeatureCollection<Polygon | MultiPolygon>>();
  const { data: marketBoundaryData } = getMarketBoundaries(marketIds);

  // Check and get sharedSearchId. Workflow for sharing a saved search.
  const searchParameters = new URLSearchParams(location.search);
  const sharedSearchId = searchParameters.has(SHARE_SEARCH_QUERY_PARAMETER_NAME)
    ? searchParameters.get(SHARE_SEARCH_QUERY_PARAMETER_NAME)
    : '';
  const { data: sharedSearch } = getSavedSearchById(sharedSearchId!);

  useEffect(() => {
    const searchId = sharedSearch?.savedSearchId;
    if (searchId) {
      addToast({
        id: searchId,
        description:
          'You can save your applied search criteria at any point for faster searching in the future.',
        title: 'Did You Know?',
        nextAction: {
          type: 'link',
          label: 'Save this search',
          action: () => setShowSaveSearchModal(true),
        },
        type: 'info',
        autoRemoveTime: 10000,
      });
    }
    const searchParameters = new URLSearchParams(location.search);
    if (searchParameters.has(SHARE_SEARCH_QUERY_PARAMETER_NAME)) {
      searchParameters.delete(SHARE_SEARCH_QUERY_PARAMETER_NAME);
    }
    navigate({ search: searchParameters.toString() }, { replace: true });
  }, [sharedSearch]);

  useEffect(() => {
    if (!mapRef) {
      return;
    }

    setMarketBoundary(marketBoundaryData);
  }, [mapRef, marketBoundaryData]);

  // NOTE - Display Submarket boundaries.

  const { data: submarketBoundaryData } = getMarketBoundaries(submarketIds);

  useEffect(() => {
    if (!mapRef) {
      return;
    }

    setSubmarketBoundaries(submarketBoundaryData);
  }, [mapRef, submarketBoundaryData]);

  // NOTE - Airport data layer.

  useEffect(() => {
    if (!mapRef?.isStyleLoaded()) {
      return;
    }

    const map = mapRef.getMap();

    if (!map) {
      return;
    }

    map.setLayoutProperty('airports', 'visibility', showAirports ? 'visible' : 'none');

    map.setPaintProperty('airports', 'icon-opacity', showAirports ? 1 : 0);
    map.setPaintProperty('airports', 'text-opacity', showAirports ? 1 : 0);
  }, [mapRef, showAirports]);

  // NOTE - Bus Stations data layer.

  useEffect(() => {
    if (!mapRef?.isStyleLoaded()) {
      return;
    }

    const map = mapRef.getMap();

    if (!map) {
      return;
    }

    map.setLayoutProperty('bus-stations', 'visibility', showBusStations ? 'visible' : 'none');

    map.setPaintProperty('bus-stations', 'icon-opacity', showBusStations ? 1 : 0);
    map.setPaintProperty('bus-stations', 'text-opacity', showBusStations ? 1 : 0);
  }, [mapRef, showBusStations]);

  // NOTE - Major Highways data layer.

  useEffect(() => {
    if (!mapRef?.isStyleLoaded()) {
      return;
    }

    const map = mapRef.getMap();

    if (!map) {
      return;
    }

    for (const layerId of majorHighwaysLayerIds) {
      const layer = map.getLayer(layerId);

      if (layer.type === 'line') {
        map.setPaintProperty(layerId, 'line-opacity', [
          'interpolate',
          ['linear'],
          ['zoom'],
          3,
          0,
          3.5,
          showMajorHighways ? 1 : 0,
        ]);
      }
    }
  }, [mapRef, showMajorHighways]);

  // NOTE - Subway Stations data layer.

  useEffect(() => {
    if (!mapRef?.isStyleLoaded()) {
      return;
    }

    const map = mapRef.getMap();

    if (!map) {
      return;
    }

    map.setLayoutProperty('subways', 'visibility', showSubwayStations ? 'visible' : 'none');

    map.setPaintProperty('subways', 'icon-opacity', showSubwayStations ? 1 : 0);
    map.setPaintProperty('subways', 'text-opacity', showSubwayStations ? 1 : 0);
  }, [mapRef, showSubwayStations]);

  // NOTE - Train Stations data layer.

  useEffect(() => {
    if (!mapRef?.isStyleLoaded()) {
      return;
    }

    const map = mapRef.getMap();

    if (!map) {
      return;
    }

    map.setLayoutProperty('train-stations', 'visibility', showTrainStations ? 'visible' : 'none');

    map.setPaintProperty('train-stations', 'icon-opacity', showTrainStations ? 1 : 0);
    map.setPaintProperty('train-stations', 'text-opacity', showTrainStations ? 1 : 0);
  }, [mapRef, showTrainStations]);

  // NOTE - Seaports data layer.

  useEffect(() => {
    if (!mapRef?.isStyleLoaded()) {
      return;
    }

    const map = mapRef.getMap();

    if (!map) {
      return;
    }

    map.setLayoutProperty('ports', 'visibility', showPorts ? 'visible' : 'none');

    map.setPaintProperty('ports', 'icon-opacity', showPorts ? 1 : 0);
    map.setPaintProperty('ports', 'text-opacity', showPorts ? 1 : 0);
  }, [mapRef, showPorts]);

  // useEffect(() => {
  //   if (!selectedListing && searchedLocation !== 'Current Location') {
  //     setCircleRadius(null);
  //   }
  // }, [selectedListing, searchedLocation]);

  // NOTE - Clustering.

  const { clusters, supercluster } = useMemo(() => {
    if (!mapRef) {
      return {
        clusters: [],
        supercluster: null,
      };
    }

    // NOTE - We leverage the Supercluster library from Mapbox to handle
    // our geospatial point clustering.
    //
    // cref: https://github.com/mapbox/supercluster

    const supercluster = new Supercluster<ListingAttributes>({
      radius: MAP_CLUSTERING_RADIUS,
      maxZoom: MAP_CLUSTERING_MAX_ZOOM,
    });

    supercluster.load(listings);

    const bbox = mapRef.getBounds().toArray().flat() as BBox;
    const zoom = mapRef.getZoom();

    const clusters = supercluster.getClusters(bbox, zoom);

    return {
      clusters,
      supercluster,
    };
  }, [mapRef, listings]);

  const getInitialViewState = (): Partial<ViewState> & {
    bounds?: LngLatBoundsLike;
    fitBoundsOptions?: FitBoundsOptions;
  } => {
    const searchParameters = new URLSearchParams(location.search);

    if (searchParameters.has(SearchParameterNames.INITIAL_VIEW_STATE)) {
      const searchParameter = searchParameters.get(SearchParameterNames.INITIAL_VIEW_STATE)!;
      const searchParameterParts = searchParameter.split('|');

      const lngLatBoundsLike = searchParameterParts[0].split(',').map(Number);
      const zoom = searchParameterParts[1] ? Number(searchParameterParts[1]) : undefined;

      if (lngLatBoundsLike.length === 2) {
        const viewState: ReturnType<typeof getInitialViewState> = {
          latitude: lngLatBoundsLike[1],
          longitude: lngLatBoundsLike[0],
        };

        if (zoom !== undefined) {
          viewState.zoom = zoom;
        }

        return viewState;
      } else if (lngLatBoundsLike.length === 4) {
        const viewState: ReturnType<typeof getInitialViewState> = {
          bounds: lngLatBoundsLike as LngLatBoundsLike,
          fitBoundsOptions: {
            maxZoom: MAP_3D_ZOOM_THRESHOLD,
          },
        };

        if (zoom !== undefined) {
          viewState.fitBoundsOptions!.zoom = zoom;
        }
        return viewState;
      }
    }

    if (searchParameters.has(SearchParameterNames.VIEWPORT)) {
      const bounds = searchParameters
        .get(SearchParameterNames.VIEWPORT)!
        .split(':')
        .map(Number) as LngLatBoundsLike;

      return {
        bounds,
      };
    }
    return {
      bounds: MAP_DEFAULT_BOUNDS,
    };
  };

  const handleMapClick = () => {
    setSelectedListing(null);
  };

  const handleMapLoad = (e: MapboxEvent) => {
    const map = e.target;
    updateViewport(map);
    handleMapZoomEnd();
  };

  const handleMapMoveStart = () => {
    setIsMoving(true);
  };

  const handleMapMoveEnd = (e: ViewStateChangeEvent) => {
    const map = e.target;
    updateViewport(map);

    setTimeout(() => setIsMoving(false), 10);
  };

  const updateViewport = (map: mapboxgl.Map) => {
    const viewport = map.getBounds().toArray().flat().join(':');
    updateSearchParameters([[SearchParameterNames.VIEWPORT, viewport]]);
  };

  const handleMapZoomEnd = () => {
    const map = mapRef?.getMap();

    if (!map) {
      return;
    }

    const zoom = map.getZoom();

    if (zoom < MAP_3D_ZOOM_THRESHOLD) {
      if (is3dActive) {
        deactivate3d();
      }

      setIs3dDisabled(true);
    } else {
      setIs3dDisabled(false);
    }
  };

  const handleClusterClick = (clusterId: number, [lon, lat]: Position) => {
    const expansionZoom = Math.min(supercluster!.getClusterExpansionZoom(clusterId), 18);

    flyTo({ center: [lon, lat], zoom: expansionZoom });

    setShowSearchResults(true);
  };

  const handlePinClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, listing: Listing) => {
    e.stopPropagation();
    if (selectedListing?.id === listing.id) {
      setSelectedListing(null);
      if (previousBounds) {
        if (!previousZoom) {
          fitBounds(previousBounds, { padding: 80, maxZoom: MAP_3D_ZOOM_THRESHOLD });
        } else {
          flyTo({ zoom: previousZoom });
        }
      } else {
        clearAllFilters();
        fitBounds(MAP_DEFAULT_BOUNDS, { padding: 80 });
      }
    } else {
      setSelectedListing(listing);
      const searchParameters = new URLSearchParams(location.search);

      // Check if there is initial viewstate in the map. Use the bounds and zoom from state in this case
      if (searchParameters.has(SearchParameterNames.INITIAL_VIEW_STATE)) {
        const searchParameter = searchParameters.get(SearchParameterNames.INITIAL_VIEW_STATE)!;
        const searchParameterParts = searchParameter.split('|');
        const lngLatBoundsLike = searchParameterParts[0].split(',').map(Number);
        const zoom = searchParameterParts[1] ? Number(searchParameterParts[1]) : undefined;
        if (lngLatBoundsLike.length === 4) {
          const bounds = lngLatBoundsLike as LngLatBoundsLike;
          setPreviousBounds(bounds);
          setPreviousZoom(zoom);
        } else if (lngLatBoundsLike.length === 2) {
          const latitude = lngLatBoundsLike[1];
          const longitude = lngLatBoundsLike[0];
          const bounds = [longitude, latitude, longitude, latitude] as LngLatBoundsLike;
          setPreviousBounds(bounds);
          setPreviousZoom(zoom);
        } else {
          setPreviousZoom(undefined);
        }
      } else {
        setPreviousBounds(boundsData!);
        setPreviousZoom(mapRef?.getZoom());
      }
      const [lon, lat] = listing.geometry.coordinates;
      flyTo({ center: [lon, lat], zoom: 18, essential: true });
    }
    setShowSearchResults(true);
  };

  const handlePinMouseEnter = (listing: Listing) => {
    setHoveredListing(listing);
    if (!!selectedListing && listing.id !== selectedListing.id) {
      setSecondaryHover(listing);
    }
  };

  const handlePinMouseLeave = () => {
    setHoveredListing(null);
    setSecondaryHover(null);
  };

  const getThreeDBuildingsLayerProps = (): FillExtrusionLayer => {
    const zoom = mapRef?.getZoom() ?? 22;

    return {
      id: '3d-buildings',
      source: 'composite',
      'source-layer': 'building',
      filter: ['==', 'extrude', 'true'],
      type: 'fill-extrusion',
      minzoom: MAP_3D_ZOOM_THRESHOLD,
      layout: {
        visibility: zoom < MAP_3D_ZOOM_THRESHOLD ? 'none' : 'visible',
      },
      paint: {
        'fill-extrusion-color': '#aaa',
        'fill-extrusion-height': [
          'interpolate',
          ['linear'],
          ['zoom'],
          MAP_3D_ZOOM_THRESHOLD,
          0,
          MAP_3D_ZOOM_THRESHOLD + 0.05,
          ['get', 'height'],
        ],
        'fill-extrusion-base': [
          'interpolate',
          ['linear'],
          ['zoom'],
          MAP_3D_ZOOM_THRESHOLD,
          0,
          MAP_3D_ZOOM_THRESHOLD + 0.05,
          ['get', 'min_height'],
        ],
        'fill-extrusion-opacity': 1.0,
      },
    };
  };

  const getMarketFillLayerProps = (): LayerProps => ({
    id: 'market-fill',
    type: 'fill',
    paint: {
      'fill-color': '#333',
      'fill-opacity': 0.1,
    },
  });

  const getCircleFillLayerProps = (): LayerProps => ({
    id: 'circle-fill',
    type: 'fill',
    paint: {
      'fill-color': '#D32F2F',
      'fill-opacity': 0.2,
    },
  });

  const getCircleLineLayerProps = (): LayerProps => ({
    id: 'circle-line',
    type: 'line',
    paint: {
      'line-color': '#D32F2F',
      'line-width': 1.5,
    },
  });

  const getMarketLineLayerProps = (): LayerProps => ({
    id: 'market-line',
    type: 'line',
    paint: {
      'line-color': '#555',
      'line-width': 1,
    },
  });

  const getSubmarketLineLayerProps = (): LayerProps => ({
    id: 'sub-market-line',
    type: 'line',
    paint: {
      'line-color': '#555',
      'line-width': 1,
      'line-dasharray': [2, 2],
    },
  });

  const drawCircle = (filter: DistanceFilter | null) => {
    if (filter && filter.distance > 0 && (selectedOption || currentLocation)) {
      let [lng, lat] = selectedOption?.center ?? [
        currentLocation?.longitude,
        currentLocation?.latitude,
      ];
      if (!lng || !lat) {
        return;
      }
      var options = { steps: 1000, units: 'miles' as Units, properties: {} };
      var circleData = turf.circle([lng, lat], filter?.distance, options);
      setCircle(circleData);
      fitCircleBounds(filter, { lng, lat });
    } else {
      var options = { steps: 1000, units: 'miles' as Units, properties: {} };
      var circleData = turf.circle([-87.627, 41.8466], 0, options);
      setCircle(circleData);
    }
  };

  const fitCircleBounds = (filter: DistanceFilter, center: { lng: number; lat: number }) => {
    if (center && filter?.distance) {
      flyTo({ center });
      var newoptions = { steps: 4, units: 'miles' as Units };
      var newcircleData = turf.circle([center.lng, center.lat], filter?.distance!, newoptions);

      const twoDArr = newcircleData?.geometry.coordinates.flat().slice(1);

      if (twoDArr.length == 4 && twoDArr.every((arr) => arr.length > 1)) {
        const bounds = [twoDArr[0][0], twoDArr[1][1], twoDArr[2][0], twoDArr[3][1]];
        fitBounds(bounds as LngLatBoundsLike, { padding: 20 });
      }
    }
  };

  useEffect(() => {
    // This gets triggerred when user applies a distance filter from a pin.
    if (!selectedOption && searchedLocation !== 'Current Location') {
      updateSearchParameters?.([[ActiveFilterQueryParam.RADIUS, undefined]]);
    }
    // Render circle only if user is NOT drawing the temp circle
    if (!tempCircle) {
      drawCircle(distanceFilter);
    }
  }, [distanceFilter, selectedOption]);

  useEffect(() => {
    // This gets triggered when user is adjusting distance filter
    if (!selectedOption && searchedLocation !== 'Current Location') {
      setDistanceFilterTempCircle(null);
    }
    if (tempCircle) {
      drawCircle(tempCircle);
    }
  }, [tempCircle]);

  return (
    <>
      <MapboxMap
        attributionControl={false}
        initialViewState={getInitialViewState()}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle={MAP_STYLES[0].url}
        onClick={handleMapClick}
        onLoad={handleMapLoad}
        onMoveStart={handleMapMoveStart}
        onMoveEnd={handleMapMoveEnd}
        onZoomEnd={handleMapZoomEnd}>
        {clusters.map((clusterOrListing) => {
          const coordinates = clusterOrListing.geometry.coordinates;
          const [lon, lat] = coordinates;

          const cluster = clusterOrListing as Cluster;

          if (cluster.properties.cluster) {
            const { cluster_id, point_count } = cluster.properties;

            return (
              <Marker key={`cluster-${cluster_id}`} longitude={lon} latitude={lat}>
                <MapCluster
                  count={point_count}
                  onClick={() => handleClusterClick(cluster_id, coordinates)}
                />
              </Marker>
            );
          }

          const listing = clusterOrListing as Listing;

          return (
            <Marker key={`pin-${listing.id}`} longitude={lon} latitude={lat}>
              <MapPin
                isActive={listing.id === selectedListing?.id}
                isHovered={listing.id === hoveredListing?.id || listing.id === resultCardHover?.id}
                isSecondaryHover={listing.id === secondaryHover?.id}
                onClick={(e) => handlePinClick(e, listing)}
                onMouseEnter={() => handlePinMouseEnter(listing)}
                onMouseLeave={() => handlePinMouseLeave()}
              />
            </Marker>
          );
        })}

        {(hoveredListing || secondaryHover || resultCardHover) && (
          <MapPinPopup
            latitude={
              hoveredListing?.geometry.coordinates[1] ??
              secondaryHover?.geometry.coordinates[1] ??
              resultCardHover!.geometry.coordinates[1]
            }
            listingId={(hoveredListing?.id ?? secondaryHover?.id ?? resultCardHover!.id) as string}
            longitude={
              hoveredListing?.geometry.coordinates[0] ??
              secondaryHover?.geometry.coordinates[0] ??
              resultCardHover!.geometry.coordinates[0]
            }
          />
        )}

        {selectedOption && !selectedOption?.listingId && (
          <Marker
            key="current-location2"
            latitude={selectedOption?.center?.[1]}
            longitude={selectedOption?.center?.[0]}>
            <Icon classNames="!h-[4rem] !w-[4rem]" name={IconName.PIN_PROPERTY} />
          </Marker>
        )}

        {currentLocation && (
          <Marker
            key="current-location"
            latitude={currentLocation.latitude}
            longitude={currentLocation.longitude}>
            <Icon classNames="!h-[2rem] !w-[2rem]" name={IconName.CURRENT_LOCATION} />
          </Marker>
        )}

        {marketBoundary && (
          <Source id="market-data" type="geojson" data={marketBoundary}>
            <Layer {...getMarketLineLayerProps()} />
            <Layer {...getMarketFillLayerProps()} />
          </Source>
        )}

        {submarketBoundaries && (
          <Source id="submarket-data" type="geojson" data={submarketBoundaries}>
            <Layer {...getSubmarketLineLayerProps()} />
          </Source>
        )}

        {circle && (
          <Source id="circle-data" type="geojson" data={circle}>
            <Layer {...getCircleLineLayerProps()} />
            {tempCircle && <Layer {...getCircleFillLayerProps()} />}
          </Source>
        )}

        {is3dActive && <Layer {...getThreeDBuildingsLayerProps()} />}
      </MapboxMap>
      {showSaveSearchModal && (
        <SaveSearchModal
          defaultName={sharedSearch?.name}
          onClose={() => {
            setShowSaveSearchModal(false);
          }}
        />
      )}
    </>
  );
};

export default Map;

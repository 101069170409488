export const NO_I_WANT_TO_CANCEL = 'No, I want to cancel';
export const SELECT_ALL = 'Select All';
export const SHOWING_ALL_RESULTS = 'Showing All Results';
export const SHARE_COLLECTION_QUERY_PARAMETER_NAME = 'sharedId';
export const SHARE_SEARCH_QUERY_PARAMETER_NAME = 'savedsearchid';
export const MAX_CHARACTERS_ALLOWED = 35;
export const MAX_CHARACTERS_ALLOWED_FOR_CONTACT_MESSAGE_BOX = 300;
export const EXCEL_REPORT_CHUNK_SIZE = 4000;
export const CURRENT_LOCATION_STRING = 'Current Location';
export const LISTVIEW_CACHE_KEY = 'last-view';
export function isiOS() {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export enum PropertyType {
  DEVELOPMENT = 'DEVELOPMENT',
  INDUSTRIAL = 'INDUSTRIAL',
  BUSINESS_PARK = 'BUSINESS_PARK',
  PRE_DEVELOPMENT = 'PRE_DEVELOPMENT',
}

export const isIndustrialOrPark = (propertyType: string | undefined) =>
  propertyType === PropertyType.INDUSTRIAL || propertyType === PropertyType.BUSINESS_PARK;

export const orderDevelopmentPrimaryInfoLargeScreen = [
  'NAME',
  'FULL_ADDRESS',
  'MARKET',
  'CONTACT_US_OR_DATE_AVAILABLE',
  'SUBMARKET',
  'ACREAGE',
  'BUILDING_SQUARE_FOOTAGE',
  'UNIT_SQUARE_FOOTAGE',
  'DIGITAL_ASSETS',
  'BUILDING_STATUS',
  'MODIFIED_AT',
  'PROPERTY_ID',
];
export const orderDevelopmentPrimaryInfoMediumScreen = [
  'NAME',
  'FULL_ADDRESS',
  'MARKET',
  'SUBMARKET',
  'CONTACT_US_OR_DATE_AVAILABLE',
  'ACREAGE',
  'BUILDING_SQUARE_FOOTAGE',
  'UNIT_SQUARE_FOOTAGE',
  'DIGITAL_ASSETS',
  'BUILDING_STATUS',
  'MODIFIED_AT',
  'PROPERTY_ID',
];

export const orderIndustrialParkPrimaryInfoLargeScreen = [
  'NAME',
  'FULL_ADDRESS',
  'MARKET',
  'BUILDING_SQUARE_FOOTAGE',
  'SUBMARKET',
  'UNIT_SQUARE_FOOTAGE',
  'CONTACT_US_OR_DATE_AVAILABLE',
  'DIGITAL_ASSETS',
  'BUILDING_STATUS',
  'MODIFIED_AT',
  'PROPERTY_ID',
];
export const orderIndustrialParkPrimaryInfoMediumScreen = [
  'NAME',
  'FULL_ADDRESS',
  'MARKET',
  'SUBMARKET',
  'CONTACT_US_OR_DATE_AVAILABLE',
  'BUILDING_SQUARE_FOOTAGE',
  'UNIT_SQUARE_FOOTAGE',
  'DIGITAL_ASSETS',
  'BUILDING_STATUS',
  'MODIFIED_AT',
  'PROPERTY_ID',
];
export const orderDevelopmentUnitSpecs = [
  'UNIT_SQUARE_FOOTAGE_RANGE',
  'TRAILER_PARKING_AVAILABLE',
  'UNIT_OFFICE_SQUARE_FOOTAGE_OR_BUILD_TO_SUIT',
  'RAIL_SERVED',
  'CLEAR_HEIGHT',
  'ESFR',
  'LOADING_DOCKS',
  'OUTDOOR_STORAGE',
  'DRIVE_IN_DOORS',
  'TENANCY',
  'TRUCK_COURT_DEPTH',
];
export const orderIndustrialParkUnitSpecs = [
  'UNIT_SQUARE_FOOTAGE_RANGE',
  'UNIT_OFFICE_SQUARE_FOOTAGE_OR_BUILD_TO_SUIT',
  'TRAILER_PARKING_AVAILABLE',
  'CLEAR_HEIGHT',
  'RAIL_SERVED',
  'LOADING_DOCKS',
  'ESFR',
  'DRIVE_IN_DOORS',
  'OUTDOOR_STORAGE',
  'TRUCK_COURT_DEPTH',
  'TENANCY',
];

export enum DevelopmentType {
  label = 'Developments (All Subtypes)',
  value = 'Development',
}
export enum IndustrialType {
  label = 'Industrial',
  value = 'Operational Industrial',
}
export enum ParksType {
  label = 'Parks',
  value = 'Link Parks',
}

export enum BTSType {
  label = 'Build to Suit Opportunities',
}
export enum UCType {
  label = 'Under Construction',
}
export enum NewClassType {
  label = 'New Class A Developments',
}

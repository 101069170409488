import React, { useState } from 'react';

import Button, { ButtonVariant } from '@components/Button';
import { TextInput, ToggleSwitch } from '@components/Inputs';
import Modal from '@components/Modal';
import useDelimitedActiveFiltersValues from '@/hooks/useDelimitedActiveFiltersValues';
import { useToast } from '@/hooks/useToast';
import { useUserSavedSearches } from '@/hooks/useUserSavedSearches';
import { SaveSearchModalProps } from './SaveSearchModal.types';
import { encodeString } from '@/utilities/textHelpers';
import { Typography } from '@components/Typography';

const SaveSearchModal: React.FC<SaveSearchModalProps> = ({ defaultName, onClose }) => {
  const currentUrl = window.location.href;

  const [nameExistsError, setNameExistsError] = useState('');
  const [enableEmailAlerts, setEnableEmailAlerts] = useState(false);
  const [input, setInput] = useState(defaultName ?? '');

  const { delimitedActiveFiltersValues } = useDelimitedActiveFiltersValues();
  const { addSavedSearch } = useUserSavedSearches();
  const { addToast } = useToast();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    nameExistsError && setNameExistsError('');
    const encodedString = encodeString(e.target.value);
    setInput(encodedString);
  };

  const handleSaveSearch = () => {
    addSavedSearch(input, currentUrl, delimitedActiveFiltersValues, enableEmailAlerts)
      .then(() => {
        addToast({
          id: `save-search-${input}-${currentUrl}`,
          description: 'Search saved',
          title: 'Success',
          type: 'success',
        });
        if (enableEmailAlerts) {
          addToast({
            id: `save-search-alerts-${input}-${currentUrl}-`,
            description: 'Email preference updated',
            title: 'Success',
            type: 'success',
          });
        }
        onClose();
      })
      .catch((error) => {
        const { exceptionMessage } = error.response.data.responseException;
        setNameExistsError(exceptionMessage);
      });
  };

  return (
    <Modal haveClose onClose={onClose} show>
      <div className="mb-4 text-center">
        <h6 className="mt-6 mb-4 font-bold">Save This Search</h6>
        <p>{delimitedActiveFiltersValues}</p>
      </div>
      <small className="my-6">Name your search</small>
      <TextInput
        className={'text-input min-w-full mt-2 mb-2'}
        error={!!nameExistsError}
        errorMessage={nameExistsError}
        onChange={handleChange}
        placeholder="My New Search"
        value={input}
        errorClasses="break-all"
      />

      <ToggleSwitch
        rightLabel="Allow Email Notifications"
        toggled={enableEmailAlerts}
        id="enableEmailAlerts"
        classNames="text-sm font-normal gap-2 mt-8"
        onClick={setEnableEmailAlerts}
      />
      <Typography className="block mt-2 mb-4" variant="body-5">
        Get notified when listings that meet this search criteria are added to our system{' '}
      </Typography>
      <Button
        buttonClassNames={nameExistsError ? 'w-full !mt-6 bg-slate-500' : 'w-full  bg-slate-500'}
        disabled={!input || !!nameExistsError}
        label="Confirm"
        onClick={handleSaveSearch}
        variant={ButtonVariant.PRIMARY_ONE}
      />
    </Modal>
  );
};

export default SaveSearchModal;

import { useIsAuthenticated } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';

import { IconName } from '@components/Icon';
import { Typography } from '@components/Typography';
import './AppSwitcher.css';
import Link from '@components/Link/Link';
import { useApiClient } from '@/hooks/useApiClient';
import { AppConfig } from '@/types/app-config';
import HeaderItemWithOptions from '../HeaderItemWithOptions';

const AppSwitcher: React.FC = () => {
  const _customerIQUrlKey = 'CustomerIQUrl';
  const _propertyIQUrlKey = 'PropertyIQUrl';
  const _researchIQUrlKey = 'ResearchIQUrl';
  const _reportingLibraryUrlKey = 'ReportingLibraryUrl';
  const _theLinkUrlKey = 'TheLinkUrl';
  const isAuthenticated = useIsAuthenticated();

  const { getAppConfigs } = useApiClient();

  const [configs, setConfigs] = useState<AppConfig[]>([]);

  const { data: appConfigs } = getAppConfigs();

  useEffect(() => {
    setConfigs(appConfigs!);
  }, [appConfigs]);

  const isValidConfig = (key: string) => {
    const value = configValue(key);
    return !!value;
  };

  const configValue = (key: string) => {
    const config = configs?.find((c) => c.key === key && c.isActive);
    return config?.value;
  };

  const menu = (
    <>
      {isValidConfig(_customerIQUrlKey) && (
        <Link url={configValue(_customerIQUrlKey)!} newTab={true}>
          <Typography className="text-base-black p-2 pl-5 link-label link-" variant="body-1">
            Customer IQ
          </Typography>
        </Link>
      )}
      {isValidConfig(_propertyIQUrlKey) && (
        <Link classNames="rounded" url={configValue(_propertyIQUrlKey)!} newTab={true}>
          <Typography className="text-base-black p-2 pl-5 link-label " variant="body-1">
            Property IQ
          </Typography>
        </Link>
      )}
      {isValidConfig(_researchIQUrlKey) && (
        <Link classNames="rounded" url={configValue(_researchIQUrlKey)!} newTab={true}>
          <Typography className="text-base-black p-2 pl-5 link-label " variant="body-1">
            Research IQ
          </Typography>
        </Link>
      )}
      {isValidConfig(_reportingLibraryUrlKey) && (
        <Link classNames="rounded" url={configValue(_reportingLibraryUrlKey)!} newTab={true}>
          <Typography className="text-base-black p-2 pl-5 link-label " variant="body-1">
            Reporting Library
          </Typography>
        </Link>
      )}
      {isValidConfig(_theLinkUrlKey) && (
        <Link classNames="rounded" url={configValue(_theLinkUrlKey)!} newTab={true}>
          <Typography className="text-base-black p-2 pl-5 link-label " variant="body-1">
            The Link
          </Typography>
        </Link>
      )}
    </>
  );

  return (
    <>
      {isAuthenticated && (
        <>
          <HeaderItemWithOptions
            defaultIcon={IconName.APP_SWITCHER}
            hoverIcon={IconName.APP_SWITCHER_HOVER}
            showIconBorderWhenActive={true}>
            {menu}
          </HeaderItemWithOptions>
        </>
      )}
    </>
  );
};

export default AppSwitcher;

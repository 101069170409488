import {
  BTSType,
  DevelopmentType,
  IndustrialType,
  NewClassType,
  ParksType,
  UCType,
} from '@/utilities/constants';
import { CheckboxDropdownSelectorOption } from '@components/Inputs/CheckboxDropdown/CheckboxDropdown.types';

export const originalOptions: CheckboxDropdownSelectorOption[] = [
  {
    label: DevelopmentType.label,
    value: DevelopmentType.value,
    childs: [
      {
        label: BTSType.label,
        value: BTSType.label,
      },
      {
        label: NewClassType.label,
        value: NewClassType.label,
      },
      {
        label: UCType.label,
        value: UCType.label,
      },
    ],
  },
  {
    label: IndustrialType.label,
    value: IndustrialType.value,
  },
  {
    label: ParksType.label,
    value: ParksType.value,
  },
];

import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { API_LISTINGS_BASE_URL } from '@/config';
import {
  FormattedLocation,
  FormattedLocationSearchDataAndCount,
  LocationCategory,
  LocationSearchData,
  SearchedLocation,
} from '@/types';

const formatLocationSearchData = (data: LocationSearchData) => {
  let currentOptionIndex = 0;

  const formattedLocationSearchData = Object.entries(data).reduce(
    (acc, [category, locations]) => {
      const formattedLocations: FormattedLocation[] = (locations as SearchedLocation[]).map(
        (location) => {
          const formattedLocations = { ...location, optionIndex: currentOptionIndex };

          currentOptionIndex++;

          return formattedLocations;
        },
      );

      acc.locationSearchData[category as LocationCategory] = formattedLocations;

      return acc;
    },
    {
      locationSearchData: {},
      resultsCount: 0,
    } as FormattedLocationSearchDataAndCount,
  );

  formattedLocationSearchData.resultsCount = currentOptionIndex + 1;

  return formattedLocationSearchData;
};

export function getLocationSearchData(axios: AxiosInstance, query: string) {
  return useQuery({
    queryKey: ['location-search', query],
    queryFn: async () => {
      const response = await axios.get<LocationSearchData>(
        `${API_LISTINGS_BASE_URL}/location-search`,
        {
          params: {
            query,
          },
        },
      );

      return formatLocationSearchData(response.data);
    },
    enabled: !!query,
  });
}

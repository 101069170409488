import React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapboxMap, { Marker } from 'react-map-gl';

import { Icon, IconName } from '@components/Icon';
import { MAPBOX_ACCESS_TOKEN, MAP_FLY_ZOOM, MAP_STYLES } from '@/config';

const ListingDetailsMap: React.FC<{
  latitude: number;
  longitude: number;
}> = ({ latitude, longitude }) => {
  return (
    <MapboxMap
      attributionControl={false}
      initialViewState={{
        latitude,
        longitude,
        zoom: MAP_FLY_ZOOM,
      }}
      boxZoom={false}
      scrollZoom={false}
      doubleClickZoom={false}
      dragPan={false}
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      mapStyle={MAP_STYLES[0].url}>
      <Marker key={`pin-${latitude}`} latitude={latitude} longitude={longitude}>
        <div className="relative flex items-center justify-center w-[2.75rem] h-[3.8125rem] !hover:pointer-events-none">
          <Icon classNames="absolute !h-[3.8125rem] !w-[2.75rem]" name={IconName.PIN_DEFAULT} />
        </div>
      </Marker>
    </MapboxMap>
  );
};

export default ListingDetailsMap;

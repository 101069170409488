import { FormatVariantStateClassNamesFunc } from './Button.types';

const buttonVariantsStatesClassNames = {
  default: {
    defaultOutline: 'btn-default-outline--default',
    defaultOutlineDarkTheme: 'btn-default-outline--default-dark-theme',
    noFill: 'btn-no-fill--default',
    noFillWhite: 'btn-no-fill--default-white',
    primaryOne: 'btn-primary-one--default',
    primaryTwo: 'btn-primary-two--default',
    revealAction: 'btn-reveal-action--default group',
    saveSearch: 'btn-save-search--default',
    reportMapPage: 'btn-report--default',
    mobileAction: 'btn-mobile-action--default',
  },
  disabled: {
    defaultOutline: 'btn-default-outline--disabled',
    defaultOutlineDarkTheme: 'btn-default-outline--disabled-dark-theme',
    noFill: 'btn-no-fill--disabled',
    noFillWhite: 'btn-no-fill--disabled-white',
    primaryOne: 'btn-primary-one--disabled',
    primaryTwo: 'btn-primary-two--disabled',
    revealAction: 'btn-reveal-action--disabled group',
    saveSearch: 'btn-save-search--default',
    reportMapPage: 'btn-report--disabled',
    mobileAction: 'btn-mobile-action--default',
  },
  error: {
    defaultOutline: 'btn-default-outline--error',
    defaultOutlineDarkTheme: 'btn-default-outline--error-dark-theme',
    noFill: 'btn-no-fill--error',
    noFillWhite: 'btn-no-fill--error',
    primaryOne: 'btn-primary-one--default',
    primaryTwo: 'btn-primary-two--default',
    revealAction: 'btn-reveal-action--default group',
    saveSearch: 'btn-save-search--default',
    reportMapPage: 'btn-report--default',
    mobileAction: 'btn-mobile-action--default',
  },
};

export const formatVariantStateClassNames: FormatVariantStateClassNamesFunc = (
  disabled,
  error,
  variant,
) => {
  const buttonState = disabled ? 'disabled' : error ? 'error' : 'default';

  return buttonVariantsStatesClassNames[buttonState][variant];
};

import React from 'react';
import cn from 'classnames';
import './ToggleSwitch.css';
import { ToggleSwitchProps } from './ToggleSwitch.types';

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  classNames,
  disabled,
  id,
  key = null,
  leftLabel,
  name,
  onClick,
  rightLabel,
  toggled,
}) => {
  const handleClick = () => {
    if (!disabled) {
      onClick(!toggled, name);
    }
  };

  const labelDisabledClassName = disabled ? 'text-cement-200' : null;
  const switchContainerDisabledClassName = disabled
    ? toggled
      ? 'toggle-switch-container--toggled--disabled'
      : 'toggle-switch-container--disabled'
    : null;
  const toggledSwitchContainerClassName = toggled ? '!bg-freight-100' : null;
  const toggledSwitchClassName = toggled ? 'translate-x-4' : null;

  return (
    <div key={key} className={cn(['flex items-center', classNames])} id={id}>
      {leftLabel && (
        <label className={cn(['mr-2', labelDisabledClassName])} htmlFor={id}>
          {leftLabel}
        </label>
      )}
      <div
        className={cn([
          'toggle-switch-container',
          toggledSwitchContainerClassName,
          switchContainerDisabledClassName,
        ])}
        onClick={handleClick}>
        <div className={cn(['toggle-switch', toggledSwitchClassName])}></div>
      </div>
      {rightLabel && (
        <label className={cn(['ml-2', labelDisabledClassName])} htmlFor={id}>
          {rightLabel}
        </label>
      )}
    </div>
  );
};

export default ToggleSwitch;

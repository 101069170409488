import { InteractionRequiredAuthError } from '@azure/msal-common';
import Axios, { InternalAxiosRequestConfig } from 'axios';

import { APIM_SUBSCRIPTION_KEY } from '@/config';
import { apiScopes, msalInstance, msGraphScopes } from './msal';

const handleTokenAcquisition =
  (scopes: string[]) => async (config: InternalAxiosRequestConfig<any>) => {
    const account = msalInstance.getActiveAccount() ?? undefined;

    try {
      const authenticationResult = await msalInstance.acquireTokenSilent({
        account,
        scopes,
      });

      config.headers['Authorization'] = `Bearer ${authenticationResult.accessToken}`;
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        await msalInstance.acquireTokenRedirect({ scopes });
      }
    }

    return config;
  };

export const marketplaceApiInstance = Axios.create({
  headers: {
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': APIM_SUBSCRIPTION_KEY,
  },
});

marketplaceApiInstance.interceptors.request.use(handleTokenAcquisition(apiScopes));

export const microsoftGraphApiInstance = Axios.create({
  baseURL: 'https://graph.microsoft.com/',
  headers: {
    'Content-Type': 'application/json',
  },
});

microsoftGraphApiInstance.interceptors.request.use(handleTokenAcquisition(msGraphScopes));

import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { CheckboxState } from '@components/Inputs';

import { ButtonVariant } from '@components/Button';
import ConfirmationModal, { BtnsDirection } from '@components/ConfirmationModal';
import { Icon, IconName } from '@components/Icon';
import OverflowAndScrollBarHiddenWrapper from '@components/OverflowHiddenWrapper';
import FavoriteModalCard from './FavoriteModalCard';

import { FavoriteModalProps } from './FavoritesModal.types';
import { useUserFavorites } from '@/hooks/useUserFavorites';
import './FavoriteModal.css';
import { Typography } from '@components/Typography';
import { useApiClient } from '@/hooks/useApiClient';
import { useUserFavoriteCollections } from '@/hooks/useUserFavoriteCollections';
import _ from 'lodash';
import LoadingSpinner from '@components/LoadingSpinner/LoadingSpinner';

const FavoriteCollectionsModal: React.FC<FavoriteModalProps> = ({
  favoriteCollectionId,
  onClose,
}) => {
  const header = 'Add Favorites to Collection';
  const { favorites } = useUserFavorites();
  const reversedFavorites = favorites.filter((favorite) => favorite.isVisible).slice();
  const searchRef = useRef<HTMLInputElement>(null);

  const [searchInputValue, setSearchInputValue] = useState('');
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [listingIdsToSave, setListingIdsToSave] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isStateModified, setIsStateModified] = useState(false);

  const { getFavoriteCollectionById } = useApiClient();
  const { data: favoriteCollection, refetch } = getFavoriteCollectionById(favoriteCollectionId!);
  const { addFavoritesToFavoriteCollection, removeFavoritesFromFavoriteCollection } =
    useUserFavoriteCollections();
  const handleSearchChange = (e: any) => {
    setSearchInputValue(e.target.value);
    refetch();
  };

  const onCardChecked = (favoriteId: string, checkboxState: CheckboxState) => {
    setListingIdsToSave((prevState) => {
      if (checkboxState === CheckboxState.CHECKED) {
        if (!prevState.includes(favoriteId)) {
          const tempList = prevState.filter((f) => f);
          tempList.push(favoriteId);
          return tempList;
        } else return prevState.filter((f) => f);
      } else if (prevState.includes(favoriteId)) {
        const tempList = prevState.filter((f) => f !== favoriteId);
        return tempList;
      } else {
        return prevState;
      }
    });
  };

  const listingsToAddCount = listingIdsToSave.length - (favoriteCollection?.favorites?.length ?? 0);
  const buttonText = `Add Selected ${
    listingsToAddCount > 1 ? 'Listings' : 'Listing'
  } to Collection`;

  const onCancel = () => {
    if (!isStateModified) {
      onClose();
    } else {
      setShowUnsavedChangesModal(true);
    }
  };

  const onSave = () => {
    const existingFavIds = favoriteCollection?.favorites.map((f) => f.favoriteId)!;
    const idsToRemove = existingFavIds.filter(
      (idExisting) => !listingIdsToSave.includes(idExisting),
    );
    setLoading(true);
    removeFavoritesFromFavoriteCollection(favoriteCollectionId, idsToRemove)
      .then(() => {
        addFavoritesToFavoriteCollection(favoriteCollectionId, listingIdsToSave).then(() => {
          setLoading(false);
          onClose();
        });
      })
      .catch(() => {
        addFavoritesToFavoriteCollection(favoriteCollectionId, existingFavIds).then(() => {
          setLoading(false);
          onClose();
        });
      });
  };

  useEffect(() => {
    const existingFavIds = favoriteCollection?.favorites.map((f) => f.favoriteId)!;
    const sortedArrayNew = listingIdsToSave.sort((a, b) => a.localeCompare(b));
    const sortedArrayExisting = existingFavIds.sort((a, b) => a.localeCompare(b));
    if (_.isEqual(sortedArrayNew, sortedArrayExisting)) {
      setIsStateModified(false);
    } else {
      setIsStateModified(true);
    }
  }, [listingIdsToSave]);

  useEffect(() => {
    setListingIdsToSave(favoriteCollection?.favorites.map((f) => f.favoriteId)!);
  }, []);

  return (
    <div>
      {showUnsavedChangesModal ? (
        <ConfirmationModal
          btnsDirection={BtnsDirection.ROW}
          classNames=""
          header="Unsaved Changes"
          description="You have unsaved changes. Are you sure you want to cancel?"
          primaryBtnLabel="No"
          primaryBtnOnClick={() => setShowUnsavedChangesModal(false)}
          primaryBtnVariant={ButtonVariant.DEFAULT_OUTLINE}
          secondaryBtnLabel="Yes"
          secondaryBtnOnClick={onClose}
          secondaryBtnVariant={ButtonVariant.PRIMARY_ONE}
          onClose={onClose}
          show
          haveCloseButton={false}></ConfirmationModal>
      ) : (
        <ConfirmationModal
          btnsDirection={BtnsDirection.COL}
          classNames="post-favorited-modal"
          header={header}
          primaryBtnLabel={buttonText}
          primaryBtnVariant={ButtonVariant.PRIMARY_ONE}
          primaryBtnOnClick={onSave}
          primaryBtnDisabled={loading || reversedFavorites.length <= 0 || !isStateModified}
          hidePrimaryButton={loading || reversedFavorites.length <= 0 || !isStateModified}
          secondaryBtnIcon={<Icon name={IconName.ARROW_LEFT} />}
          secondaryBtnLabel="Back to Collection"
          secondaryBtnOnClick={onCancel}
          secondaryBtnVariant={ButtonVariant.DEFAULT_OUTLINE}
          onClose={onCancel}
          show>
          <Typography variant="body-2" className="mt-2 mb-4">
            Select the listings you would like to add to this collection.
          </Typography>
          <div className="fav-search-input-wrapper">
            <Icon classNames="!h-6 !w-6 mr-3" name={IconName.SEARCH} />
            <input
              className="fav-search-input"
              ref={searchRef}
              onChange={handleSearchChange}
              placeholder={`Search by Listing Name (${favorites.length})`}
              type="text"
              value={searchInputValue}
            />
          </div>

          {reversedFavorites.length > 0 ? (
            <OverflowAndScrollBarHiddenWrapper classNames="mt-6" heightRem={20}>
              {reversedFavorites
                .filter((item) => item.name.toLowerCase().includes(searchInputValue.toLowerCase()))
                .map((favorite, index) => {
                  const exists =
                    listingIdsToSave.length <= 0
                      ? favoriteCollection?.favorites.some(
                          (item) => item.favoriteId === favorite.favoriteId,
                        )
                      : listingIdsToSave.some((item) => item === favorite.favoriteId);
                  const disabled = favoriteCollection?.favorites.some(
                    (item) => item.favoriteId === favorite.favoriteId,
                  );
                  return (
                    <FavoriteModalCard
                      key={favorite.listingId}
                      classNames={cn([index && 'mt-4'])}
                      favoriteCollectionId={favoriteCollectionId}
                      favoriteId={favorite.favoriteId}
                      favoriteName={favorite.name}
                      favoriteSq={favorite.size}
                      favoriteAddress={favorite.addressLine1}
                      thumbnailImageUrl={favorite.thumbnailImageUrl ?? null}
                      isChecked={exists ? CheckboxState.CHECKED : CheckboxState.EMPTY}
                      isDisabled={disabled}
                      onChecked={onCardChecked}
                    />
                  );
                })}
            </OverflowAndScrollBarHiddenWrapper>
          ) : (
            <div className="mt-6">
              {loading ? (
                <LoadingSpinner />
              ) : (
                <Typography variant="body-2">There are no saved listings.</Typography>
              )}
            </div>
          )}
        </ConfirmationModal>
      )}
    </div>
  );
};

export default FavoriteCollectionsModal;

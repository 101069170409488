import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './index.css';

import App from './App';
import { msalInstance } from './lib/msal';

import 'react-tooltip/dist/react-tooltip.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

// function setBaseScalingFactor() {
//   let scalingFactor = 100;

//   // NOTE - Windows applies a recommended scaling factor by default based on a
//   // number of heuristics. To ensure the initial experience of the application
//   // is aligned with our design, we account for this below. We only do this on
//   // the initial load, to avoid tredding too much on user preferences, with
//   // respect to accessibility concerns. We will continue to refine this, as it
//   // is not ideal.

//   if (navigator.platform.toLowerCase().startsWith('win')) {
//     scalingFactor = 100 * (1 / window.devicePixelRatio);
//   }

//   document.documentElement.style.fontSize = `${scalingFactor}%`;
// }

// setBaseScalingFactor();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

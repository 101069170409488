import React from 'react';
import cn from 'classnames';

import { OverflowHiddenWrapperProps } from './OverflowHiddenWrapper.types';

const OverflowHiddenWrapper: React.FC<OverflowHiddenWrapperProps> = ({
  children,
  classNames,
  heightRem,
  maxHeightRem,
  height,
}) => {
  const heightStyle = maxHeightRem
    ? { maxHeight: `${maxHeightRem}rem` }
    : { height: heightRem ? `${heightRem}rem` : height };

  return (
    <div className={cn(['overflow-auto w-full', classNames])} style={heightStyle}>
      <div className="w-full h-full overflow-y-auto overflow-hidden-inner-wrapper">{children}</div>
    </div>
  );
};

export default OverflowHiddenWrapper;

import React, { useState } from 'react';

import { Icon, IconName } from '@components/Icon';
import './HeaderItemWithOptions.css';
import cn from 'classnames';

export interface HeaderItemWithOptionsProps {
  defaultIcon: IconName;
  hoverIcon: IconName;
  showIconBorderWhenActive: boolean;
  children?: any;
}

const HeaderItemWithOptions: React.FC<HeaderItemWithOptionsProps> = ({
  defaultIcon,
  hoverIcon,
  children,
  showIconBorderWhenActive = false,
}) => {
  const [iconName, setIconName] = useState<IconName>(defaultIcon);
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="mr-8 z-[1000] overflow-hidden">
        <button
          className={cn([
            'flex items-center space-x-3 rounded p-1',
            showIconBorderWhenActive && open ? 'open-menu' : 'closed-menu',
          ])}
          onMouseEnter={() => {
            setIconName(hoverIcon);
          }}
          onMouseLeave={() => setIconName(defaultIcon)}
          onClick={() => setOpen(!open)}>
          <Icon classNames="!h-[1.125rem] !w-[1.125rem]" name={iconName} />
        </button>
        {open && (
          <div className="absolute mt-1 right-[8.25rem] rounded header-menu bg-base-white w-[200px] max-h-[250px]">
            {children}
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderItemWithOptions;

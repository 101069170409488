import React from 'react';

import { useToast } from '@/hooks/useToast';
import { useUserFavoriteCollections } from '@/hooks/useUserFavoriteCollections';
import { decodeString } from '@/utilities/textHelpers';
import ConfirmationModal from '@components/ConfirmationModal';
import { RemoveFavoritesFromCollectionModalProps } from './RemoveFavoritesFromCollectionModal.types';

export const RemoveFavoritesFromCollectionModal: React.FC<
  RemoveFavoritesFromCollectionModalProps
> = ({ show, favoriteCollection, favorites, onConfirm, onClose }) => {
  const { removeFavoritesFromFavoriteCollection } = useUserFavoriteCollections();
  const { addToast } = useToast();

  const handleConfirm = async () => {
    await removeFavoritesFromFavoriteCollection(
      favoriteCollection.favoriteCollectionId,
      favorites.map((x) => x.favoriteId),
    );

    addToast({
      id: `remove-favorites-from-collection-${new Date().getTime()}`,
      description: 'Favorite(s) Removed Successfully',
      title: 'Success',
      type: 'success',
    });

    onConfirm ? onConfirm() : onClose();
  };

  return (
    <ConfirmationModal
      //classNames="sm:!w-[30.375rem]"
      header="Remove from Collection"
      onClose={onClose}
      primaryBtnLabel="Yes, remove from collection"
      primaryBtnOnClick={handleConfirm}
      secondaryBtnLabel="No, I want to cancel"
      secondaryBtnOnClick={onClose}
      show={show}
      subText={
        favorites.length === 1 ? (
          <>
            Are you sure you want to remove{' '}
            <span className="font-bold underline">{favorites[0].name}</span> listing from your
            collection &apos;{decodeString(favoriteCollection.name)}&apos;?
          </>
        ) : (
          `Are you sure you want to remove the selected listings (${
            favorites.length
          }) from your collection '${decodeString(favoriteCollection.name)}'?`
        )
      }
    />
  );
};

export default RemoveFavoritesFromCollectionModal;

export interface GridMapToggleSwitchProps {
  classNames?: string;
  onClick: (option: ToggleSwitchOption) => void;
  option: ToggleSwitchOption;
  variant?: ToggleSwitchVariant;
}

export interface GridMapToggleSwitchOptionProps {
  active: boolean;
  onClick: (option: ToggleSwitchOption) => void;
  label: string;
  option: ToggleSwitchOption;
  variantIsBlack: boolean;
}

export enum ToggleSwitchOption {
  GRID = 'GRID',
  MAP = 'MAP',
}

export enum ToggleSwitchVariant {
  BLACK = 'BLACK',
  RUSTY = 'RUSTY',
}

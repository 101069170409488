import React, { useState } from 'react';

import Button, { ButtonVariant } from '@components/Button';
import { TextInput } from '@components/Inputs';
import Modal from '@components/Modal';
import { Typography } from '@components/Typography';
import { useToast } from '@/hooks/useToast';
import { useUserSavedSearches } from '@/hooks/useUserSavedSearches';
import useDelimitedActiveFiltersValues from '@/hooks/useDelimitedActiveFiltersValues';
import { EditSearchTitleModalProps } from './SavedSearchCard.types';
import { encodeString } from '@/utilities/textHelpers';

const EditSearchTitleModal: React.FC<EditSearchTitleModalProps> = ({
  savedSearch,
  setShowEditSearchTitleModal,
  value,
}) => {
  const [input, setInput] = useState(value);
  const [nameExistsError, setNameExistsError] = useState('');

  const { addToast } = useToast();
  const { updateSavedSearch } = useUserSavedSearches();
  const startOfQueryParameterIndex = savedSearch.url.indexOf('?');
  const queryParameter = savedSearch.url.substring(startOfQueryParameterIndex);
  const { delimitedActiveFiltersValues } = useDelimitedActiveFiltersValues(queryParameter);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    nameExistsError && setNameExistsError('');
    const encodedString = encodeString(e.target.value);
    setInput(encodedString);
  };

  const handleCloseEditSearchTitleModal = () => {
    setShowEditSearchTitleModal(false);
  };

  const handleEditSearchTitle = (updatedTitle: string) => {
    const { url, savedSearchId, emailAlertsEnabled } = savedSearch;
    updateSavedSearch(
      savedSearchId,
      updatedTitle,
      url,
      emailAlertsEnabled,
      delimitedActiveFiltersValues,
    )
      .then(() => {
        addToast({
          description: 'Search Title Has Been Updated.',
          title: 'Success',
          type: 'success',
        });
        handleCloseEditSearchTitleModal();
      })
      .catch((error) => {
        const { exceptionMessage } = error.response.data.responseException;
        setNameExistsError(exceptionMessage);
      });
  };

  return (
    <Modal haveClose onClose={handleCloseEditSearchTitleModal} show>
      <div className="my-4">
        <Typography className="mb-6 text-center capitalize" variant="h6">
          Edit Search Title
        </Typography>
      </div>

      <Typography variant="body-4" className="mb-4">
        Title Name
      </Typography>

      <TextInput
        className="min-w-full mt-2 mb-8 text-input"
        error={!!nameExistsError}
        errorMessage={nameExistsError}
        onChange={handleChange}
        value={input}
      />

      <Button
        buttonClassNames={nameExistsError ? 'w-full mt-8' : 'w-full'}
        disabled={!input || !!nameExistsError}
        label="Confirm"
        onClick={() => handleEditSearchTitle(input)}
        variant={ButtonVariant.PRIMARY_ONE}
      />
    </Modal>
  );
};

export default EditSearchTitleModal;

export const isValidNumericStringValue = (value: string) => {
  const isEmptyString = value === '';
  const isNonNumericValue = !/^[0-9]*$/.test(value); // checks if current value is numeric characters only
  const isZero = value.charAt(0) === '0' && value.length === 1; // prevents characters from being enters after initial zero input
  const isGreaterThanZero = parseInt(value, 10) > 0 && !(value.charAt(0) === '0'); // prevents characters from being enters after initial zero input

  return !isNonNumericValue && (isEmptyString || isZero || isGreaterThanZero);
};

// adds highlight and bold styling to the suggestive substring in input to create a focus
export const predictiveHighlightedValue = (inputValue: string, predictiveValue: string) => {
  const valuesMatch =
    predictiveValue.toLowerCase() === inputValue.toLowerCase() ||
    predictiveValue.length < inputValue.length;
  const predictiveSubstring = predictiveValue.substring(inputValue.length);

  return (
    <>
      {!valuesMatch && (
        <div className="flex">
          <div className="text-transparent whitespace-pre">{inputValue}</div>
          <div className="bg-site-100/50 font-semibold pr-[0.3125rem] whitespace-pre">
            {predictiveSubstring}
          </div>
        </div>
      )}
    </>
  );
};

// adds bold styling to the option value's initial substring that matches the input
export const searchResultOption = ({
  inputValue,
  minMaxDefaultValue,
  optionIdentifierSubtext,
  optionValue,
}: {
  inputValue: string;
  minMaxDefaultValue?: string;
  optionIdentifierSubtext?: string;
  optionValue: string;
}) => {
  const matchedInputValueSubstring = optionValue.substring(0, inputValue.length);
  const optionValueSubstring = optionValue.substring(inputValue.length);
  const identifierSubtext = optionValue === minMaxDefaultValue ? '' : optionIdentifierSubtext;

  return (
    <>
      <span className="font-semibold">{matchedInputValueSubstring}</span>
      {optionValueSubstring} {identifierSubtext}
    </>
  );
};

import React, { useState } from 'react';

import AssetsCarousel from '@components/AssetsCarousel';
import Thumbnail from '@components/Thumbnail/Thumbnail';
import { Typography } from '@components/Typography';
import { formatNumberWithCommas } from '@/utilities/textHelpers';

export interface ThumbnailWrapperProps {
  assetUrls: string[];
  thumbnailUrl: string;
}

const ThumbnailWrapper: React.FC<ThumbnailWrapperProps> = ({ assetUrls, thumbnailUrl }) => {
  const [showAssetsCarouselModal, setShowAssetsCarouselModal] = useState(false);

  const heroImageUrl = thumbnailUrl === '--' ? undefined : thumbnailUrl;
  const filteredAssetUrls = assetUrls.filter((asset) => !!asset);
  const assetCount = filteredAssetUrls.length;
  return (
    <>
      <div className="relative overflow-hidden rounded-lg group">
        <Thumbnail imgClassNames="h-[15.5rem]" propertyImageUrl={heroImageUrl} />
        {!!assetCount && assetCount > 0 && (
          <>
            <Typography
              className="absolute bottom-2 left-4 text-white-100"
              variant="button">{`1/${formatNumberWithCommas(assetCount)}`}</Typography>

            <div
              className="absolute top-0 z-10 items-center justify-center hidden w-full h-full cursor-pointer group-hover:flex bg-slate-500 bg-opacity-60"
              onClick={() => setShowAssetsCarouselModal(true)}>
              <div className="px-5 py-3 border rounded-lg bg-white-100 border-slate-300">
                <Typography
                  className="text-slate-500"
                  variant="button">{`View Gallery (${formatNumberWithCommas(
                  assetCount,
                )})`}</Typography>
              </div>
            </div>
          </>
        )}
      </div>

      {assetCount > 0 && filteredAssetUrls.length && showAssetsCarouselModal && (
        <AssetsCarousel
          assets={filteredAssetUrls}
          onClose={() => setShowAssetsCarouselModal(false)}
        />
      )}
    </>
  );
};

export default ThumbnailWrapper;

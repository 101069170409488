import { ControlledMenu, useHover } from '@szhsin/react-menu';
import cn from 'classnames';
import React, { useRef, useState } from 'react';

import { Icon, IconName } from '@components/Icon';
import { ToggleSwitch } from '@components/Inputs';
import { Typography } from '@components/Typography';
import './../Map.css';

interface DataLayersMenuItemProps {
  showAirports: boolean;
  setShowAirports: (showAirports: boolean) => void;
  showBusStations: boolean;
  setShowBusStations: (showBusStations: boolean) => void;
  showMajorHighways: boolean;
  setShowMajorHighways: (showMajorHighways: boolean) => void;
  showSubwayStations: boolean;
  setShowSubwayStations: (showSubwayStations: boolean) => void;
  showTrainStations: boolean;
  setShowTrainStations: (showTrainStations: boolean) => void;
  showPorts: boolean;
  setShowPorts: (showPorts: boolean) => void;
}

export const DataLayersMenuItem: React.FC<DataLayersMenuItemProps> = ({
  showAirports,
  setShowAirports,
  showBusStations,
  setShowBusStations,
  showMajorHighways,
  setShowMajorHighways,
  showSubwayStations,
  setShowSubwayStations,
  showTrainStations,
  setShowTrainStations,
  showPorts,
  setShowPorts,
}: DataLayersMenuItemProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setIsOpen);

  const menuRef = useRef(null);

  return (
    <>
      <button
        className={cn([
          'mb-0.5 p-2 inline-flex justify-center items-center bg-slate-500 text-cardboard-100 hover:text-slate-500 hover:bg-site-100 first:rounded-tr-md last:rounded-br-md',
          isOpen ? 'bg-site-100 !text-slate-500' : '',
          'margin-bottom-all',
        ])}
        ref={menuRef}
        {...anchorProps}>
        <Icon name={IconName.DATA_LAYERS} classNames="w-[1.2rem]" />
      </button>

      <ControlledMenu
        {...hoverProps}
        anchorRef={menuRef}
        direction="right"
        onClose={() => setIsOpen(false)}
        state={isOpen ? 'open' : 'closed'}
        transition>
        <div className="text-base-white rounded-tr-2xl w-[15rem] overflow-hidden">
          <div className="flex items-center justify-between p-2 bg-slate-500">
            <Typography variant="label-2">Data Layers</Typography>
            <button onClick={() => setIsOpen(false)}>
              <Icon name={IconName.CLOSE} />
            </button>
          </div>

          <div className="flex flex-col space-y-4 p-4 bg-slate-400">
            <ToggleSwitch
              rightLabel="Airports"
              toggled={showAirports}
              onClick={(toggled) => setShowAirports(toggled)}
            />
            <ToggleSwitch
              rightLabel="Bus Stations"
              toggled={showBusStations}
              onClick={(toggled) => setShowBusStations(toggled)}
            />
            <ToggleSwitch
              rightLabel="Major Highways"
              toggled={showMajorHighways}
              onClick={(toggled) => setShowMajorHighways(toggled)}
            />
            <ToggleSwitch
              rightLabel="Subway Stations"
              toggled={showSubwayStations}
              onClick={(toggled) => setShowSubwayStations(toggled)}
            />
            <ToggleSwitch
              rightLabel="Train Stations"
              toggled={showTrainStations}
              onClick={(toggled) => setShowTrainStations(toggled)}
            />
            <ToggleSwitch
              rightLabel="Sea Ports"
              toggled={showPorts}
              onClick={(toggled) => setShowPorts(toggled)}
            />
          </div>
        </div>
      </ControlledMenu>
    </>
  );
};

import { DropdownSelectorOption } from './DropdownSelector.types';

export const ifNextParentIndexHasNoChilds = (
  childs: DropdownSelectorOption[] | undefined,
  focusedChildOptionIndex: string,
  focusedSingleChildOptionIndex: number,
) =>
  childs === undefined ||
  (childs.length > 0 &&
    focusedChildOptionIndex != '-' &&
    focusedSingleChildOptionIndex === childs.length - 1);

export const ifNextParentIndexHasChilds = (
  childs: DropdownSelectorOption[] | undefined,
  focusedChildOptionIndex: string,
) => childs != undefined && childs.length > 0 && focusedChildOptionIndex === '-';

export const ifSameParentIndexHasChildsLeft = (
  childs: DropdownSelectorOption[] | undefined,
  focusedChildOptionIndex: string,
  focusedSingleChildOptionIndex: number,
) =>
  childs != undefined &&
  childs.length > 0 &&
  focusedChildOptionIndex != '-' &&
  focusedSingleChildOptionIndex < childs.length - 1;

export const ifNoPrevParentIndex = (prevOptionIndex: number, focusedChildOptionIndex: string) =>
  prevOptionIndex < 0 && focusedChildOptionIndex === '-';

export const ifLastParentIndexHasChilds = (
  prevOptionChilds: DropdownSelectorOption[] | undefined,
  prevOptionChildLength: number | undefined,
) => prevOptionChilds === undefined || prevOptionChildLength === 0;

export const ifPrevParentIndexhasNoChilds = (childs: DropdownSelectorOption[] | undefined) =>
  childs === undefined;

export const ifPrevParentIndexHasChilds = (
  childs: DropdownSelectorOption[] | undefined,
  focusedChildOptionIndex: string,
) => childs != undefined && focusedChildOptionIndex === '-';

export const ifSameParentIndexHasChilds = (
  childs: DropdownSelectorOption[] | undefined,
  focusedSingleChildOptionIndex: number,
) => childs != undefined && focusedSingleChildOptionIndex > 0;

export const ifSameParentIndexHasNoChildsLeft = (
  childs: DropdownSelectorOption[] | undefined,
  focusedChildOptionIndex: string,
  focusedSingleChildOptionIndex: number,
) => childs != undefined && focusedChildOptionIndex != '-' && focusedSingleChildOptionIndex === 0;

export const ifSameParentIndexWhoHasNoChildsLeftIsFirstIndex = (
  focusedOptionIndex: number,
  focusedChildsParentOptionIndex: number,
  focusedChildOptionIndex: string,
) =>
  focusedOptionIndex === -1 &&
  focusedChildsParentOptionIndex === 0 &&
  focusedChildOptionIndex === '0-0';

export const handleDownConditions = (
  options: DropdownSelectorOption[],
  childs: DropdownSelectorOption[] | undefined,
  stateUtility: {
    focusedChildOptionIndex: string;
    focusedSingleChildOptionIndex: number;
    focusedChildsParentOptionIndex: number;
    setFocusedOptionIndex: (arg0: number) => void;
    setFocusedChildsParentOptionIndex: (arg0: number) => void;
    setFocusedChildOptionIndex: (arg0: string) => void;
  },
) => {
  const {
    focusedChildOptionIndex,
    focusedSingleChildOptionIndex,
    focusedChildsParentOptionIndex,
    setFocusedOptionIndex,
    setFocusedChildsParentOptionIndex,
    setFocusedChildOptionIndex,
  } = stateUtility;
  if (
    ifNextParentIndexHasNoChilds(childs, focusedChildOptionIndex, focusedSingleChildOptionIndex)
  ) {
    //Set to next parent
    const nextOptionIndex = (focusedChildsParentOptionIndex + 1) % options.length;
    setFocusedOptionIndex(nextOptionIndex);
    setFocusedChildsParentOptionIndex(nextOptionIndex);
    setFocusedChildOptionIndex('-');
  } else if (ifNextParentIndexHasChilds(childs, focusedChildOptionIndex)) {
    //Set to next parent's first child
    const nextchildOptionIndex = focusedChildsParentOptionIndex + '-' + '0';
    setFocusedChildOptionIndex(nextchildOptionIndex);
    setFocusedOptionIndex(-1);
  } else if (
    ifSameParentIndexHasChildsLeft(childs, focusedChildOptionIndex, focusedSingleChildOptionIndex)
  ) {
    //Set to same parents next child
    const nextchildOptionIndex =
      focusedChildsParentOptionIndex + '-' + (focusedSingleChildOptionIndex + 1).toString();
    setFocusedChildOptionIndex(nextchildOptionIndex);
    setFocusedOptionIndex(-1);
  }
};

export const handleUpConditions = (
  prevOptionIndex: number,
  options: DropdownSelectorOption[],
  childs: DropdownSelectorOption[] | undefined,
  stateUtility: {
    focusedOptionIndex: number;
    focusedChildOptionIndex: string;
    focusedSingleChildOptionIndex: number;
    focusedChildsParentOptionIndex: number;
    setFocusedOptionIndex: (arg0: number) => void;
    setFocusedChildsParentOptionIndex: (arg0: number) => void;
    setFocusedChildOptionIndex: (arg0: string) => void;
  },
) => {
  const {
    focusedOptionIndex,
    focusedChildOptionIndex,
    focusedSingleChildOptionIndex,
    focusedChildsParentOptionIndex,
    setFocusedOptionIndex,
    setFocusedChildsParentOptionIndex,
    setFocusedChildOptionIndex,
  } = stateUtility;
  if (ifNoPrevParentIndex(prevOptionIndex, focusedChildOptionIndex)) {
    //current index is very first parent.
    const prevOptionIndex = options.length - 1;
    const prevOptionChilds = options[prevOptionIndex].childs;
    const prevOptionChildLength = prevOptionChilds?.length;
    if (ifLastParentIndexHasChilds(prevOptionChilds, prevOptionChildLength)) {
      //is last parent has childs then set to last parents last child.
      setFocusedOptionIndex(prevOptionIndex);
      setFocusedChildsParentOptionIndex(prevOptionIndex);
      setFocusedChildOptionIndex('-');
    } else {
      //is last parent and has no childs then set to last parent.
      setFocusedChildsParentOptionIndex(options.length - 1);
      setFocusedChildOptionIndex(
        (options.length - 1).toString() + '-' + (prevOptionChildLength! - 1).toString(),
      );
      setFocusedOptionIndex(-1);
    }
  } else if (ifPrevParentIndexhasNoChilds(childs)) {
    //set to prev parents.
    setFocusedOptionIndex(prevOptionIndex);
    setFocusedChildsParentOptionIndex(prevOptionIndex);
    setFocusedChildOptionIndex('-');
  } else if (ifPrevParentIndexHasChilds(childs, focusedChildOptionIndex)) {
    //set to prev parents last child.
    setFocusedOptionIndex(-1);
    setFocusedChildsParentOptionIndex(prevOptionIndex);
    setFocusedChildOptionIndex(prevOptionIndex + '-' + (childs!.length - 1).toString());
  } else if (ifSameParentIndexHasChilds(childs, focusedSingleChildOptionIndex)) {
    //set to same parents prev child.
    setFocusedOptionIndex(-1);
    setFocusedChildOptionIndex(
      focusedChildsParentOptionIndex + '-' + (focusedSingleChildOptionIndex - 1).toString(),
    );
  } else if (
    ifSameParentIndexHasNoChildsLeft(childs, focusedChildOptionIndex, focusedSingleChildOptionIndex)
  ) {
    //same parent has no childs left.
    if (
      ifSameParentIndexWhoHasNoChildsLeftIsFirstIndex(
        focusedOptionIndex,
        focusedChildsParentOptionIndex,
        focusedChildOptionIndex,
      )
    ) {
      //same parent who has no childs left is the very first parent.
      setFocusedOptionIndex(0);
      setFocusedChildsParentOptionIndex(0);
      setFocusedChildOptionIndex('-');
    } else {
      //same parent who has no childs left is any other parent that very first then set to same parent and unset child.
      setFocusedOptionIndex(prevOptionIndex + 1);
      setFocusedChildsParentOptionIndex(prevOptionIndex + 1);
      setFocusedChildOptionIndex('-');
    }
  }
};

import { InputProps } from '../inputs.types';

interface CheckboxDefaultProps extends Omit<InputProps, 'onClick' | 'value'> {
  checkedState: CheckboxState;
  key?: number | string;
  label?: string;
}

export interface CheckboxProps extends CheckboxDefaultProps {
  darkMode?: boolean;
  disableIndeterminateOption?: boolean;
  onClick?: (checkedState: CheckboxState, name?: string) => void;
  labelClassNames?: string;
}

export enum CheckboxState {
  CHECKED = 'CHECKED',
  EMPTY = 'EMPTY',
  INDETERMINATE = 'INDETERMINATE',
}

import React, { useState } from 'react';
import cn from 'classnames';

import { Typography } from '@components/Typography';

interface SitePlanImageProps {
  sitePlanUrl: string;
}

export const SitePlanImage: React.FC<SitePlanImageProps> = ({
  sitePlanUrl,
}: SitePlanImageProps) => {
  const [didError, setDidError] = useState(false);

  return (
    <div className={cn([didError ? 'hidden' : 'visible'])}>
      <Typography className="mb-6 font-medium" variant="h5">
        Conceptual Site Plan
      </Typography>

      <img
        className="max-w-full"
        src={sitePlanUrl}
        alt="The conceptual site plan for the listing"
        onError={() => setDidError(true)}
      />
    </div>
  );
};

export default SitePlanImage;

import React from 'react';
import cn from 'classnames';
import { InputProps } from '../inputs.types';
import { Typography } from '@components/Typography';

interface RadioButtonProps extends Omit<InputProps, 'onClick'> {
  onClick?: (option: any) => void;
}

const RadioButton: React.FC<RadioButtonProps> = (props) => {
  const { onClick, key = null, onChange = () => {}, ...rest } = props;
  const { checked, classNames, disabled, id, label, value } = rest;

  const selectedInputClassNames = disabled
    ? 'bg-freight-100/20 !border-freight-100/20 !cursor-not-allowed'
    : 'bg-freight-100 !border-freight-100';

  const inputClassNames = cn([
    'appearance-none bg-clip-content border border-slate-200 cursor-pointer h-5 p-[0.1875rem] rounded-full w-5',
    checked ? selectedInputClassNames : disabled && '!border-cement-200 !cursor-not-allowed',
  ]);

  const labelClassNames = cn(['ml-2 text-sm cursor-pointer', disabled && 'text-cement-200']);

  return (
    <div
      key={key}
      className={cn('flex items-center cursor-pointer', classNames)}
      data-testid="radioButton"
      onClick={() => onClick?.(value)}>
      <div className="flex justify-center">
        <input className={inputClassNames} onChange={onChange} type="radio" {...rest} />
      </div>
      {label && (
        <label className={labelClassNames} htmlFor={id}>
          <Typography variant="body-2">{label}</Typography>
        </label>
      )}
    </div>
  );
};

export default RadioButton;

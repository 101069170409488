import { useEffect, useState } from 'react';

const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState<string>(getDeviceType());

  useEffect(() => {
    function handleWindowResize() {
      setDeviceType(getDeviceType());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function getDeviceType() {
    return window.matchMedia('(pointer: coarse)').matches ? 'touch' : 'click';
  }
  return deviceType;
};

export default useDeviceType;

import React, { useEffect, useState } from 'react';

export type scrollInfoType = {
  hs: boolean;
  vs: boolean;
};

const useScrollbarInfo = (elementId: string, isHorizontalScrollEnabled: boolean) => {
  const [scrollInfo, setScrollInfo] = useState<scrollInfoType>(
    isHorizontalScrollEnabled ? { hs: true, vs: false } : { hs: false, vs: false },
  );

  useEffect(() => {
    getScrollInfoOfAnElement(elementId);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  function handleWindowResize() {
    setScrollInfo(getScrollInfoOfAnElement(elementId));
  }

  function getScrollInfoOfAnElement(id: string): scrollInfoType {
    let div = document.getElementById(id);
    let hs = div?.scrollWidth! > div?.clientWidth!;
    let vs = div?.scrollHeight! > div?.clientHeight!;
    return { hs, vs };
  }

  return scrollInfo;
};

export default useScrollbarInfo;

import React, { useState } from 'react';
import cn from 'classnames';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as Logo } from '@images/link-logo.svg';
import { ReactComponent as LogoMobile } from '@images/link-logo-mobile.svg';
import AuthenticationMenu from './AuthenticationMenu';
import './Header.css';
import { MenuButton } from '@szhsin/react-menu';
import ContactUsModal from '@components/ContactUsModal';
import { Icon, IconName } from '@components/Icon';
import { FEATURE_FLAG_ENABLE_APP_SWITCHER } from '@/config';
import AppSwitcher from './AppSwitcher';

const Header: React.FC = () => {
  const location = useLocation();
  const [showContactUsModal, setShowContactUsModal] = useState(false);
  const isMapPage = location.pathname.includes('map');

  return (
    <nav className="flex items-center justify-between bg-solar-100">
      <Link to="/">
        <Logo className="brand" />
        <LogoMobile className="brand-mobile" />
      </Link>

      <div className="flex items-center h-full nav-right">
        <div className="relative flex items-center h-full nav-items">
          {FEATURE_FLAG_ENABLE_APP_SWITCHER && <AppSwitcher />}
          <Link className={cn([isMapPage && 'pointer-events-none'])} to="/map">
            <Icon classNames="!h-[1.125rem] !w-[1.125rem] !mr-8" name={IconName.MAP_ICON} />
            {isMapPage && (
              <div className="absolute border border-b-[0.1875rem] border-slate-500 mt-1 w-[1.125rem]" />
            )}
          </Link>
          <MenuButton
            onClick={() => {
              setShowContactUsModal(true);
            }}>
            <Icon classNames="!h-[1.125rem] !w-[1.125rem] !mr-8" name={IconName.CONTACT_ICON} />
          </MenuButton>
          {showContactUsModal && (
            <ContactUsModal
              onClose={() => {
                setShowContactUsModal(false);
              }}
            />
          )}
        </div>
        <AuthenticationMenu />
      </div>
    </nav>
  );
};

export default Header;

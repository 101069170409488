import React from 'react';
import { Icon, IconName } from '@components/Icon';
import { MapPinProps } from './MapPin.types';

export const MapPin: React.FC<MapPinProps> = ({
  isActive,
  isHovered,
  isSecondaryHover,
  onClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  const getMapPin = () =>
    isSecondaryHover ? (
      <Icon classNames="!h-[3.8125rem] !w-[2.75rem]" name={IconName.PIN_SECONDARY_HOVER} />
    ) : isActive ? (
      <Icon classNames="!h-[3.8125rem] !w-[2.75rem]" name={IconName.PIN_ACTIVE} />
    ) : isHovered ? (
      <Icon classNames="!h-[3.8125rem] !w-[2.75rem]" name={IconName.PIN_ACTIVE} />
    ) : (
      <Icon classNames="!h-[3.75rem] !w-[2.75rem]" name={IconName.PIN_DEFAULT} />
    );

  return (
    <div
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className="cursor-pointer w-[2.75rem] h-[3.625rem]">
      {getMapPin()}
    </div>
  );
};

export default MapPin;

import { useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { API_LISTINGS_BASE_URL } from '@/config';
import { AppConfig } from '@/types/app-config';

export function getAppConfigs(axios: AxiosInstance) {
  return useQuery([], {
    queryFn: async () => {
      const response = await axios.get<AppConfig[]>(`${API_LISTINGS_BASE_URL}/appconfig/all`);
      return response.data;
    },
    staleTime: 60 * 1000,
  });
}

export function getAppConfigByKey(axios: AxiosInstance, key: string) {
  return useQuery([key], {
    queryFn: async () => {
      const response = await axios.get<AppConfig>(`${API_LISTINGS_BASE_URL}/appconfig/${key}`);

      return response.data;
    },
    enabled: !!key,
    staleTime: 60 * 1000,
  });
}

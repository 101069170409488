export const getCardImagesUrls = (favoriteListings: string[]) => {
  let heroImageUrl: null | string = null;
  const thumbnailImagesUrls: string[] = [];

  for (const listing of favoriteListings) {
    if (!heroImageUrl) {
      heroImageUrl = listing;
    } else if (thumbnailImagesUrls.length < 3) {
      thumbnailImagesUrls.push(listing);
    }

    if (heroImageUrl && thumbnailImagesUrls.length === 3) {
      break;
    }
  }

  return { heroImageUrl, thumbnailImagesUrls };
};

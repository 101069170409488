import cn from 'classnames';
import React from 'react';

import { Icon, IconName } from '@components/Icon';
import { Typography } from '@components/Typography';
import { ConstrainedTypography } from '@components/ConstrainedTypography';

type UnavailableListingCardProps = {
  classNames?: string;
  favoriteName: string;
  favoriteId: string;
  favoriteAddress: string;
  favoriteSq: string;
  thumbnailImageUrl: string | null;
};

const UnavailableListingCard: React.FC<UnavailableListingCardProps> = ({
  classNames,
  favoriteName,
  favoriteAddress,
  favoriteSq,
  thumbnailImageUrl,
}) => {
  return (
    <div
      className={cn([
        'flex h-20 justify-between rounded-lg border-cement-300 border py-2 pl-2 pr-4',
        classNames,
      ])}>
      <div className="flex space-x-4">
        {thumbnailImageUrl ? (
          <div
            className="bg-center bg-cover w-[4rem] h-full"
            style={{ backgroundImage: `url(${thumbnailImageUrl})` }}
          />
        ) : (
          <div className="flex justify-center bg-cement-100 w-[4rem] h-full items-center">
            <Icon classNames="text-cement-300 !w-full" name={IconName.FACILITY} />
          </div>
        )}

        <div className="flex flex-col items-start justify-center h-full text-left">
          <ConstrainedTypography variant="subtitle-1" width="w-[11.75rem] md:w-[16rem]">
            {favoriteName}
          </ConstrainedTypography>
          <Typography variant="body-4" className=" text-cement-400">
            {favoriteAddress}
          </Typography>
          <Typography variant="body-4" className=" text-slate-500">
            {favoriteSq}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default UnavailableListingCard;

import { format, fromUnixTime } from 'date-fns';

import { ActiveFilterQueryParam as QueryParam, Submarket } from '@/types';
import { formatNumberWithCommas } from '@/utilities/textHelpers';
import {
  DatePickerTimeFrames,
  MinMaxRangeDefault as RangeDefault,
  MinMaxRangeType as RangeType,
} from '@components/Inputs';
import { getLabelFromTimeFrameValue } from '@components/Inputs/DatePicker/DatePicker.utils';
import { FilterToStringMap } from './useDelimitedActiveFiltersValues.types';

/**
 * returns an updated active filters map with the range
 * value stringified and formatted based on is it is a
 * single min, max or full range value
 */
export const filterToStringifiedRangeMap =
  (activeFilters: Partial<FilterToStringMap>) =>
  (
    activeFilterKey: keyof FilterToStringMap,
    rangeDescriptor: string,
    rangeType: RangeType,
    value: string,
  ) => {
    if (rangeType === RangeType.MAX) {
      activeFilters[activeFilterKey] =
        activeFilters?.[activeFilterKey]?.replace(
          `${rangeDescriptor} - ${RangeDefault.NO_MAX}`,
          `- ${value} ${rangeDescriptor}`,
        ) || `${RangeDefault.NO_MIN} - ${value} ${rangeDescriptor}`;
    } else {
      activeFilters[activeFilterKey] =
        activeFilters?.[activeFilterKey]?.replace(RangeDefault.NO_MIN, value) ||
        `${value} ${rangeDescriptor} - ${RangeDefault.NO_MAX}`;
    }

    return activeFilters;
  };

/**
 * returns stringified submarket names delimited by commas by
 * accessing the name of each submarket from the provided
 * submarket data via id
 */
export const getDelimitedActiveSubmarketNames = (
  submarketData: Submarket[],
  delimitedActiveSubmarketIds: string,
) => {
  const activeSubmarketIds = delimitedActiveSubmarketIds.split(',');

  const submarketMap = submarketData.reduce((submarketMap, submarket) => {
    submarketMap[submarket.marketId] = submarket.name;
    return submarketMap;
  }, {} as { [marketId: string]: string });

  const activeSubmarketNames = activeSubmarketIds.reduce((activeSubmarketNames, submarketId) => {
    const submarketName = submarketMap[submarketId];
    if (submarketName) {
      activeSubmarketNames.push(submarketName);
    }
    return activeSubmarketNames;
  }, [] as string[]);

  const delimitedSubmarketNames = activeSubmarketNames.join(', ');

  return delimitedSubmarketNames;
};

// returns a mapping of active filters values stringified and formatted
export const getActiveFiltersToStringMap = (
  searchParameters: URLSearchParams,
  marketNames?: string[],
  submarketNames?: string[],
) => {
  const activeFiltersQueryParameters = Array.from(searchParameters.entries());

  return activeFiltersQueryParameters.reduce((activeFilters, [key, value]) => {
    // Listing Group Type needs to be the first filter in the criteria
    activeFilters = { ...activeFilters };
    const updateFilterString = filterToStringifiedRangeMap({ ...activeFilters });
    switch (key) {
      case QueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MAX:
        activeFilters = updateFilterString(
          'availableSqFtRange',
          'Sq Ft',
          RangeType.MAX,
          formatNumberWithCommas(value),
        );
        break;
      case QueryParam.UNIT_SQUARE_FOOTAGE_AVAILABLE_MIN:
        activeFilters = updateFilterString(
          'availableSqFtRange',
          'Sq Ft',
          RangeType.MIN,
          formatNumberWithCommas(value),
        );
        break;
      case QueryParam.CLASSIFICATIONS:
        activeFilters = {
          ...activeFilters,
          classifications: `Class ${value.split(',').join(', ')}`,
        };
        break;
      case QueryParam.CLEAR_HEIGHT_MAX:
        activeFilters = {
          ...activeFilters,
          clearHeight: "Up to 32' Clear Height",
        };
        break;
      case QueryParam.CLEAR_HEIGHT_MIN:
        activeFilters = {
          ...activeFilters,
          clearHeight: `${value}'+ Clear Height`,
        };
        break;
      case QueryParam.DATE_AVAILABLE:
        activeFilters = {
          ...activeFilters,
          dateAvailable: format(fromUnixTime(parseInt(value)), 'MM/dd/yy'),
        };
        break;
      case QueryParam.DATE_TIMEFRAME:
        activeFilters = {
          ...activeFilters,
          dateTimeFrame: getLabelFromTimeFrameValue(value as DatePickerTimeFrames),
        };
        break;
      case QueryParam.LISTING_TYPES:
        activeFilters = {
          ...activeFilters,
          listingTypes: value
            .replace('Operational Industrial', 'Industrial')
            .replace('Link Parks', 'Parks')
            .split(',')
            .join(', '),
        };
        break;
      case QueryParam.ESFR:
        activeFilters = {
          ...activeFilters,
          esfr: 'ESFR Required',
        };
        break;
      case QueryParam.FUND:
        activeFilters = {
          ...activeFilters,
          fund: `Fund ${value}`,
        };
        break;
      case QueryParam.LOADING_DOCKS_MAX:
        activeFilters = updateFilterString(
          'dockDoorsRange',
          'Loading Docks',
          RangeType.MAX,
          formatNumberWithCommas(value),
        );
        break;
      case QueryParam.LOADING_DOCKS_MIN:
        activeFilters = updateFilterString(
          'dockDoorsRange',
          'Loading Docks',
          RangeType.MIN,
          formatNumberWithCommas(value),
        );
        break;
      case QueryParam.MARKET_IDS:
        activeFilters = {
          ...activeFilters,
          market: marketNames?.join(', '),
        };
        break;
      case QueryParam.OUTDOOR_STORAGE:
        activeFilters = {
          ...activeFilters,
          outdoorStorage: 'Outdoor Storage Allowed',
        };
        break;
      case QueryParam.RAIL_SERVED:
        activeFilters = {
          ...activeFilters,
          railServed: 'Rail Service Required',
        };
        break;
      case QueryParam.SEARCHED_LOCATION:
        activeFilters = {
          ...activeFilters,
          searchedLocation: value,
        };
        break;
      case QueryParam.SUBMARKET_IDS:
        activeFilters = {
          ...activeFilters,
          submarkets: submarketNames?.join(', '),
        };
        break;
      case QueryParam.TRAILER_PARKING:
        activeFilters = {
          ...activeFilters,
          trailerParking: 'Trailer Parking Required',
        };
        break;
      default:
        break;
    }

    return activeFilters;
  }, {} as Partial<FilterToStringMap>);
};

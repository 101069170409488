import React, { useMemo } from 'react';

import { useApiClient } from '@/hooks/useApiClient';
import { useMapData } from '@/pages/Map/hooks/useMapData';
import { ActiveFilterQueryParam as QueryParam } from '@/types';
import { SELECT_ALL, SHOWING_ALL_RESULTS } from '@/utilities/constants';
import { CheckboxState } from '@components/Inputs';
import CheckboxDropdown from '@components/Inputs/CheckboxDropdown/CheckboxDropdown';
import { CheckboxDropdownSelectorOption } from '@components/Inputs/CheckboxDropdown/CheckboxDropdown.types';
import { Typography } from '@components/Typography';

interface SubmarketFilterProps {
  className?: string;
}

export const SubmarketFilter: React.FC<SubmarketFilterProps> = ({
  className,
}: SubmarketFilterProps) => {
  const { getSubmarkets } = useApiClient();
  const { marketIds, submarketIds, updateSearchParameters } = useMapData();
  const { data } = getSubmarkets(marketIds);

  const options: CheckboxDropdownSelectorOption[] = useMemo(() => {
    if (!data?.length) {
      return [];
    }

    const options: CheckboxDropdownSelectorOption[] = data
      .map((submarket) => ({
        label: submarket.name === 'Out of Submarket' ? 'Submarket N/A' : submarket.name,
        value: submarket.marketId,
        checkedState: submarketIds.includes(submarket.marketId)
          ? CheckboxState.CHECKED
          : CheckboxState.EMPTY,
        parentLabel: submarket.parentName,
        parentValue: submarket.parentId,
      }))
      .sort((a, b) => a.parentLabel.localeCompare(b.parentLabel) || a.label.localeCompare(b.label));

    const allSelected = options.every((x) => x.checkedState === CheckboxState.CHECKED);

    options.unshift({
      label: SELECT_ALL,
      value: 0,
      checkedState: allSelected ? CheckboxState.CHECKED : CheckboxState.EMPTY,
    });

    return options;
  }, [data]);

  return (
    <div className={className}>
      <Typography className="mb-4 text-white-100" variant="subtitle-1">
        Submarket
      </Typography>

      <CheckboxDropdown
        disabled={marketIds.length === 0}
        onSelect={() => {
          const selectedOptions = options
            .filter((x) => x.label !== SELECT_ALL && x.checkedState === CheckboxState.CHECKED)
            .map((x) => x.value as string);

          const value = selectedOptions.length === 0 ? null : selectedOptions.join(',');
          updateSearchParameters([[QueryParam.SUBMARKET_IDS, value]]);
        }}
        options={options}
        isSubmarket={true}
        placeholder={SHOWING_ALL_RESULTS}
      />
    </div>
  );
};

import React, { useMemo } from 'react';

import {
  DropdownSelector,
  DropdownSelectorOption,
  DropdownSelectorSelectedOption as SelectedOption,
} from '@components/Inputs';
import { Typography } from '@components/Typography';
import { ActiveFilterQueryParam as QueryParam } from '@/types';
import { useMapData } from '@/pages/Map/hooks/useMapData';

const UP_TO = "Up to 32'";

const clearHeightOptions = [
  { label: UP_TO, value: '32' },
  { label: "32'+", value: '32' },
  { label: "36'+", value: '36' },
  { label: "40'+", value: '40' },
];

const ClearHeightFilter: React.FC = () => {
  const { clearHeightRange, updateSearchParameters } = useMapData();

  const selectedOption = useMemo(() => {
    return clearHeightRange.max
      ? clearHeightOptions[0]
      : clearHeightOptions.find(
          (option) => !option.label.includes(UP_TO) && option.value === clearHeightRange.min,
        );
  }, [clearHeightRange.max, clearHeightRange.min]);

  const handleSelect = (selectedOption: SelectedOption) => {
    const option = selectedOption as DropdownSelectorOption;
    const { label, value } = option;

    if (!value) {
      updateSearchParameters([
        [QueryParam.CLEAR_HEIGHT_MAX, null],
        [QueryParam.CLEAR_HEIGHT_MIN, null],
      ]);
    } else if (label.includes(UP_TO)) {
      updateSearchParameters([
        [QueryParam.CLEAR_HEIGHT_MAX, value],
        [QueryParam.CLEAR_HEIGHT_MIN, null],
      ]);
    } else {
      updateSearchParameters([
        [QueryParam.CLEAR_HEIGHT_MAX, null],
        [QueryParam.CLEAR_HEIGHT_MIN, value],
      ]);
    }
  };

  return (
    <div>
      <Typography className="mb-4 text-white-100" variant="subtitle-1">
        Clear Height
      </Typography>

      <DropdownSelector
        classNames="mb-10 justify-between"
        options={clearHeightOptions}
        onSelect={handleSelect}
        placeholder="Select Clear Height"
        selectedOption={selectedOption}
        isValuePill
      />
    </div>
  );
};

export default ClearHeightFilter;

import { TemplateValue } from '@/types/templates';
import React, { Fragment } from 'react';
import ListingInfoElementsWrapper from './ListingDetails.utils';

export interface ListingDetailsContentDesktopProps {
  data: TemplateValue[];
  isBottom: boolean;
}
const ListingDetailsContentDesktop: React.FC<ListingDetailsContentDesktopProps> = ({
  data,
  isBottom,
}) => {
  return (
    <div>
      {data?.map(
        (listing: { listingId: any; templateType: any; children: any; propertyType: string }) => {
          return (
            <Fragment key={`${listing.listingId}-${listing.templateType}`}>
              <ListingInfoElementsWrapper
                listingChildren={listing.children}
                isBottom={isBottom}
                propertyType={listing.propertyType}
              />
            </Fragment>
          );
        },
      )}
    </div>
  );
};

export default ListingDetailsContentDesktop;

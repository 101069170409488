import React from 'react';
import { Icon, IconName } from '@components/Icon';
import { Typography } from '@components/Typography';

const NoMatchingResults: React.FC = () => (
  <div className="search-results-empty">
    <Icon name={IconName.ALERT_CIRCLE} />
    <Typography variant="subtitle-1">No matching results</Typography>
    <Typography variant="body-2">Try expanding your search to see more options</Typography>
  </div>
);

export default NoMatchingResults;

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { useEffect, useState } from 'react';

const ranges = [
  { valueFrom: 5, valueTo: 15, labelFrom: 5, labelTo: 9 },
  { valueFrom: 16, valueTo: 31, labelFrom: 10, labelTo: 24 },
  { valueFrom: 32, valueTo: 47, labelFrom: 25, labelTo: 99 },
  { valueFrom: 48, valueTo: 63, labelFrom: 100, labelTo: 249 },
  { valueFrom: 64, valueTo: 79, labelFrom: 250, labelTo: 499 },
  { valueFrom: 80, valueTo: 100, labelFrom: 500, labelTo: 750 },
];

const marks = [
  {
    value: 5,
    label: '5',
  },
  {
    value: 16,
    label: '10',
  },
  {
    value: 32,
    label: '25',
  },
  {
    value: 48,
    label: '100',
  },
  {
    value: 64,
    label: '250',
  },
  {
    value: 80,
    label: '500',
  },
  {
    value: 100,
    label: '750',
  },
];

type SliderProps = {
  defaultValue: number | undefined;
  onChange: (value: number) => void;
};

export default function DistanceSlide({ defaultValue, onChange }: SliderProps) {
  const [value, setValue] = useState<number>(5);

  useEffect(() => {
    defaultValue && setValue(calculateValueFromLabel(defaultValue));
  }, [defaultValue]);

  const calculateValue = (value: number) => {
    //find range
    let range = null;
    let i = 0;

    while (range === null) {
      if (ranges[i].valueTo >= value) {
        range = ranges[i];
      }
      i++;
    }

    //CALCULATE SCALE
    const valueScale = range.valueTo - range.valueFrom;
    const labelScale = range.labelTo - range.labelFrom;

    return Math.round(((value - range.valueFrom) * labelScale) / valueScale + range.labelFrom);
  };

  const calculateValueFromLabel = (label: number) => {
    //find range
    let range = null;
    let i = 0;

    while (range == null) {
      if (ranges[i].labelTo >= label) {
        range = ranges[i];
      }
      i++;
    }

    //CALCULATE SCALE
    const valueScale = range.valueTo - range.valueFrom;
    const labelScale = range.labelTo - range.labelFrom;

    return ((label - range.labelFrom) * valueScale) / labelScale + range.valueFrom;
  };

  const onChangeSlide = (_: Event, value: number | number[]) => {
    onChange(calculateValue(Number(value)));
  };

  return (
    <Box sx={{ width: 200 }}>
      <Slider
        aria-label="Distance"
        value={value}
        min={5}
        sx={{
          '& .MuiSlider-markLabel': {
            color: '#ccc',
            fontSize: 11,
          },
          '&.MuiSlider-root': {
            color: '#eb6547',
            height: '6px',
          },
          '& .MuiSlider-mark': {
            width: '6px',
            height: '6px',
            borderRadius: '6px',
            backgroundColor: 'white',
          },
          '& .MuiSlider-thumb': {
            width: '16px',
            height: '16px',
            backgroundColor: 'white',
            border: '4px solid #eb6547',
          },
        }}
        marks={marks}
        onChange={onChangeSlide}
      />
    </Box>
  );
}

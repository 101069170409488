import React from 'react';

import { useToast } from '@/hooks/useToast';
import { useUserFavorites } from '@/hooks/useUserFavorites';
import ConfirmationModal from '@components/ConfirmationModal';
import { RemoveFavoritesModalProps } from './RemoveFavoritesModal.types';

export const RemoveFavoritesModal: React.FC<RemoveFavoritesModalProps> = ({
  show,
  favorites,
  onConfirm,
  onClose,
}) => {
  const { removeFavorites } = useUserFavorites();
  const { addToast } = useToast();

  const handleConfirm = async () => {
    await removeFavorites(favorites.map((x) => x.listingId));

    addToast({
      id: `remove-favorites-${new Date().getTime()}`,
      description: 'Favorite(s) Removed Successfully',
      title: 'Success',
      type: 'success',
    });

    onConfirm ? onConfirm() : onClose();
  };

  return (
    <ConfirmationModal
      //classNames="sm:!w-[30.375rem]"
      header="Are you sure?"
      onClose={onClose}
      primaryBtnLabel="Yes, I want to remove"
      primaryBtnOnClick={handleConfirm}
      secondaryBtnLabel="No, I want to cancel"
      secondaryBtnOnClick={onClose}
      show={show}
      subText="Removing these listing(s) from Favorites will also remove them from any saved Collections."
    />
  );
};

export default RemoveFavoritesModal;

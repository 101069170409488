import React, { useEffect, useState } from 'react';

import { FEATURE_FLAG_ENABLE_DISTANCE_FILTER, MAP_STYLES } from '@/config';
import { ActiveFilterQueryParam } from '@/types';
import { Icon, IconName } from '@components/Icon';
import { DataLayersMenuItem } from '@components/Map/DataLayersMenuItem';
import { MapStyleMenuItem } from '@components/Map/MapStyleMenuItem';
import { MapToolbar, MapToolbarItem } from '@components/Map/MapToolbar';
import { useGeolocation } from './hooks/useGeolocation';
import { useMapActions } from './hooks/useMapActions';
import { useMapData } from './hooks/useMapData';
import useIsBigScreen from '@/hooks/useIsBigScreen';
import { TextInput } from '@components/Inputs';
import cn from 'classnames';
import { MapToolbarItemWithInput } from '@components/Map/MapToolbarItemWithInput';
import Button, { ButtonVariant } from '@components/Button';
import DistanceSlide from '@components/Map/MapToolbarItemWithInput/DistanceSlide';

interface ToolbarProps {
  className: string;
  isCollectionMap?: boolean;
}

export const Toolbar: React.FC<ToolbarProps> = ({ className, isCollectionMap }) => {
  const isBigScreen = useIsBigScreen();
  const { currentLocation } = useGeolocation();
  const {
    is3dDisabled,
    is3dActive,
    activate3d,
    deactivate3d,
    flyTo,
    setMapStyle,
    zoomIn,
    zoomOut,
    showAirports,
    setShowAirports,
    showBusStations,
    setShowBusStations,
    showMajorHighways,
    setShowMajorHighways,
    showSubwayStations,
    setShowSubwayStations,
    showTrainStations,
    setShowTrainStations,
    showPorts,
    setShowPorts,
  } = useMapActions();
  const {
    updateSearchParameters,
    selectedOption,
    distanceFilter,
    tempCircle,
    setDistanceFilterTempCircle,
    searchedLocation,
  } = useMapData();
  const { updateCurrentLocation } = useGeolocation();
  const [radiusValue, setRadiusValue] = useState<number | undefined>(distanceFilter?.distance);

  useEffect(() => {
    setRadiusValue(distanceFilter?.distance);
  }, [distanceFilter]);

  const updateRadiusValue = (radius: number | undefined) => {
    setRadiusValue(radius);
    setDistanceFilterTempCircle({
      distance: radiusValue!,
      longitude: selectedOption?.center[1]! ?? currentLocation?.longitude,
      latitude: selectedOption?.center[0]! ?? currentLocation?.longitude,
    });
  };

  const applyRadiusValue = () => {
    setDistanceFilterTempCircle(null);
    const value =
      radiusValue +
      ',' +
      (selectedOption
        ? selectedOption?.center[0] + ',' + selectedOption?.center[1]
        : currentLocation?.longitude + ',' + currentLocation?.latitude);
    updateSearchParameters?.([[ActiveFilterQueryParam.RADIUS, value]]);
  };

  const clearRadiusFilter = () => {
    setDistanceFilterTempCircle(null);
    updateSearchParameters?.([[ActiveFilterQueryParam.RADIUS, '']]);
  };

  const items: MapToolbarItem[] = [
    {
      id: 'live-location-menu-item',
      type: 'simple',
      show: true,
      icon: IconName.LIVE_LOCATION,
      onClick: async () => {
        const currentLocation = await updateCurrentLocation();

        if (!currentLocation) {
          return;
        }

        updateSearchParameters?.([
          [ActiveFilterQueryParam.SEARCHED_LOCATION, null],
          [ActiveFilterQueryParam.MARKET_IDS, null],
          [ActiveFilterQueryParam.SUBMARKET_IDS, null],
          [ActiveFilterQueryParam.INITIAL_VIEW_STATE, null],
        ]);

        const { longitude, latitude } = currentLocation;

        flyTo({ center: [longitude, latitude], zoom: 8 });
      },
    },
    {
      id: 'map-style-menu-item-radius',
      type: 'custom',
      show: isBigScreen,
      element: FEATURE_FLAG_ENABLE_DISTANCE_FILTER ? (
        <MapToolbarItemWithInput
          id={'map-style-menu-item-radius'}
          classNames="w-full"
          icon={IconName.ACTIVE_LOCATION}
          title={'Distance From Pin'}
          showCloseButton={true}
          isButtonDisabled={searchedLocation !== 'Current Location' && selectedOption === null}
          disabledTooltipContent={'Search for an address or a listing'}
          closeOnMouseOut={false}>
          <TextInput
            className="text-input text-base-black max-w-[4.5rem] radius-input"
            placeholder="Distance (Miles)"
            style={{ width: '40px' }}
            type="number"
            label="Distance (Miles)"
            max={750}
            min={0}
            onChange={(e) => {
              updateRadiusValue(e.target.value as unknown as number);
            }}
            value={radiusValue}
          />

          <DistanceSlide defaultValue={radiusValue} onChange={updateRadiusValue} />
          {!tempCircle && distanceFilter && distanceFilter.distance > 0 ? (
            <Button
              buttonClassNames={cn(['w-full !px-4 !h-12'])}
              label={'Reset Distance Filter'}
              Icon={<Icon classNames="p-1" name={IconName.REFRESH} />}
              onClick={clearRadiusFilter}
              variant={ButtonVariant.DEFAULT_OUTLINE_DARK_THEME}
            />
          ) : (
            <Button
              buttonClassNames={cn(['w-full !px-4 !h-12'])}
              disabled={!radiusValue || radiusValue <= 0}
              label="Apply Distance Filter"
              onClick={applyRadiusValue}
              variant={ButtonVariant.PRIMARY_TWO}
            />
          )}
        </MapToolbarItemWithInput>
      ) : (
        <></>
      ),
    },
    {
      id: 'map-style-menu-item',
      type: 'custom',
      show: true,
      element: <MapStyleMenuItem styles={MAP_STYLES} setMapStyle={setMapStyle} />,
    },
    {
      id: 'three-d-menu-item',
      type: 'simple',
      show: true,
      icon: IconName.THREE_D,
      isActive: is3dActive,
      isDisabled: is3dDisabled,
      disabledTooltipContent: 'Click a pin on the map to enable 3-D',
      onClick: () => {
        if (is3dActive) {
          deactivate3d();
        } else {
          activate3d();
        }
      },
    },
  ];

  const ZoomInOutIcons: MapToolbarItem[] = [
    {
      id: 'zoom-in-menu-item',
      type: 'simple',
      show: isBigScreen,
      icon: IconName.ZOOM_IN,
      onClick: zoomIn,
    },
    {
      id: 'zoom-out-menu-item',
      type: 'simple',
      show: isBigScreen,
      icon: IconName.ZOOM_OUT,
      onClick: zoomOut,
    },
  ];

  if (!isCollectionMap) {
    items.splice(2, 0, {
      id: 'data-layers-menu-item',
      type: 'custom',
      show: true,
      element: (
        <DataLayersMenuItem
          showAirports={showAirports}
          setShowAirports={setShowAirports}
          showBusStations={showBusStations}
          setShowBusStations={setShowBusStations}
          showMajorHighways={showMajorHighways}
          setShowMajorHighways={setShowMajorHighways}
          showSubwayStations={showSubwayStations}
          setShowSubwayStations={setShowSubwayStations}
          showTrainStations={showTrainStations}
          setShowTrainStations={setShowTrainStations}
          showPorts={showPorts}
          setShowPorts={setShowPorts}
        />
      ),
    });
  }
  return (
    <div className={className}>
      <MapToolbar items={[...items, ...ZoomInOutIcons]} />
    </div>
  );
};

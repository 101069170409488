import { ControlledMenu, useHover } from '@szhsin/react-menu';
import cn from 'classnames';
import React, { useRef, useState } from 'react';

import { Icon, IconName } from '@components/Icon';
import './../Map.css';
import { Typography } from '@components/Typography';
import { Tooltip } from 'react-tooltip';

interface MapToolbarItemWithInputProps {
  id: string;
  icon: IconName;
  classNames?: string;
  title: string;
  showCloseButton: boolean;
  isButtonDisabled: boolean;
  disabledTooltipContent: string;
  children?: React.ReactNode;
  closeOnMouseOut?: boolean;
}

export const MapToolbarItemWithInput: React.FC<MapToolbarItemWithInputProps> = ({
  id,
  classNames,
  icon,
  title,
  showCloseButton,
  isButtonDisabled,
  disabledTooltipContent,
  children,
  closeOnMouseOut = true,
}: MapToolbarItemWithInputProps) => {
  const [isOpen, setIsOpen] = useState(closeOnMouseOut ? false : true);

  console.log(isOpen);
  const { anchorProps, hoverProps } = useHover(isOpen, setIsOpen);

  const menuRef = useRef(null);

  return (
    <div className={cn(['relative', classNames])}>
      <button
        className={cn([
          'w-full mb-0.5 p-2 flex justify-center items-center bg-slate-500 text-cardboard-100 hover:text-slate-500 hover:bg-site-100',
          isOpen ? 'bg-site-100 !text-slate-500' : '',
          'margin-bottom-all',
        ])}
        ref={menuRef}
        // onClick={() => closeOnMouseOut && setIsOpen(!isOpen)}
        {...anchorProps}>
        <Icon name={icon} classNames="w-[1.2rem]" />
      </button>
      {isButtonDisabled && disabledTooltipContent && (
        <>
          <div
            className="absolute top-0 left-0 w-full h-full opacity-50 bg-base-white"
            data-tooltip-id={`${id}-tooltip`}
            data-tooltip-target="tooltip-default"></div>
          <Tooltip id={`${id}-tooltip`} place="right">
            <Typography variant="body-4">{disabledTooltipContent}</Typography>
          </Tooltip>
        </>
      )}
      <ControlledMenu
        {...hoverProps}
        anchorRef={menuRef}
        direction="right"
        onClose={() => closeOnMouseOut && setIsOpen(false)}
        state={isOpen ? 'open' : 'closed'}
        transition>
        <div className="text-base-white rounded-tr-2xl w-[15rem] overflow-hidden">
          <div className="flex items-center justify-between p-2 bg-slate-500">
            <Typography variant="label-2">{title}</Typography>
            {showCloseButton && (
              <button onClick={() => setIsOpen(false)}>
                <Icon name={IconName.CLOSE} />
              </button>
            )}
          </div>

          <div className="flex flex-col space-y-4 p-4 bg-slate-400">{children}</div>
        </div>
      </ControlledMenu>
    </div>
  );
};

import { FixedSizeList as List } from 'react-window';
import { Listing } from '@/types';

export const formatListingId = (id: string) => `property-${id}`;

export const scrollToSelectedListing = (
  listings: Listing[],
  selectedListing: Listing | null,
  listRef: React.RefObject<List<Listing[]>>,
) => {
  const selectedPropertyIndex = listings.findIndex(
    (property) => property.id === selectedListing?.id,
  );
  listRef?.current?.scrollToItem(selectedPropertyIndex, 'start');
};

export const sortNumericValues = (a: any, b: any) => {
  if (typeof a !== 'number' || isNaN(a)) {
    return 1;
  } else if (typeof b !== 'number' || isNaN(b)) {
    return -1;
  } else {
    return a - b;
  }
};

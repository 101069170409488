import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import { PredictiveSearch } from '@components/Inputs';
import { Typography } from '@components/Typography';
import {
  MinMaxRangeDefault as RangeDefault,
  MinMaxRangeType,
  MinMaxSelectorProps,
} from './MinMaxSelector.types';

const MinMaxSelector: React.FC<MinMaxSelectorProps> = ({
  classNames,
  clearFilters,
  customOnBlurCallback,
  label,
  onSelect,
  optionIdentifierSubtext,
  range: { max, min },
  maxWidth,
}) => {
  const [isMaxInputFocused, setIsMaxInputFocused] = useState(false);
  const [isMinInputFocused, setIsMinInputFocused] = useState(false);
  const [maxInputValue, setMaxInputValue] = useState(max);
  const [maxSearchResults] = useState<string[]>([]);
  const [minInputValue, setMinInputValue] = useState(min);
  const [minSearchResults] = useState<string[]>([]);
  const [rangeError, setRangeError] = useState(false);

  return (
    <div className={cn([classNames])}>
      {label && (
        <Typography className="mb-4 text-white-100" variant="subtitle-1">
          {label}
        </Typography>
      )}

      <div className="flex items-start">
        <PredictiveSearch
          classNames="w-full"
          clearFilters={clearFilters}
          customErrorMessage="Invalid range"
          customHasError={rangeError}
          customInputValue={minInputValue}
          customIsInputFocused={isMinInputFocused}
          customOnBlurCallback={customOnBlurCallback}
          customSetErrorCallback={(hasError) => setRangeError(hasError)}
          customSetInputValue={setMinInputValue}
          customSetIsInputFocused={setIsMinInputFocused}
          data={minSearchResults}
          displayResultsWhenFocused
          hideChevron
          isMinMaxSelector
          maxWidth={maxWidth}
          minMaxDefaultValue={RangeDefault.NO_MIN}
          name={MinMaxRangeType.MIN}
          onSelect={onSelect}
          oppositeIsInputFocused={isMaxInputFocused}
          oppositeRangeInputValue={maxInputValue}
          optionIdentifierSubtext={optionIdentifierSubtext}
          value={min}
        />

        <div className="flex h-[3.1875rem] items-center">
          <div className="w-3 h-0 mx-4 border range-divider"></div>
        </div>

        <PredictiveSearch
          classNames="w-full"
          clearFilters={clearFilters}
          customErrorMessage="Invalid range"
          customHasError={rangeError}
          customInputValue={maxInputValue}
          customIsInputFocused={isMaxInputFocused}
          customOnBlurCallback={customOnBlurCallback}
          customSetErrorCallback={(hasError) => setRangeError(hasError)}
          customSetInputValue={setMaxInputValue}
          customSetIsInputFocused={setIsMaxInputFocused}
          data={maxSearchResults}
          displayResultsWhenFocused
          hideChevron
          isMinMaxSelector
          maxWidth={maxWidth}
          minMaxDefaultValue={RangeDefault.NO_MAX}
          name={MinMaxRangeType.MAX}
          onSelect={onSelect}
          oppositeIsInputFocused={isMinInputFocused}
          oppositeRangeInputValue={minInputValue}
          optionIdentifierSubtext={optionIdentifierSubtext}
          value={max}
        />
      </div>
    </div>
  );
};

export default MinMaxSelector;

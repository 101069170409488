import React from 'react';

import ellipse from '@images/loading-spinner/loading-spinner-ellipse.png';
import icon from '@images/loading-spinner/loading-spinner-icon.png';
import './LoadingSpinner.css';

const LoadingSpinner: React.FC = () => {
  return (
    <div className="flex h-[4.5rem] items-center justify-center relative w-[4.5rem]">
      <img className="loading-spinner-ellipse" src={ellipse} />
      <img className="loading-spinner-icon absolute" src={icon} />
    </div>
  );
};

export default LoadingSpinner;

import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import { API_USERS_BASE_URL } from '@/config';
import { Favorite } from '@/types';

export function getFavorites(axios: AxiosInstance, isEnabled: boolean) {
  return useQuery({
    queryKey: ['favorites'],
    queryFn: async () => {
      const response = await axios.get<Favorite[]>(`${API_USERS_BASE_URL}/favorites`);
      return response.data;
    },
    enabled: isEnabled,
  });
}

type AddFavoriteVariables = {
  listingId: string;
};

export function addFavorite(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({ listingId }: AddFavoriteVariables) => {
      const response = await axios.post<Favorite>(`${API_USERS_BASE_URL}/favorites`, {
        listingId,
      });

      return response.data;
    },
  });
}

type RemoveFavoritesVariables = {
  listingIds: string[];
};

export function removeFavorites(axios: AxiosInstance) {
  return useMutation({
    mutationFn: async ({ listingIds }: RemoveFavoritesVariables) => {
      const response = await axios.delete(`${API_USERS_BASE_URL}/favorites`, {
        data: {
          listingIds,
        },
      });

      return response.data;
    },
  });
}

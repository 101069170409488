import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Header from '@components/Header';
import Toaster from '@components/Toaster/Toaster';
import { Bookmarks } from './pages/Bookmarks';
import { CollectionDetails } from './pages/CollectionDetails';
import { Collections } from './pages/Collections';
import { Favorites } from './pages/Favorites';
import LandingPage from './pages/LandingPage/LandingPage';
import { ListingDetails } from './pages/ListingDetails';
import { MapComponent } from './pages/Map';
import { NotFound } from './pages/NotFound';
import { SavedSearches } from './pages/SavedSearches';
import { Authentication } from './utilities/Authentication';
import { Providers } from './utilities/Providers';
import ScrollToTop from '@components/ScrollToTop';
import { MapProviders } from './pages/Map/MapProviders';

const App: React.FC<any> = () => {
  return (
    <>
      <UnauthenticatedTemplate>
        <Authentication />
      </UnauthenticatedTemplate>

      <AuthenticatedTemplate>
        <Providers>
          <MapProviders>
            <Header />
            <ScrollToTop>
              <Routes>
                <Route index element={<LandingPage />} />
                <Route path="/bookmarks" element={<Bookmarks />}>
                  <Route path="favorites" element={<Favorites />} />
                  <Route path="collections" element={<Collections />} />
                  <Route path="collections/:id" element={<CollectionDetails />} />
                  <Route path="saved-searches" element={<SavedSearches />} />
                </Route>
                <Route path="/map" element={<MapComponent />} />
                <Route path="/listing/:id" element={<ListingDetails />} />
                <Route path="/not-found" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </ScrollToTop>
            <Toaster />
          </MapProviders>
        </Providers>
      </AuthenticatedTemplate>
    </>
  );
};

export default App;

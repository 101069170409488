import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { ImageSlideProps } from './AssetsCarousel.types';

const ImageSlide: React.FC<ImageSlideProps> = ({
  activeSlideIndex,
  imageUrl,
  index,
  onGettingCurrentZoomLevel,
  onSettingCurrentSlideControlsRef,
}) => {
  return (
    <TransformWrapper
      onTransformed={onGettingCurrentZoomLevel}
      onWheel={onGettingCurrentZoomLevel}
      onPanning={onGettingCurrentZoomLevel}
      onPanningStart={() => {
        const imageSlideWrapper = document.getElementsByClassName('image-slide-wrapper')[0];
        imageSlideWrapper.classList.add('!cursor-grabbing');
      }}
      onPanningStop={() => {
        const imageSlideWrapper = document.getElementsByClassName('image-slide-wrapper')[0];
        imageSlideWrapper.classList.remove('!cursor-grabbing');
      }}
      onZoom={onGettingCurrentZoomLevel}>
      {({ zoomIn, zoomOut, resetTransform }) => {
        /**
         * Each image slide has its own reset and zoom controls
         * By default the global zoom buttons defined outside the Swiper component do not have access to the active image slide's
         * zoom controls. Thus, a global ref is required to store the active image slide's
         * reset and zoom controls to interactive with the active slide while avoiding interaction with controls for non-active slides'
         * hidden in the overflow.
         * Navigating to a new slide requires resetting the zoom level of the previously active image slide, thus the aforementioned
         * is required.
         * (Note) the global ref is interchangeable with video controls
         */
        index === activeSlideIndex &&
          onSettingCurrentSlideControlsRef({
            zoomIn,
            zoomOut,
            resetTransform,
          });

        return (
          <TransformComponent wrapperClass="cursor-grab !h-full image-slide-wrapper !w-full">
            <div
              className="w-full h-full bg-center bg-cover rounded"
              style={{ backgroundImage: `url(${imageUrl})` }}
            />
          </TransformComponent>
        );
      }}
    </TransformWrapper>
  );
};

export default ImageSlide;

import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import {
  Checkbox,
  CheckboxState,
  DropdownSelector,
  DropdownSelectorColor,
} from '@components/Inputs';
import './MultipleLevelCheckboxDropdown.css';
import {
  MultipleLevelCheckboxDropdownProps,
  MultipleLevelCheckboxDropdownSelectorOption,
  MultipleLevelCheckboxDropdownSelectorRenderOptionFunc,
} from './MultipleLevelCheckboxDropdown.types';
import { handleChildOption, handleParentOption, updateSelectedLabelsText } from './utils';

const MultipleLevelMultipleLevelCheckboxDropdown: React.FC<MultipleLevelCheckboxDropdownProps> = ({
  classNames,
  disabled,
  options,
  onSelect,
  placeholder,
  isSubmarket,
}) => {
  const [dropdownOptions, setDropdownOptions] =
    useState<MultipleLevelCheckboxDropdownSelectorOption[]>(options);
  const [selectedSubmarketPills, setSelectedSubmarketPills] = useState<JSX.Element[] | string>('');

  // Synchronize value changes
  useEffect(() => {
    setDropdownOptions(options);
    updateSelectedLabelsText(options, handleClick, onSelect, setSelectedSubmarketPills);
  }, [options]);

  const handleClick = (
    checkedState: CheckboxState,
    label?: string,
    value?: string,
    childs?: MultipleLevelCheckboxDropdownSelectorOption[],
  ) => {
    const updatedDropdownOptions = dropdownOptions;
    if (childs !== undefined) {
      //Parent option clicked.
      handleParentOption(updatedDropdownOptions, childs, checkedState, value);
    } else {
      handleChildOption(updatedDropdownOptions, value);
    }
    setDropdownOptions(updatedDropdownOptions);
    updateSelectedLabelsText(
      updatedDropdownOptions,
      handleClick,
      onSelect,
      setSelectedSubmarketPills,
    );
  };

  const renderOption: MultipleLevelCheckboxDropdownSelectorRenderOptionFunc = (option) => {
    return (
      <Checkbox
        label={option.label}
        checkedState={option.checkedState as CheckboxState}
        onClick={() => {
          !disabled &&
            handleClick(
              option.checkedState as CheckboxState,
              option.label,
              option.value as string,
              option.childs as MultipleLevelCheckboxDropdownSelectorOption[],
            );
        }}
        name={option.label}
        darkMode={true}
      />
    );
  };

  return (
    <div className="MultipleLevelCheckboxDropdown">
      <DropdownSelector
        classNames={cn([
          selectedSubmarketPills.length && '[&_.dropdown-selector-btn]:h-fit',
          classNames,
        ])}
        color={DropdownSelectorColor.WHITE}
        disabled={disabled}
        isCheckboxDropdown
        onSelect={onSelect}
        options={dropdownOptions}
        placeholder={
          selectedSubmarketPills.length ? (
            <div className="w-full relative min-h-[3.0625rem] break-normal flex flex-wrap space-x-2 py-3">
              {selectedSubmarketPills}
            </div>
          ) : (
            placeholder
          )
        }
        renderOption={renderOption}
        selectedOption={undefined}
        isSubmarket={isSubmarket}
      />
    </div>
  );
};

export default MultipleLevelMultipleLevelCheckboxDropdown;

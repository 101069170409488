import { addDays, fromUnixTime } from 'date-fns';
import { LngLatBounds } from 'mapbox-gl';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  FEATURE_FLAG_PAGINATION_CARDS_PER_PAGE,
  FEATURE_FLAG_USE_FRONTEND_FILTERING,
} from '@/config';
import { useApiClient } from '@/hooks/useApiClient';
import {
  DistanceFilter,
  FormattedLocation,
  Listing,
  ActiveFilters as SearchFilters,
  ActiveFilterQueryParam as SearchParameterNames,
} from '@/types';
import { DatePickerTimeFrames, MinMaxRange } from '@components/Inputs';

export interface MapDataContextValue {
  listings: Listing[];
  selectedListing: Listing | null;
  setSelectedListing: (feature: Listing | null) => void;
  selectedOption: FormattedLocation | null;
  setSelectedOption: (feature: FormattedLocation | null) => void;
  isLoading: boolean;
  setIsMoving: (isMoving: boolean) => void;

  showSearchResults: boolean;
  setShowSearchResults: (showSearchResults: boolean) => void;

  viewport: LngLatBounds | null;
  searchedLocation: string;
  setSearchedLocation: (searchedLocation: string) => void;
  dateAvailable: Date | undefined;
  dateTimeFrame: DatePickerTimeFrames | undefined;
  unitSquareFootageAvailableRange: MinMaxRange;
  marketId: string;
  marketIds: string[];
  submarketIds: string[];
  listingTypes: string[];
  clearHeightRange: MinMaxRange;
  loadingDocksRange: MinMaxRange;
  classifications: string[];
  trailerParking: boolean;
  railServed: boolean;
  outdoorStorage: boolean;
  esfr: boolean;
  fund: null | string;
  clearFilters: boolean;
  clearAllFilters: () => void;
  moreFilterCount: number;
  dateAvailableFilterCount: number;
  sqFtAvailableMinFilterCount: number;
  sqFtAvailableMaxFilterCount: number;
  filtersVariables: SearchFilters | null;
  isMapPinFetched: boolean;

  updateSearchParameters: (
    entries: [string, boolean | string | number | null | undefined][],
  ) => void;
  resetDatePicker: () => void;
  updateDateAvailable: (dateTimeAvailable: Date | undefined) => void;
  updateDateTimeFrame: (dateTimeFrame: DatePickerTimeFrames | undefined) => void;

  distanceFilter: DistanceFilter | null;
  tempCircle: DistanceFilter | null;
  setDistanceFilterTempCircle: (filter: DistanceFilter | null) => void;
}

export const MapDataContext = createContext<MapDataContextValue>({} as MapDataContextValue);

type MapDataProviderProps = Omit<React.ProviderProps<MapDataContextValue>, 'value'>;

export const MapDataProvider: React.FC<MapDataProviderProps> = ({
  children,
}: MapDataProviderProps) => {
  const [listings, setListings] = useState<Listing[]>([]);
  const [selectedListing, setSelectedListing] = useState<Listing | null>(null);
  const [selectedOption, setSelectedOption] = useState<FormattedLocation | null>(null);
  const [isMoving, setIsMoving] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [showSearchResults, setShowSearchResults] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const [distanceFilter, setDistanceFilter] = useState<DistanceFilter | null>(null);
  const [tempCircle, setTempCircle] = useState<DistanceFilter | null>(null);

  const [viewport, setViewport] = useState<LngLatBounds | null>(null);
  const [filtersVariables, setFiltersVariables] = useState<SearchFilters | null>(null);
  const [isMapPinFetched, setIsMapPinFetched] = useState<boolean>(false);

  const [searchedLocation, setSearchedLocation] = useState('');
  const [dateAvailable, setDateAvailable] = useState<Date>();
  const [dateTimeFrame, setDateTimeFrame] = useState<DatePickerTimeFrames>();
  const [unitSquareFootageAvailableRange, setUnitSquareFootageAvailableRange] =
    useState<MinMaxRange>({ min: '', max: '' });
  const [marketId] = useState<string>('');
  const [marketIds, setMarketIds] = useState<string[]>([]);
  const [submarketIds, setSubmarketIds] = useState<string[]>([]);
  const [listingTypes, setListingTypes] = useState<string[]>([]);
  const [clearHeightRange, setClearHeightRange] = useState<MinMaxRange>({ min: '', max: '' });
  const [loadingDocksRange, setLoadingDocksRange] = useState<MinMaxRange>({ min: '', max: '' });
  const [classifications, setClassifications] = useState<string[]>([]);
  const [trailerParking, setTrailerParking] = useState<boolean>(false);
  const [railServed, setRailServed] = useState<boolean>(false);
  const [outdoorStorage, setOutdoorStorage] = useState<boolean>(false);
  const [esfr, setEsfr] = useState<boolean>(false);
  const [fund, setFund] = useState<null | string>(null);

  const [moreFilterCount, setMoreFilterCount] = useState(0);
  const [dateAvailableFilterCount, setDateAvailableFilterCount] = useState(0);
  const [sqFtAvailableMinFilterCount, setSqFtAvailableMinFilterCount] = useState(0);
  const [sqFtAvailableMaxFilterCount, setSqFtAvailableMaxFilterCount] = useState(0);
  const [clearFilters, setClearFilters] = useState(false);

  // NOTE - This effect updates the filter state from the URL. We leverage the
  // URL as the source of truth for the map page, since we want to make it easy
  // to share searches with other users.

  useEffect(() => {
    const searchParameters = new URLSearchParams(location.search);
    let moreFilterCount = 0;
    let dateAvailableFilterCount = 0;
    let sqFtAvailableMinFilterCount = 0;
    let sqFtAvailableMaxFilterCount = 0;

    // NOTE - Viewport

    if (searchParameters.has(SearchParameterNames.VIEWPORT)) {
      const value = searchParameters.get(SearchParameterNames.VIEWPORT)!;

      const newViewport = new LngLatBounds(
        value.split(':').map(Number) as [number, number, number, number],
      );

      if (value !== viewport?.toArray().flat().join(':')) {
        setViewport(newViewport);
      }
    } else {
      setViewport(null);
    }

    // NOTE - We consider an active filter to be any non-default value for any
    // filter other than the viewport.

    let isActiveFilter = false;

    // NOTE - Searched Location

    if (searchParameters.has(SearchParameterNames.SEARCHED_LOCATION)) {
      const value = searchParameters.get(SearchParameterNames.SEARCHED_LOCATION)!;
      setSearchedLocation(value);

      isActiveFilter = true;
    } else {
      setSearchedLocation('');
      // Reset distance filter when searched location is cleared
      setDistanceFilter(null);
      setTempCircle(null);
    }

    // NOTE - Date Available

    if (searchParameters.has(SearchParameterNames.DATE_AVAILABLE)) {
      const value = searchParameters.get(SearchParameterNames.DATE_AVAILABLE)!;
      setDateAvailable(fromUnixTime(parseInt(value)));
      setDateTimeFrame(undefined);
      isActiveFilter = true;
      moreFilterCount++;
      dateAvailableFilterCount++;
    } else if (searchParameters.has(SearchParameterNames.DATE_TIMEFRAME)) {
      const value = searchParameters.get(SearchParameterNames.DATE_TIMEFRAME)!;
      setDateTimeFrame(value as DatePickerTimeFrames);
      const newDate = addDays(new Date(), Number(value));
      setDateAvailable(newDate);
      isActiveFilter = true;
      moreFilterCount++;
      dateAvailableFilterCount++;
    } else {
      if (!dateAvailable) setDateAvailable(undefined);
      if (!dateTimeFrame) setDateTimeFrame(undefined);
    }

    // NOTE - Unit Square Footage Available

    if (searchParameters.has(SearchParameterNames.UNIT_SQUARE_FOOTAGE_AVAILABLE_MIN)) {
      const value = searchParameters.get(SearchParameterNames.UNIT_SQUARE_FOOTAGE_AVAILABLE_MIN)!;
      setUnitSquareFootageAvailableRange((previousValue) => ({ ...previousValue, min: value }));
      isActiveFilter = true;
      moreFilterCount++;
      sqFtAvailableMinFilterCount++;
    } else {
      setUnitSquareFootageAvailableRange((previousValue) => ({ ...previousValue, min: '' }));
    }

    if (searchParameters.has(SearchParameterNames.UNIT_SQUARE_FOOTAGE_AVAILABLE_MAX)) {
      const value = searchParameters.get(SearchParameterNames.UNIT_SQUARE_FOOTAGE_AVAILABLE_MAX)!;
      setUnitSquareFootageAvailableRange((previousValue) => ({ ...previousValue, max: value }));
      isActiveFilter = true;
      moreFilterCount++;
      sqFtAvailableMaxFilterCount++;
    } else {
      setUnitSquareFootageAvailableRange((previousValue) => ({ ...previousValue, max: '' }));
    }

    // NOTE - Markets
    if (searchParameters.has(SearchParameterNames.MARKET_IDS)) {
      const value = searchParameters.get(SearchParameterNames.MARKET_IDS)!;
      const newMarketIds = value.split(',');
      if (value !== marketIds.join(',')) {
        setMarketIds(newMarketIds);
      }
      isActiveFilter = true;
      moreFilterCount++;
    } else {
      if (marketIds.length > 0) {
        setMarketIds([]);
      }
    }

    // NOTE - Submarket(s)

    if (searchParameters.has(SearchParameterNames.SUBMARKET_IDS)) {
      const value = searchParameters.get(SearchParameterNames.SUBMARKET_IDS)!;

      const newSubmarketIds = value.split(',');

      if (value !== submarketIds.join(',')) {
        setSubmarketIds(newSubmarketIds);
      }

      isActiveFilter = true;
      moreFilterCount++;
    } else {
      if (submarketIds.length > 0) {
        setSubmarketIds([]);
      }
    }

    // NOTE - Listing Types

    if (searchParameters.has(SearchParameterNames.LISTING_TYPES)) {
      const value = searchParameters.get(SearchParameterNames.LISTING_TYPES)!;

      const newListingTypes = value.split(',');

      if (value !== listingTypes.join(',')) {
        setListingTypes(newListingTypes);
      }

      isActiveFilter = true;
      moreFilterCount++;
    } else {
      if (listingTypes.length > 0) {
        setListingTypes([]);
      }
    }

    // NOTE - Clear Height

    if (searchParameters.has(SearchParameterNames.CLEAR_HEIGHT_MIN)) {
      const value = searchParameters.get(SearchParameterNames.CLEAR_HEIGHT_MIN)!;
      setClearHeightRange((previousValue) => ({ ...previousValue, min: value }));

      isActiveFilter = true;
      moreFilterCount++;
    } else {
      setClearHeightRange((previousValue) => ({ ...previousValue, min: '' }));
    }

    if (searchParameters.has(SearchParameterNames.CLEAR_HEIGHT_MAX)) {
      const value = searchParameters.get(SearchParameterNames.CLEAR_HEIGHT_MAX)!;
      setClearHeightRange((previousValue) => ({ ...previousValue, max: value }));

      isActiveFilter = true;
      moreFilterCount++;
    } else {
      setClearHeightRange((previousValue) => ({ ...previousValue, max: '' }));
    }

    // NOTE - Loading Docks

    if (searchParameters.has(SearchParameterNames.LOADING_DOCKS_MIN)) {
      const value = searchParameters.get(SearchParameterNames.LOADING_DOCKS_MIN)!;
      setLoadingDocksRange((previousValue) => ({ ...previousValue, min: value }));

      isActiveFilter = true;
      moreFilterCount++;
    } else {
      setLoadingDocksRange((previousValue) => ({ ...previousValue, min: '' }));
    }

    if (searchParameters.has(SearchParameterNames.LOADING_DOCKS_MAX)) {
      const value = searchParameters.get(SearchParameterNames.LOADING_DOCKS_MAX)!;
      setLoadingDocksRange((previousValue) => ({ ...previousValue, max: value }));

      isActiveFilter = true;
      moreFilterCount++;
    } else {
      setLoadingDocksRange((previousValue) => ({ ...previousValue, max: '' }));
    }

    // NOTE - Classifications

    if (searchParameters.has(SearchParameterNames.CLASSIFICATIONS)) {
      const value = searchParameters.get(SearchParameterNames.CLASSIFICATIONS)!;

      const classes = value.split(',');

      if (value !== classifications.join(',')) {
        setClassifications(classes);
      }

      isActiveFilter = true;
      moreFilterCount++;
    } else {
      if (classifications.length > 0) {
        setClassifications([]);
      }
    }

    // NOTE - Trailer Parking Required

    if (searchParameters.has(SearchParameterNames.TRAILER_PARKING)) {
      setTrailerParking(true);

      isActiveFilter = true;
      moreFilterCount++;
    } else {
      setTrailerParking(false);
    }

    // NOTE - Rail Service Required

    if (searchParameters.has(SearchParameterNames.RAIL_SERVED)) {
      setRailServed(true);

      isActiveFilter = true;
      moreFilterCount++;
    } else {
      setRailServed(false);
    }

    // NOTE - Outdoor Storage Allowed

    if (searchParameters.has(SearchParameterNames.OUTDOOR_STORAGE)) {
      setOutdoorStorage(true);

      isActiveFilter = true;
      moreFilterCount++;
    } else {
      setOutdoorStorage(false);
    }

    // NOTE - ESFR Required

    if (searchParameters.has(SearchParameterNames.ESFR)) {
      setEsfr(true);

      isActiveFilter = true;
      moreFilterCount++;
    } else {
      setEsfr(false);
    }

    // NOTE - Fund

    if (searchParameters.has(SearchParameterNames.FUND)) {
      const value = searchParameters.get(SearchParameterNames.FUND)!;
      setFund(value);

      isActiveFilter = true;
      moreFilterCount++;
    } else {
      setFund(null);
    }

    // NOTE - Radius filter

    if (searchParameters.has(SearchParameterNames.RADIUS)) {
      const value = searchParameters.get(SearchParameterNames.RADIUS)!;
      const arr = value?.split(',');
      if (arr?.length > 2) {
        const radius = arr[0] as unknown as number;
        const lng = arr[1] as unknown as number;
        const lat = arr[2] as unknown as number;
        const state = {
          longitude: lng,
          latitude: lat,
          distance: radius,
        } as unknown as DistanceFilter;
        setDistanceFilter(state);
      } else {
        setDistanceFilter(null);
      }
    } else {
      setDistanceFilter(null);
    }

    // NOTE - If an active filter is applied, then we should show the search
    // results panel.

    if (isActiveFilter) {
      setShowSearchResults(true);
    }

    setMoreFilterCount(moreFilterCount);
    setDateAvailableFilterCount(dateAvailableFilterCount);
    setSqFtAvailableMinFilterCount(sqFtAvailableMinFilterCount);
    setSqFtAvailableMaxFilterCount(sqFtAvailableMaxFilterCount);
  }, [location.search]);

  // NOTE - This memo prepares the filters we provide to the backend API call.

  const searchFilters = useMemo<SearchFilters | null>(() => {
    // NOTE - If we have enabled frontend filtering, then the only filters we
    // apply are the bounds of the US. This will return all listings on the
    // initial request and filter them accordingly. No further requests will be
    // made due to a stable react-query key.

    if (FEATURE_FLAG_USE_FRONTEND_FILTERING) {
      return {
        minLatitude: 20,
        maxLatitude: 50,
        minLongitude: -125,
        maxLongitude: -65,
        pageNumber: 1,
        pageSize: FEATURE_FLAG_PAGINATION_CARDS_PER_PAGE,
      };
    }

    // NOTE - The viewport will not be available until the map finishes its
    // initial load. We return null here to ensure that we don't make a request
    // until the viewport is available.

    if (!viewport) {
      return null;
    }

    let filter = {
      minLatitude: viewport.getSouth(),
      maxLatitude: viewport.getNorth(),
      minLongitude: viewport.getWest(),
      maxLongitude: viewport.getEast(),
      dateAvailable: dateAvailable === null ? undefined : dateAvailable,
      minUnitSquareFootageAvailable:
        unitSquareFootageAvailableRange.min === ''
          ? undefined
          : Number(unitSquareFootageAvailableRange.min),
      maxUnitSquareFootageAvailable:
        unitSquareFootageAvailableRange.max === ''
          ? undefined
          : Number(unitSquareFootageAvailableRange.max),
      marketId: marketId === '' ? undefined : marketId,
      marketIds: marketIds.length === 0 ? undefined : marketIds,
      submarketIds: submarketIds.length === 0 ? undefined : submarketIds,
      listingTypes: listingTypes.length === 0 ? undefined : listingTypes,
      minClearHeight: clearHeightRange.min === '' ? undefined : Number(clearHeightRange.min),
      maxClearHeight: clearHeightRange.max === '' ? undefined : Number(clearHeightRange.max),
      minUnitDockDoors: loadingDocksRange.min === '' ? undefined : Number(loadingDocksRange.min),
      maxUnitDockDoors: loadingDocksRange.max === '' ? undefined : Number(loadingDocksRange.max),
      classifications: classifications.length === 0 ? undefined : classifications,
      trailerParking: trailerParking ? true : undefined,
      railServed: railServed ? true : undefined,
      outdoorStorage: outdoorStorage ? true : undefined,
      esfr: esfr ? true : undefined,
      fund: fund === null ? undefined : fund,
      geoSpatialSearchParameter:
        distanceFilter === null ||
        !distanceFilter.latitude ||
        !distanceFilter.longitude ||
        !distanceFilter.distance
          ? undefined
          : { ...distanceFilter, distance: distanceFilter.distance * 1.6093 }, // Need to convert Miles to KM for the API since Azure Search expects KM.
      isGeoSpatialQuery:
        distanceFilter === null ||
        !distanceFilter.latitude ||
        !distanceFilter.longitude ||
        !distanceFilter.distance
          ? false
          : true,
    };
    return filter;
  }, [
    viewport,
    dateAvailable,
    dateTimeFrame,
    unitSquareFootageAvailableRange.min,
    unitSquareFootageAvailableRange.max,
    marketId,
    marketIds,
    submarketIds,
    listingTypes,
    clearHeightRange.min,
    clearHeightRange.max,
    loadingDocksRange.min,
    loadingDocksRange.max,
    classifications,
    trailerParking,
    railServed,
    outdoorStorage,
    esfr,
    fund,
    distanceFilter,
  ]);

  const setDistanceFilterTempCircle = (filter: DistanceFilter | null) => {
    setTempCircle(filter);
  };

  useEffect(() => {
    if (clearFilters) {
      setClearFilters(false);
    }
  }, [clearFilters]);

  const clearAllFilters = () => {
    setClearFilters(true);
    clearSearchParameters();
    setShowSearchResults(false);
    setSelectedListing(null);
    setSelectedOption(null);
    setSqFtAvailableMinFilterCount(0);
    setSqFtAvailableMaxFilterCount(0);
    setDateAvailableFilterCount(0);
    resetDatePicker();
  };

  const resetDatePicker = () => {
    setDateAvailable(undefined);
    setDateTimeFrame(undefined);
  };

  const updateDateAvailable = (dateAvailable: Date | undefined) => {
    setDateAvailable(dateAvailable);
  };
  const updateDateTimeFrame = (dateTimeFrame: DatePickerTimeFrames | undefined) => {
    setDateTimeFrame(dateTimeFrame);
  };

  const updateSearchParameters = (
    entries: [string, boolean | string | number | null | undefined][],
  ) => {
    const queryParameters = new URLSearchParams(location.search);

    for (const [key, value] of entries) {
      if (value) {
        queryParameters.set(key, value.toString());
      } else {
        queryParameters.delete(key);
      }
    }

    navigate({ search: queryParameters.toString() }, { replace: true });
  };

  const clearSearchParameters = () => {
    const searchParameters = new URLSearchParams(location.search);
    for (const name of Array.from(searchParameters.keys())) {
      if (name === SearchParameterNames.VIEWPORT) {
        continue;
      }

      searchParameters.delete(name);
    }
    navigate({ search: searchParameters.toString() }, { replace: true });
  };

  useEffect(() => {
    setFiltersVariables(searchFilters);
  }, [searchFilters]);

  const { searchListings } = useApiClient();
  let { data, isLoading: isQuerying } = searchListings(searchFilters);

  const isLoading = useMemo(
    () => isMoving || isQuerying || isFiltering,
    [isMoving, isQuerying, isFiltering],
  );

  useEffect(() => {
    setIsMapPinFetched(true);
  }, [data]);

  useEffect(() => {
    setIsFiltering(true);

    if (!data) {
      setIsFiltering(false);
      return;
    }

    let listings = data.features;

    if (FEATURE_FLAG_USE_FRONTEND_FILTERING) {
      listings = data.features.filter((feature) => {
        // filter all properties by location, market, submarket, building status and unit square footage available
        // Build to Suit properties ignored by other filters

        // Viewport

        if (viewport) {
          const [lon, lat] = feature.geometry.coordinates;

          if (
            lat < viewport.getSouth() ||
            lat > viewport.getNorth() ||
            lon < viewport.getWest() ||
            lon > viewport.getEast()
          ) {
            return false;
          }
        }

        // Market

        if (marketId !== '' && feature.properties.market_id !== marketId) {
          return false;
        }

        // Markets

        if (marketIds.length > 0 && !marketIds.includes(feature.properties.market_id)) {
          return false;
        }

        // Submarket(s)

        if (submarketIds.length > 0 && !submarketIds.includes(feature.properties.submarket_id)) {
          return false;
        }

        // Building Statuses

        if (
          listingTypes.length > 0 &&
          (!feature.properties.building_status ||
            !listingTypes.includes(toListingStatus(feature.properties.building_status)))
        ) {
          return false;
        }

        // Unit Square Footage Available

        if (
          unitSquareFootageAvailableRange.min !== '' &&
          (feature.properties.unit_min_square_footage_available === null ||
            feature.properties.unit_min_square_footage_available <
              Number(unitSquareFootageAvailableRange.min))
        ) {
          return false;
        }

        if (
          unitSquareFootageAvailableRange.max !== '' &&
          (feature.properties.unit_max_square_footage_available === null ||
            feature.properties.unit_max_square_footage_available >
              Number(unitSquareFootageAvailableRange.max))
        ) {
          return false;
        }

        if (feature.properties.building_status !== 'BUILD_TO_SUIT') {
          // Date Available

          if (
            dateAvailable &&
            (!feature.properties.date_available ||
              new Date(feature.properties.date_available) > dateAvailable)
          ) {
            return false;
          }

          // Clear Height

          if (
            clearHeightRange.min !== '' &&
            (feature.properties.clear_height === null ||
              feature.properties.clear_height < Number(clearHeightRange.min))
          ) {
            return false;
          }

          if (
            clearHeightRange.max !== '' &&
            (feature.properties.clear_height === null ||
              feature.properties.clear_height > Number(clearHeightRange.max))
          ) {
            return false;
          }

          // Loading Docks

          if (
            loadingDocksRange.min !== '' &&
            (feature.properties.unit_dock_doors === null ||
              feature.properties.unit_dock_doors < Number(loadingDocksRange.min))
          ) {
            return false;
          }

          if (
            loadingDocksRange.max !== '' &&
            (feature.properties.unit_dock_doors === null ||
              feature.properties.unit_dock_doors > Number(loadingDocksRange.max))
          ) {
            return false;
          }

          // Classifications

          if (
            classifications.length > 0 &&
            (!feature.properties.classification ||
              !classifications.includes(feature.properties.classification))
          ) {
            return false;
          }

          // Trailer Parking Required

          if (trailerParking && !feature.properties.trailer_parking) {
            return false;
          }

          // Rail Service Required

          if (railServed && !feature.properties.rail_served) {
            return false;
          }

          // Outdoor Storage Allowed

          if (outdoorStorage && !feature.properties.outdoor_storage) {
            return false;
          }

          // ESFR Required

          if (esfr && !feature.properties.esfr) {
            return false;
          }

          // Fund

          if (fund && feature.properties.fund !== fund) {
            return false;
          }
        }

        return true;
      });
    }

    setListings(listings);

    setIsFiltering(false);
  }, [
    data,
    viewport,
    dateAvailable,
    unitSquareFootageAvailableRange.min,
    unitSquareFootageAvailableRange.max,
    marketId,
    marketIds,
    submarketIds,
    listingTypes,
    clearHeightRange.min,
    clearHeightRange.max,
    loadingDocksRange.min,
    loadingDocksRange.max,
    classifications,
    trailerParking,
    railServed,
    outdoorStorage,
    esfr,
    fund,
  ]);

  function toListingStatus(value: string) {
    switch (value) {
      case 'BUILD_TO_SUIT': {
        return 'Build to Suit Opportunities';
      }

      case 'UNDER_CONSTRUCTION': {
        return 'Under Construction';
      }

      case 'NEW_SPECULATIVE_DEVELOPMENT': {
        return 'New Class A Developments';
      }

      case 'OPERATIONAL': {
        return '2nd Generation';
      }

      default: {
        return '';
      }
    }
  }

  const contextValue: MapDataContextValue = {
    listings,
    selectedListing,
    setSelectedListing,
    selectedOption,
    setSelectedOption,
    isLoading,
    setIsMoving,

    showSearchResults,
    setShowSearchResults,

    viewport,
    searchedLocation,
    setSearchedLocation,
    dateAvailable,
    dateTimeFrame,
    unitSquareFootageAvailableRange,
    marketId,
    marketIds,
    submarketIds,
    listingTypes,
    clearHeightRange,
    loadingDocksRange,
    classifications,
    trailerParking,
    railServed,
    outdoorStorage,
    esfr,
    fund,
    moreFilterCount,
    dateAvailableFilterCount,
    sqFtAvailableMinFilterCount,
    sqFtAvailableMaxFilterCount,
    clearFilters,
    clearAllFilters,
    filtersVariables,
    isMapPinFetched,
    updateSearchParameters,
    resetDatePicker,
    updateDateAvailable,
    updateDateTimeFrame,
    distanceFilter,
    tempCircle,
    setDistanceFilterTempCircle,
  };

  return <MapDataContext.Provider value={contextValue}>{children}</MapDataContext.Provider>;
};

import { TemplateTypes, TemplateValue } from '@/types';
import Button, { ButtonVariant } from '@components/Button';
import ContactBroker from '@components/ContactBroker';
import PrimaryInfo from '@components/PrimaryInfo/PrimaryInfo';
import { Typography } from '@components/Typography';
import UnitSpecs from '@components/UnitSpecs/UnitSpecs';
import { PropertyListingsPageInitialElements } from './ListingDetails.types';
import ListingDetailsMap from './ListingDetailsMap';
import SitePlanImage from './SitePlanImage';
import {
  isIndustrialOrPark,
  PropertyType,
  orderDevelopmentPrimaryInfoLargeScreen,
  orderDevelopmentPrimaryInfoMediumScreen,
  orderIndustrialParkPrimaryInfoMediumScreen,
  orderIndustrialParkPrimaryInfoLargeScreen,
  orderDevelopmentUnitSpecs,
  orderIndustrialParkUnitSpecs,
} from '@/utilities/constants';
import AssetsCarousel from '@components/AssetsCarousel';
import { useState } from 'react';
import { sortBy } from '@/utilities/functions';
import { useMediaQuery } from 'react-responsive';
export interface ListingInfoElementsWrapperProps {
  listingChildren: TemplateValue[];
  isBottom: boolean;
  propertyType: string;
}
const ListingInfoElementsWrapper: React.FC<ListingInfoElementsWrapperProps> = ({
  listingChildren,
  isBottom,
  propertyType,
}) => {
  const [showAssetsCarouselModal, setShowAssetsCarouselModal] = useState(false);
  let filteredAssetUrls: string[] | undefined = undefined;
  const orderDevelopmentPrimaryInfo = useMediaQuery({ query: '(min-width: 1024px)' })
    ? orderDevelopmentPrimaryInfoLargeScreen
    : orderDevelopmentPrimaryInfoMediumScreen;
  const orderIndustrialParkPrimaryInfo = useMediaQuery({ query: '(min-width: 1024px)' })
    ? orderIndustrialParkPrimaryInfoLargeScreen
    : orderIndustrialParkPrimaryInfoMediumScreen;
  const {
    propertySpecifications,
    sitePlanButton,
    sitePlanImage,
    unitSpecifications,
    propertyDescription,
    squareFootageRange,
    map,
    contactInformation,
  } = listingChildren.reduce(
    (listingInfo, templateInfo) => {
      switch (templateInfo.templateType) {
        case TemplateTypes.PropertySpecifications:
          listingInfo.propertySpecifications = (
            <div className="property-listing-overview">
              <PrimaryInfo
                attributes={
                  propertyType === PropertyType.DEVELOPMENT ||
                  propertyType === PropertyType.PRE_DEVELOPMENT
                    ? sortBy(templateInfo?.attributes, orderDevelopmentPrimaryInfo)
                    : sortBy(templateInfo?.attributes, orderIndustrialParkPrimaryInfo)
                }
                propertyType={propertyType}
              />
            </div>
          );
          break;

        case TemplateTypes.UnitSpecifications:
          templateInfo.attributes.forEach((unitSpec) => {
            if (
              unitSpec.key === 'UNIT_OFFICE_SQUARE_FOOTAGE_OR_BUILD_TO_SUIT' &&
              unitSpec.value != 'To-suit'
            ) {
              unitSpec.value = Math.round(unitSpec.rawValue).toLocaleString() + ' Sq Ft';
            }

            if (unitSpec.key === 'UNIT_SQUARE_FOOTAGE' && isIndustrialOrPark(propertyType)) {
              unitSpec.label = 'Unit Sq Ft';
            }
          });
          listingInfo.unitSpecifications = (
            <UnitSpecs
              attributes={
                propertyType === PropertyType.DEVELOPMENT ||
                propertyType === PropertyType.PRE_DEVELOPMENT
                  ? sortBy(templateInfo?.attributes, orderDevelopmentUnitSpecs)
                  : sortBy(templateInfo?.attributes, orderIndustrialParkUnitSpecs)
              }
              propertyType={propertyType}
            />
          );
          break;

        case TemplateTypes.PropertyDescription:
          listingInfo.propertyDescription = templateInfo.attributes[0].rawValue && (
            <div className="w-[34.5625rem]">
              <Typography className="mb-6 font-medium" variant="h5">
                About Listing
              </Typography>
              <Typography className="mb-8" variant="body-2">
                {templateInfo.attributes[0].value}
              </Typography>
            </div>
          );
          break;
        case TemplateTypes.SquareFootageRange:
          listingInfo.squareFootageRange = templateInfo.attributes[0].rawValue && (
            <div className="w-[34.5625rem]">
              <Typography className="mb-6 font-medium" variant="h5">
                Unit Square Footage
              </Typography>
              <div className="flex space-x-32 mb-8">
                <Typography variant="body-2">Available Range</Typography>
                <Typography variant="subtitle-2">{templateInfo.attributes[0].value}</Typography>
              </div>
            </div>
          );

          break;
        case TemplateTypes.SitePlan: {
          const sitePlanUrl = templateInfo.attributes[0].rawValue;
          const variant = templateInfo.metadata['variant'];

          if (sitePlanUrl) {
            filteredAssetUrls = sitePlanUrl.split();
            switch (variant) {
              case 'BUTTON': {
                listingInfo.sitePlanButton = (
                  <Button
                    classNames="absolute right-0"
                    onClick={() => setShowAssetsCarouselModal(true)}
                    label="View Site Plan"
                    variant={ButtonVariant.DEFAULT_OUTLINE}
                  />
                );
                break;
              }
              case 'IMAGE': {
                listingInfo.sitePlanButton = (
                  <Button
                    classNames="absolute right-0"
                    onClick={() => setShowAssetsCarouselModal(true)}
                    label="View Site Plan"
                    variant={ButtonVariant.DEFAULT_OUTLINE}
                  />
                );
                listingInfo.sitePlanImage = <SitePlanImage sitePlanUrl={sitePlanUrl} />;
                break;
              }
            }
          }
          break;
        }
        case TemplateTypes.ListingDetailsMap: {
          const latitude = templateInfo.attributes.find(
            (attribute) => attribute.key === 'LATITUDE',
          );
          const longitude = templateInfo.attributes.find(
            (attribute) => attribute.key === 'LONGITUDE',
          );
          listingInfo.map = (
            <div className="flex flex-col w-[70rem] pt-4 pb-8">
              <Typography className="mb-6" variant="h5">
                Map View
              </Typography>
              <div className="[&_.mapboxgl-map]:rounded-[0.4375rem] w-full h-[40rem] pointer-events-none drop-shadow-lg listing-map">
                <ListingDetailsMap latitude={latitude?.rawValue} longitude={longitude?.rawValue} />
              </div>
            </div>
          );
          break;
        }
        case TemplateTypes.ContactInformation: {
          const brokerName = templateInfo.attributes.find(
            (attribute) => attribute.key === 'BROKER_NAME',
          );
          const brokerPhone = templateInfo.attributes.find(
            (attribute) => attribute.key === 'BROKER_PHONE_NUMBER',
          );
          const brokerShop = templateInfo.attributes.find(
            (attribute) => attribute.key === 'BROKER_SHOP',
          );
          const brokerEmail = templateInfo.attributes.find(
            (attribute) => attribute.key === 'BROKER_EMAIL',
          );
          const linkRepEmail = templateInfo.attributes.find(
            (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_EMAIL',
          );
          const linkRepName = templateInfo.attributes.find(
            (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_NAME',
          );
          const linkRepPhone = templateInfo.attributes.find(
            (attribute) => attribute.key === 'LINK_LEASING_REPRESENTATIVE_PHONE_NUMBER',
          );
          let nameToShow = brokerName?.rawValue ?? '--',
            emailToShow = brokerEmail?.rawValue ?? '--',
            phoneToShow = brokerPhone?.rawValue ?? '--',
            shopToShow = brokerShop?.rawValue ?? '--';
          // if broker info exists, add 'Broker for' to the shop name
          if (shopToShow !== '--') {
            shopToShow = `Broker for ${shopToShow}`;
          }
          if ((!brokerName || !brokerName?.rawValue) && !!linkRepName) {
            nameToShow = linkRepName?.rawValue ?? '--';
            emailToShow = linkRepEmail?.rawValue ?? '--';
            phoneToShow = linkRepPhone?.rawValue ?? '--';
            shopToShow = nameToShow === '--' ? '--' : 'Agent for LINK Logistics';
          }

          if (isIndustrialOrPark(propertyType)) {
            shopToShow = 'Market Officer for Link Logistics';
            phoneToShow = '--';
          }

          listingInfo.contactInformation = (
            <div className="property-listing-contact">
              <ContactBroker
                name={nameToShow}
                email={emailToShow}
                phone={phoneToShow}
                shop={shopToShow}
                emailIcon={brokerEmail ? brokerEmail?.icon : linkRepEmail?.icon}
                phoneIcon={brokerPhone ? brokerPhone?.icon : linkRepPhone?.icon}
              />
            </div>
          );
          break;
        }
      }

      return listingInfo;
    },
    {
      propertySpecifications: null,
      sitePlanButton: null,
      sitePlanImage: null,
      unitSpecifications: null,
      propertyDescription: null,
      squareFootageRange: null,
      map: null,
    } as PropertyListingsPageInitialElements,
  );

  return (
    <>
      {propertySpecifications}
      <div className="property-unit-specs">
        <div className="flex max-w-[70.625rem] relative w-full">
          <div className="max-w-[63.4375rem] w-full">
            {!!propertyDescription && propertyDescription}
            {!!squareFootageRange && squareFootageRange}
            {!!unitSpecifications && unitSpecifications}
            {!!sitePlanImage && sitePlanImage}
          </div>
          {!!sitePlanButton && sitePlanButton}
        </div>
      </div>
      {!!map && <div className="flex justify-center w-full px-10">{map}</div>}
      <div id="contactBannerBottom"></div>
      {!!contactInformation && (
        <div
          className={
            isBottom
              ? 'flex justify-center w-full contact-banner-bottom'
              : 'flex justify-center w-full contact-banner'
          }>
          {contactInformation}
        </div>
      )}
      {filteredAssetUrls && showAssetsCarouselModal && (
        <AssetsCarousel
          assets={filteredAssetUrls}
          onClose={() => setShowAssetsCarouselModal(false)}
        />
      )}
    </>
  );
};

export function isInViewport(element: HTMLElement | null) {
  const rect = element?.getBoundingClientRect();
  return (
    rect?.top! >= 0 &&
    rect?.left! >= 0 &&
    rect?.bottom! <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect?.right! <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export function hasAScroll() {
  const root = document?.getElementById('root');
  const hasVerticalScrollbar = root?.scrollHeight! > root?.clientHeight!;
  return hasVerticalScrollbar;
}

export default ListingInfoElementsWrapper;

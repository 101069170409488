import cn from 'classnames';
import React from 'react';

import { ConstrainedTypography } from '@components/ConstrainedTypography';
import { Icon } from '@components/Icon';
import { Typography } from '@components/Typography';
import { PrimarySpecificationProps } from './PrimaryInfo.types';
import './PrimarySpecs.css';
import { isIndustrialOrPark } from '@/utilities/constants';

const PrimarySpecification: React.FC<PrimarySpecificationProps> = ({
  className,
  iconName,
  label,
  value,
  propertyType,
}) => (
  <div
    className={cn([
      'flex items-center sm:mb-3 sm:mr-2 md:mb-1 md:mr-0 lg:mb-3 lg:mr-2',
      className,
    ])}>
    <div
      className={
        isIndustrialOrPark(propertyType) && label === 'Potentially Available Date'
          ? 'flex basis-2/3 grow-0 md:grow-1 md:basis-[auto] items-center'
          : 'flex basis-1/2 grow-0 md:grow-1 md:basis-[auto] items-center'
      }>
      {iconName ? (
        <Icon classNames="mr-2 !w-6 !h-6" name={iconName} />
      ) : (
        <div className="w-6 h-6 mr-2" />
      )}
      <Typography variant="body-2" className=" md:mr-1 lg:mr-5">
        {label}:
      </Typography>
    </div>

    <ConstrainedTypography variant="subtitle-2" className="custom-max-w-specs">
      {value}
    </ConstrainedTypography>
  </div>
);

export default PrimarySpecification;

import React, { createContext, useState } from 'react';

interface GeolocationContextValue {
  currentLocation: GeolocationCoordinates | null;
  geolocationError: GeolocationPositionError | null;
  setCurrentLocation: React.Dispatch<React.SetStateAction<GeolocationCoordinates | null>>;
  updateCurrentLocation: () => Promise<GeolocationCoordinates | null>;
}

export const GeolocationContext = createContext<GeolocationContextValue>(
  {} as GeolocationContextValue,
);

type GeolocationProviderProps = Omit<React.ProviderProps<GeolocationContextValue>, 'value'>;

export const GeolocationProvider: React.FC<GeolocationProviderProps> = ({ children }) => {
  const [currentLocation, setCurrentLocation] = useState<GeolocationCoordinates | null>(null);
  const [geolocationError, setGeolocationError] = useState<GeolocationPositionError | null>(null);

  const updateCurrentLocation = () =>
    new Promise<GeolocationCoordinates | null>((resolve) => {
      if (!navigator.geolocation) {
        setCurrentLocation(null);
        return resolve(null);
      }

      navigator.geolocation.getCurrentPosition(
        ({ coords }) => {
          setCurrentLocation(coords);
          resolve(coords);
        },
        (error) => {
          setCurrentLocation(null);
          setGeolocationError(error);
          resolve(null);
        },
        { enableHighAccuracy: true, timeout: 5000 },
      );
    });

  const contextValue: GeolocationContextValue = {
    currentLocation,
    geolocationError,
    setCurrentLocation,
    updateCurrentLocation,
  };

  return <GeolocationContext.Provider value={contextValue}>{children}</GeolocationContext.Provider>;
};

import React from 'react';

import { Typography } from '@components/Typography';

interface MapClusterProps {
  count: number;
  onClick: () => void;
}

export const MapCluster: React.FC<MapClusterProps> = ({ count, onClick }) => (
  <div
    className="flex items-center justify-center w-12 h-12 border-[0.5rem] rounded-full cursor-pointer bg-freight-100 hover:bg-base-black hover:text-base-white border-base-white"
    onClick={onClick}>
    <Typography className="block" variant="subtitle-1">
      {count}
    </Typography>
  </div>
);

export default MapCluster;

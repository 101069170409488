import { useEffect, useState } from 'react';

import { useApiClient } from './useApiClient';

const useMarkets = () => {
  const [nameLookup, setNameLookup] = useState(new Map<string, string>());
  const [idLookup, setIdLookup] = useState(new Map<string, string>());

  const { getMarkets } = useApiClient();

  const { data } = getMarkets();

  useEffect(() => {
    if (!data?.length) {
      return;
    }

    const nameLookup = data.reduce((lookup, market) => {
      lookup.set(market.name, market.marketId);
      return lookup;
    }, new Map<string, string>());

    setNameLookup(nameLookup);

    const idLookup = data.reduce((lookup, market) => {
      lookup.set(market.marketId, market.name);
      return lookup;
    }, new Map<string, string>());

    setIdLookup(idLookup);
  }, [data]);

  return { data, idLookup, nameLookup };
};

export default useMarkets;

import cn from 'classnames';
import React, { useState } from 'react';

import { useApiClient } from '@/hooks/useApiClient';
import { useUser } from '@/hooks/useUser';
import { useUserFavoriteCollections } from '@/hooks/useUserFavoriteCollections';
import ConfirmationModal from '@components/ConfirmationModal';
import { Checkbox, CheckboxState, TextInput } from '@components/Inputs';
import { Typography } from '@components/Typography';
import { GenerateAvailabilityReportsModalProps } from './GenerateAvailabilityReportsModal.types';
import { MAX_CHARACTERS_ALLOWED } from '@/utilities/constants';
import { useToast } from '@/hooks/useToast';
import { useMediaQuery } from 'react-responsive';
import { ternaryOperation } from '@/utilities/functions';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import './GenerateAvailabilityReportsModal.css';
import { Tooltip } from 'react-tooltip';

const GenerateAvailabilityReportsModal: React.FC<GenerateAvailabilityReportsModalProps> = ({
  listingGroups,
  onConfirm,
  onClose,
}) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 499px)' });
  const isMediumScreen = useMediaQuery({ query: '(min-width: 768px)' });
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1024px)' });
  const { email, firstName, lastName } = useUser();
  const [createACollection, setCreateACollection] = useState(CheckboxState.EMPTY);
  const [reportName, setReportName] = useState('');
  const [reportNameError, setReportNameError] = useState('');
  const [showDownloadingAvailabilityReportModal] = useState(false);
  const [maxCharactersRemaining, setMaxCharactersRemaining] = useState(MAX_CHARACTERS_ALLOWED);

  const [emails, setEmails] = useState<string[]>([]);

  const { shareAvailabilityReportViaEmail } = useApiClient();
  const { addFavoriteCollection } = useUserFavoriteCollections();
  const { addToast } = useToast();

  const { mutate: shareAvailabilityReportViaEmailMutation } = shareAvailabilityReportViaEmail();

  const handleButtonClick = async () => {
    if (reportName && reportName.trim().length <= 0) {
      setReportNameError('Report Name cannot be empty spaces or blank');
      return;
    }

    if (createACollection === CheckboxState.CHECKED) {
      try {
        await addFavoriteCollection(reportName, listingGroups[0].listingIds);
      } catch (error: any) {
        const { exceptionMessage } = error.response.data.responseException;
        setReportNameError(exceptionMessage);
        return;
      }
    }

    let totalListingCount = 0;

    for (const { name, listingIds } of listingGroups) {
      shareAvailabilityReportViaEmailMutation({
        recipient: emails,
        listingIds,
        title: name ?? reportName,
        senderEmail: email!,
        senderFirstName: firstName!,
        senderLastName: lastName!,
      });

      totalListingCount += listingIds.length;
    }

    if (totalListingCount > 10) {
      addToast({
        id: 'generate-report',
        description: `Once it's ready, your file will automatically be sent via email`,
        title: 'Generating Report',
        type: 'success',
      });
    } else {
      addToast({
        id: 'generate-report',
        description: `Availability report sent to ${emails.length} ${
          emails.length > 1 ? 'people' : 'person'
        }`,
        title: 'Success!',
        type: 'success',
      });
    }
    onConfirm();
  };

  const handleReportNameOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaxCharactersRemaining(MAX_CHARACTERS_ALLOWED - event.target.value.length);
    const inputValue = event.target.value;

    setReportName(inputValue);
    setReportNameError('');
  };

  const onChangeEmails = (emails: string[]) => {
    setEmails(emails);
  };

  const getLabelInput = (email: string, index: number, removeEmail: (index: number) => void) => (
    <div data-tag key={index}>
      {email}
      <button
        onClick={() => removeEmail(index)}
        style={{ cursor: 'pointer', marginLeft: '6px', fontSize: 18 }}>
        ×
      </button>
    </div>
  );

  const showReportNameInput = !listingGroups[0].name;
  const showCreateCollectionCheckbox = showReportNameInput;

  return (
    <>
      {showDownloadingAvailabilityReportModal ? (
        <ConfirmationModal
          header="Downloading Availability Report..."
          onClose={onConfirm}
          primaryBtnOnClick={onConfirm}
          primaryBtnLabel="Return to Property Search"
          show>
          <Typography className="mt-4 mb-2" variant="body-2">
            This report contains a large amount of data and may take us a little while to prepare.
            {isMediumScreen && <br />}
            Once it&apos;s ready, your file will automatically be sent via email.
          </Typography>
        </ConfirmationModal>
      ) : (
        <ConfirmationModal
          header={
            listingGroups.length > 1
              ? 'Generate Availability Reports'
              : 'Generate an Availability Report'
          }
          classNames="sm:!pb-6"
          onClose={onClose}
          primaryBtnDisabled={
            !emails || emails.length === 0 || (showReportNameInput && reportName == '')
          }
          primaryBtnLabel="Send Via Email"
          primaryBtnOnClick={handleButtonClick}
          show
          subText={ternaryOperation(
            listingGroups.length === 1,
            `You are creating a new report from ${ternaryOperation(
              listingGroups[0].name,
              'this collection',
              `(${listingGroups[0].listingIds.length}) selected listing${ternaryOperation(
                listingGroups[0].listingIds.length === 1,
                '',
                's',
              )}`,
            )}. The report will be sent to the following email.`,
            `You are creating new reports from (${listingGroups.length}) selected collection${
              listingGroups.length === 1 ? '' : 's'
            }. These reports will be sent to the following email.`,
          )}>
          {showReportNameInput && (
            <div className="flex flex-col items-start w-full mt-10 mb-4 sm:mt-4 sm:mb-2">
              <Typography className="mb-4" variant="body-4">
                Enter Report Name
              </Typography>
              <TextInput
                classNames="h-[5.375rem] mb-[0.125rem] w-full"
                error={!!reportNameError}
                errorMessage={reportNameError}
                label="Enter Report Name"
                onChange={handleReportNameOnChange}
                value={reportName}
                maxLength={MAX_CHARACTERS_ALLOWED}
                required={true}
                maxCharactersRemaining={maxCharactersRemaining}
                showRemainingCharactersText={true}
              />
            </div>
          )}

          <div
            className={cn([
              'flex flex-col items-start w-full',
              showReportNameInput ? '' : 'mt-6 sm:mt-4',
            ])}>
            <Typography className="mb-4" variant="body-4">
              Recipients&apos; Emails
            </Typography>
            {isLargeScreen && emails.length > 0 && (
              <Tooltip id="emails-info" className="z-10">
                <Typography
                  variant="body-4"
                  className="sm:max-w-[16rem] md:max-w-[22rem] sm:max-h-[15rem] md:max-h-[25rem]">
                  {emails.join(', ')}
                </Typography>
              </Tooltip>
            )}
            <div className="multiple-email" data-tooltip-id="emails-info" data-tooltip-place="top">
              <ReactMultiEmail
                placeholder="Recipients' Emails"
                allowDuplicate={false}
                emails={emails}
                style={{
                  width: '100%',
                  maxWidth: isMediumScreen ? '445px' : '100%',
                  backgroundColor: '#eee',
                  borderColor: '#eee',
                  height: 'auto',
                  maxHeight: isSmallScreen ? 110 : 200,
                  overflowY: 'auto',
                }}
                className="mb-[0.125rem] w-full text-input bg-slate-50 h-auto"
                onChange={onChangeEmails}
                getLabel={getLabelInput}
              />
            </div>
          </div>

          {showCreateCollectionCheckbox && (
            <Checkbox
              checkedState={createACollection}
              className="w-full sm:mt-3 md:mt-6"
              labelClassNames="text-left"
              disableIndeterminateOption
              onClick={(checkedState) => setCreateACollection(checkedState)}
              label="I would also like to create a collection with these listing(s)."
            />
          )}
        </ConfirmationModal>
      )}
    </>
  );
};

export default GenerateAvailabilityReportsModal;

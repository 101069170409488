import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useUserFavoriteCollections } from '@/hooks/useUserFavoriteCollections';
import { SummaryFavoriteCollection } from '@/types';
import { SHARE_COLLECTION_QUERY_PARAMETER_NAME } from '@/utilities/constants';
import { encodeString, formatNumberWithCommas } from '@/utilities/textHelpers';
import {
  AddOrRenameCollectionModal as AddCollectionModal,
  AddOrRenameCollectionModal as RenameCollectionModal,
} from '@components/AddOrRenameCollectionModal';
import Button, { ButtonVariant } from '@components/Button';
import FavoriteCollectionCard from '@components/CollectionCard';
import { CollectionCardHandlerOptionProps } from '@components/CollectionCard/CollectionCard.types';
import ConfirmationModal from '@components/ConfirmationModal';
import Footer from '@components/Footer';
import GenerateAvailabilityReportsModal from '@components/GenerateAvailabilityReportModal';
import { Icon, IconName } from '@components/Icon';
import { CheckboxState } from '@components/Inputs';
import { RemoveCollectionModal } from '@components/RemoveCollectionModal';
import SavedContentPageWrapper from '@components/SavedContentPageWrapper';
import { ShareCollectionModal } from '@components/ShareCollectionModal';
import { Typography } from '@components/Typography';
import useIsBigScreen from '@/hooks/useIsBigScreen';

import './Collections.css';

export const Collections: React.FC = () => {
  const [contextMenuCollection, setContextMenuCollection] =
    useState<SummaryFavoriteCollection | null>(null);
  const [enableCollectionSelection, setEnableCollectionSelection] = useState(false);
  const [selectedCollections, setSelectedCollections] = useState<SummaryFavoriteCollection[]>([]);
  const [showAddCollectionModal, setShowAddCollectionModal] = useState(false);
  const [showGenerateAvailabilityReportsModal, setShowGeneratevailabilityReportsModal] =
    useState(false);
  const [showRenameCollectionModal, setShowRenameCollectionModal] = useState(false);
  const [showRemoveCollectionModal, setShowRemoveCollectionModal] = useState(false);
  const [showShareCollectionModal, setShowShareCollectionModal] = useState(false);
  const isBigScreen = useIsBigScreen();

  const location = useLocation();
  const navigate = useNavigate();
  const {
    cloneFavoriteCollection,
    favoriteCollections,
    isFavoriteCollectionsLoading,
    refetchFavoriteCollections,
  } = useUserFavoriteCollections();

  const sharedCollectionId = useMemo(() => {
    const searchParameters = new URLSearchParams(location.search);

    return searchParameters.has(SHARE_COLLECTION_QUERY_PARAMETER_NAME)
      ? searchParameters.get(SHARE_COLLECTION_QUERY_PARAMETER_NAME)
      : '';
  }, [location.search]);

  const optionHandlers: CollectionCardHandlerOptionProps = {
    deleteHandler: (collectionId) => {
      updateContextMenuCollection(collectionId);
      setShowRemoveCollectionModal(true);
    },
    renameHandler: (collectionId) => {
      updateContextMenuCollection(collectionId);
      setShowRenameCollectionModal(true);
    },
    copyLinkHandler: (collectionId) => {
      updateContextMenuCollection(collectionId);
      setShowShareCollectionModal(true);
    },
    createOpHandler: (collectionId) => {
      const collection = favoriteCollections.find((x) => x.favoriteCollectionId == collectionId)!;
      setSelectedCollections([collection]);
      setShowGeneratevailabilityReportsModal(true);
    },
  };

  const handleCloseSharedCollectionModal = () => {
    const searchParameters = new URLSearchParams(location.search);
    searchParameters.delete(SHARE_COLLECTION_QUERY_PARAMETER_NAME);
    navigate({ search: searchParameters.toString() }, { replace: true });
  };

  const updateContextMenuCollection = (collectionId: string) => {
    const collection = favoriteCollections.find((x) => x.favoriteCollectionId == collectionId)!;
    setContextMenuCollection(collection);
  };

  useEffect(() => {
    (async function () {
      await refetchFavoriteCollections();
    })();
  }, []);

  const renderActions = () => (
    <div className="flex float-right">
      {enableCollectionSelection && (
        <Button
          buttonClassNames="cancel"
          Icon={<Icon name={IconName.CLOSE} />}
          label="Cancel"
          onClick={() => {
            setEnableCollectionSelection(false);
            setSelectedCollections([]);
          }}
          variant={ButtonVariant.NO_FILL}
          classNames="mr-4"
        />
      )}

      <Button
        disabled={enableCollectionSelection && selectedCollections.length === 0}
        label="Create Report"
        onClick={() => {
          if (enableCollectionSelection) {
            setShowGeneratevailabilityReportsModal(true);
          } else {
            setEnableCollectionSelection(true);
          }
        }}
        variant={
          enableCollectionSelection ? ButtonVariant.PRIMARY_TWO : ButtonVariant.DEFAULT_OUTLINE
        }
        classNames="mr-4"
      />

      {!enableCollectionSelection && (
        <Button
          Icon={<Icon name={IconName.ADD_FOLDER} />}
          label="New Collection"
          onClick={() => setShowAddCollectionModal(true)}
          variant={ButtonVariant.DEFAULT_OUTLINE}
        />
      )}
    </div>
  );

  return (
    <>
      <SavedContentPageWrapper isLoading={isFavoriteCollectionsLoading}>
        <div className="flex items-center justify-between mb-2 lg:mb-10 h-[3.4rem] lg:h-[5.625rem]">
          <Typography className="my-[0.3625rem] lg:my-[0.5625rem] title" variant="title">
            {`My Collections${
              favoriteCollections.length
                ? ` (${formatNumberWithCommas(favoriteCollections.length)})`
                : ''
            }`}
          </Typography>

          <div className="flex items-center space-x-6">
            {favoriteCollections.length > 0 && isBigScreen ? renderActions() : null}
          </div>
        </div>

        {favoriteCollections.length === 0 ? (
          <div className="flex flex-col justify-end items-center">
            <div className="sm:absolute sm:top-[40vh] md:relative md:top-4 sm:mx-2">
              <Typography className="mb-2 text-center" variant="h6">
                You don&apos;t have any Collections yet.
              </Typography>

              <Typography className="mb-10 text-center" variant="body-2">
                Create a new collection so you can start organizing your favorited listings.
              </Typography>
              <div className="flex justify-center">
                <Button
                  classNames="sm:absolute sm:top-[37vh] md:relative md:top-0"
                  buttonClassNames="lg:w-[28.125rem] w-[18rem]"
                  Icon={<Icon name={IconName.ADD_FOLDER} />}
                  label="New Collection"
                  variant={ButtonVariant.PRIMARY_ONE}
                  onClick={() => setShowAddCollectionModal(true)}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-rows-none items-center sm:pb-14 md:pb-0 mt-4 md:items-stretch md:justify-items-center">
            {favoriteCollections.map((favoriteCollection) => (
              <FavoriteCollectionCard
                key={favoriteCollection.favoriteCollectionId}
                addToOppsReportChecked={
                  selectedCollections.find(
                    (x) => x.favoriteCollectionId === favoriteCollection.favoriteCollectionId,
                  )
                    ? CheckboxState.CHECKED
                    : CheckboxState.EMPTY
                }
                addToOppsReportOnClick={(collectionId) => {
                  if (
                    selectedCollections.find((x) => x.favoriteCollectionId === collectionId) !==
                    undefined
                  ) {
                    setSelectedCollections((selectedCollections) =>
                      selectedCollections.filter((x) => x.favoriteCollectionId !== collectionId),
                    );
                  } else {
                    setSelectedCollections((selectedCollections) => [
                      ...selectedCollections,
                      favoriteCollection,
                    ]);
                  }
                }}
                collectionId={favoriteCollection.favoriteCollectionId}
                collectionName={favoriteCollection.name}
                createOppsReportEnabled={enableCollectionSelection}
                optionHandlers={optionHandlers}
                numberOfListings={favoriteCollection.listingIds.length}
                thumbList={favoriteCollection.thumbnailImageUrls}
              />
            ))}
          </div>
        )}
      </SavedContentPageWrapper>

      {showGenerateAvailabilityReportsModal && (
        <GenerateAvailabilityReportsModal
          listingGroups={selectedCollections.map(({ name, listingIds }) => ({
            name: encodeString(name),
            listingIds,
          }))}
          onConfirm={() => {
            setShowGeneratevailabilityReportsModal(false);
            setEnableCollectionSelection(false);
            setSelectedCollections([]);
          }}
          onClose={() => {
            setShowGeneratevailabilityReportsModal(false);
            setSelectedCollections([]);
          }}
        />
      )}

      {showAddCollectionModal && (
        <AddCollectionModal onClose={() => setShowAddCollectionModal(false)} show />
      )}

      {contextMenuCollection && (
        <>
          {showRenameCollectionModal && (
            <RenameCollectionModal
              favoriteCollection={contextMenuCollection}
              onClose={() => {
                setShowRenameCollectionModal(false);
                setContextMenuCollection(null);
              }}
              show
            />
          )}

          {showRemoveCollectionModal && (
            <RemoveCollectionModal
              favoriteCollection={contextMenuCollection}
              onClose={() => {
                setShowRemoveCollectionModal(false);
                setContextMenuCollection(null);
              }}
              show
            />
          )}

          {showShareCollectionModal && (
            <ShareCollectionModal
              favoriteCollectionId={contextMenuCollection.favoriteCollectionId}
              show={showShareCollectionModal}
              listingIds={contextMenuCollection.listingIds}
              onClose={() => {
                setShowShareCollectionModal(false);
                setContextMenuCollection(null);
              }}
            />
          )}
        </>
      )}

      {!!sharedCollectionId && (
        <ConfirmationModal
          header={`Add Collection?`}
          onClose={handleCloseSharedCollectionModal}
          primaryBtnLabel="Yes, Proceed"
          primaryBtnOnClick={() => {
            cloneFavoriteCollection(sharedCollectionId).then(() =>
              handleCloseSharedCollectionModal(),
            );
          }}
          secondaryBtnLabel="No, I want to cancel"
          secondaryBtnOnClick={handleCloseSharedCollectionModal}
          show
          subText={`A collection has been shared with you. Add this collection on your account?`}
        />
      )}

      {!isBigScreen && favoriteCollections.length > 0 ? (
        <div className="mobile-actions">{renderActions()}</div>
      ) : (
        <Footer />
      )}
    </>
  );
};

export default Collections;
